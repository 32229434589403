import React from "react";
import IconHome from "assets/icons/IconHome";
import IconCatalogos from "assets/icons/IconCatalogos";
import IconPlaneacion from "assets/icons/IconPlaneacion";
import IconMonitor from "assets/icons/IconMonitor";
import IconConfiguracion from "assets/icons/IconConfiguracion";
import IsoTipo from "assets/logos/IsoTipo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const menus = [
  {
    text: ["Dashboard"],
    Icon: IconHome,
  },
  {
    text: ["Catálogos", "Catalogs"],
    Icon: IconCatalogos,
  },
  {
    text: ["Planeación", "Planning"],
    Icon: IconPlaneacion,
  },
  {
    text: ["Monitoreo", "Monitoring"],
    Icon: IconMonitor,
    multiLevel: true,
  },
  {
    text: ["Configuración", "Setting"],
    Icon: IconConfiguracion,
  },
];

const SideBar = () => {
  const { PUBLIC_URL } = process.env;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [indexSub, setIndexSub] = React.useState(null);
  const { views } = useSelector((state) => state.auth);
  const [timeoutId, setTimeoutId] = React.useState(null);

  const { pathname } = useLocation();

  const findIconRoute = (route) => {
    let IconRoute = menus.find((item) => item.text.includes(route.name));
    if (IconRoute) {
      return IconRoute;
    }

    return null;
  };

  const getDefaultRoute = (item) => {
    try {
      return item.children[0].url;
    } catch (error) {
      return item.url;
    }
  };

  const renderChidlren = (chidlren) => {
    return chidlren.map((item, index) => {
      return (
        <motion.li
          key={index}
          className={"p-1"}
          animate={{
            opacity: 1,
          }}
          initial={{
            opacity: 0,
          }}
        >
          <Link
            to={item.url ? `../${item.url}` : "#"}
            className="mx-2 font-[Gravity] text-[18px] font-[350] flex flex-row items-center gap-x-2 text-[#ffffff] "
            style={{
              color: "#222222",
            }}
          >
            {item.name}
          </Link>
        </motion.li>
      );
    });
  };

  const getColorActiveMultiLevel = ({ url, bg, indexItem }) => {
    if (bg) {
      return pathname.includes(url) || indexItem === indexSub ? "#f8f8f8" : "";
    }
    return pathname.includes(url) || indexItem === indexSub
      ? "#222222"
      : "#ffffff";
  };

  const getChildrenLink = (item, index) => {
    let RouteObject = findIconRoute(item);
    const { url } = item;
    const defaultRoute = getDefaultRoute(item);
    return (
      <li
        key={`${item.id}_${index}`}
        className="p-2 flex flex-col my-5"
        style={{
          backgroundColor: getColorActiveMultiLevel({
            url,
            bg: true,
            indexItem: index,
          }),
        }}
      >
        <Link
          to={defaultRoute ? `../${defaultRoute}` : "#"}
          className="mx-2 font-[Gravity] text-[18px] font-[350] flex flex-row items-center gap-x-2 text-[#ffffff] "
          style={{
            color: getColorActiveMultiLevel({ url, indexItem: index }),
          }}
          onClick={(event) => {
            if (RouteObject.multiLevel) {
              event.preventDefault();
              if (indexSub) {
                setIndexSub(null);
              } else {
                setIndexSub(index);
              }
              setOpen(true);
            }
          }}
        >
          {RouteObject ? (
            <RouteObject.Icon
              width={open ? 30 : 35}
              height={open ? 30 : 35}
              fill={getColorActiveMultiLevel({ url, indexItem: index })}
              bg={"none"}
            />
          ) : null}
          {open ? (
            <motion.label
              class="cursor-pointer"
              initial={{ opacity: 0 }}
              animate={{
                opacity: open ? 1 : 0,
              }}
              transition={{ duration: 0.5 }}
            >
              {item.name}
            </motion.label>
          ) : null}

          {open && RouteObject?.multiLevel ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${indexSub === index ? "-rotate-180" : ""}`}
            >
              <circle cx="75" cy="75" r="75" fill="#ffffff" />
              <path d="M75 108L37.5 42.75L112.5 42.75L75 108Z" fill="#222222" />
            </svg>
          ) : null}
        </Link>
        {RouteObject?.multiLevel && indexSub === index && open ? (
          <ul className="ml-5 mt-2">{renderChidlren(item.children)}</ul>
        ) : null}
      </li>
    );
  };

  return (
    <motion.div
      className={`rounded-r-[20px] bg-[#000000]  h-screen flex flex-col justify-between absolute left-0 top-0 z-10`}
      onMouseLeave={() => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        if (open) {
          setTimeoutId(setTimeout(() => setOpen(false), 3000));
        }
      }}
      animate={{
        width: open ? 225 : 70,
      }}
      transition={{ duration: 0.5 }}
      initial={{
        width: 70,
      }}
    >
      <div
        className={`flex justify-center items-center cursor-pointer ${open ? "px-4 py-2" : "px-2 py-6"
          }`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? (
          <motion.img
            src={`${PUBLIC_URL}/logos/imagotipo.svg`}
            className={"-mt-10"}
            alt="#"
            animate={{
              opacity: 1,
            }}
            initial={{
              opacity: 0,
            }}
          />
        ) : (
          <IsoTipo width={40} height={40} />
        )}
      </div>
      <div className="min-h-[500px] flex justify-center">
        <ul className="w-full">
          {views.map((item, index) => getChildrenLink(item, index))}
        </ul>
      </div>
      <div className="min-h-[70px] flex items-center justify-start w-full pb-[50px] mx-4">
        <button
          className={`h-[34px] rounded-md  flex flex-row items-center justify-center cursor-pointer`}
          onClick={() => {
            dispatch({ type: "LOGOUT" });
            navigate("../");
          }}
        >
          <svg
            width={open ? 30 : 35}
            height={open ? 30 : 35}
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M134.288 113.105H122.277C121.457 113.105 120.688 113.464 120.176 114.096C118.98 115.549 117.699 116.951 116.349 118.284C110.829 123.812 104.29 128.218 97.0947 131.257C89.64 134.408 81.6276 136.024 73.5353 136.009C65.3518 136.009 57.4246 134.403 49.9758 131.257C42.7803 128.218 36.2417 123.812 30.7216 118.284C25.1917 112.774 20.7822 106.244 17.7375 99.0543C14.5768 91.6017 12.988 83.6877 12.988 75.5001C12.988 67.3126 14.5939 59.3985 17.7375 51.946C20.7785 44.7498 25.1521 38.2716 30.7216 32.7164C36.2912 27.1611 42.7662 22.7853 49.9758 19.7428C57.4246 16.5977 65.3518 14.9909 73.5353 14.9909C81.7187 14.9909 89.6459 16.5806 97.0947 19.7428C104.304 22.7853 110.779 27.1611 116.349 32.7164C117.699 34.0667 118.963 35.4683 120.176 36.9042C120.688 37.5366 121.474 37.8955 122.277 37.8955H134.288C135.364 37.8955 136.03 36.699 135.432 35.7931C122.328 15.4183 99.4011 1.93189 73.3473 2.00026C32.413 2.10282 -0.406226 35.3487 0.00380051 76.2522C0.413827 116.506 33.1818 149 73.5353 149C99.5207 149 122.345 135.531 135.432 115.207C136.013 114.301 135.364 113.105 134.288 113.105ZM149.476 74.4233L125.233 55.2791C124.327 54.5612 123.012 55.2107 123.012 56.356V69.3467H69.3666C68.6149 69.3467 67.9999 69.962 67.9999 70.7141V80.2862C67.9999 81.0383 68.6149 81.6536 69.3666 81.6536H123.012V94.6443C123.012 95.7895 124.344 96.439 125.233 95.7211L149.476 76.577C149.639 76.4491 149.771 76.2856 149.862 76.0991C149.953 75.9125 150 75.7077 150 75.5001C150 75.2926 149.953 75.0878 149.862 74.9012C149.771 74.7146 149.639 74.5512 149.476 74.4233Z"
              fill="white"
            />
          </svg>
          {open ? (
            <motion.span
              className="font-[Gravity] text-[18px] font-[350] text-[#ffffff] ml-2"
              initial={{ opacity: 0 }}
              animate={{
                opacity: open ? 1 : 0,
              }}
              transition={{ duration: 0.5 }}
            >
              Cerrar sesion
            </motion.span>
          ) : (
            ""
          )}
        </button>
      </div>
    </motion.div>
  );
};

export default SideBar;
