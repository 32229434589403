const IsoTipo = ({  width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" fill="none" />
      <g id="Logos">
        <rect
          width="5470"
          height="2342"
          transform="translate(-431 -427)"
          fill="none"
        />
        <g id="isotipo">
          <g id="Capa 3">
            <g id="Capa 1">
              <path
                id="Vector"
                d="M148.725 123.078C149.539 124.471 149.968 126.055 149.968 127.668C149.968 129.281 149.539 130.866 148.725 132.258C147.939 133.613 146.813 134.74 145.458 135.525C144.065 136.339 142.481 136.768 140.868 136.768C139.255 136.768 137.671 136.339 136.278 135.525C134.925 134.737 133.799 133.612 133.011 132.258C132.206 130.864 131.782 129.282 131.781 127.671C131.781 126.061 132.204 124.479 133.008 123.083C133.813 121.688 134.97 120.529 136.364 119.723C137.758 118.916 139.339 118.49 140.95 118.488C142.559 118.475 144.141 118.905 145.523 119.73C146.881 120.512 148.008 121.639 148.79 122.997L148.725 123.078ZM147.026 131.164C147.661 130.084 147.999 128.854 148.005 127.601C148.01 126.348 147.683 125.115 147.057 124.03C146.431 122.944 145.529 122.043 144.442 121.42C143.354 120.797 142.121 120.473 140.868 120.481C139.61 120.467 138.372 120.801 137.291 121.445C136.186 122.07 135.267 122.977 134.628 124.075C133.985 125.144 133.651 126.371 133.665 127.619C133.651 128.878 133.984 130.115 134.628 131.197C135.234 132.304 136.125 133.229 137.209 133.875C138.293 134.507 139.524 134.839 140.778 134.839C142.032 134.839 143.264 134.507 144.347 133.875C145.452 133.251 146.371 132.343 147.01 131.246L147.026 131.164ZM139.186 132.471H137.552V122.882H140.966C141.832 122.861 142.673 123.17 143.318 123.748C143.622 124.005 143.865 124.326 144.031 124.687C144.198 125.048 144.283 125.441 144.282 125.839C144.284 126.518 144.027 127.172 143.563 127.668C143.09 128.206 142.453 128.574 141.75 128.714L144.723 132.471H142.518L139.643 128.779H139.137L139.186 132.471ZM141.962 126.868C142.113 126.75 142.234 126.598 142.316 126.425C142.398 126.252 142.439 126.063 142.436 125.872C142.437 125.685 142.394 125.501 142.312 125.334C142.23 125.167 142.11 125.021 141.962 124.908C141.619 124.637 141.191 124.498 140.754 124.516H139.12V127.342H140.754C141.191 127.36 141.619 127.221 141.962 126.95"
                fill="white"
              />
              <path
                id="Vector_2"
                d="M74.9755 13C61.5861 13.0041 48.5588 17.3491 37.8476 25.3832C27.1365 33.4174 19.3187 44.7076 15.5668 57.5606H0V70.6282H70.5162V57.5606H29.4022C33.3264 47.2216 40.6432 38.5205 50.1556 32.8806C59.668 27.2407 70.8128 24.996 81.7668 26.5137C92.7208 28.0314 102.836 33.2217 110.456 41.2354C118.077 49.2491 122.752 59.6118 123.718 70.6282H136.785C135.701 54.979 128.716 40.3249 117.243 29.6275C105.769 18.9302 90.6623 12.9873 74.9755 13"
                fill="white"
              />
              <path
                id="Vector_3"
                d="M149.967 79.2366H83.4042V92.3042H120.647C116.723 102.643 109.406 111.344 99.8935 116.984C90.3811 122.624 79.2362 124.869 68.2822 123.351C57.3282 121.833 47.2135 116.643 39.5928 108.629C31.9721 100.616 27.2967 90.253 26.3313 79.2366H13.2637C14.2587 93.7384 20.3263 107.427 30.4028 117.904C40.4792 128.38 53.9217 134.976 68.3738 136.534C82.8259 138.093 97.3654 134.515 109.444 126.428C121.522 118.341 130.369 106.26 134.433 92.3042H150L149.967 79.2366Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IsoTipo;
