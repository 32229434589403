import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  monitoringTripsList: [],
  polls:{}
};

const monitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    setMonitoringTrips(state, action) {
      return { ...state, monitoringTripsList: action.payload };
    },
    setPoll(state, action) {
      return { ...state, polls: action.payload };
    },
  },
});

export const {
    setMonitoringTrips,
    setPoll
} = monitoringSlice.actions;
export default monitoringSlice.reducer;
