import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import InputCustom from "components/InputCustom";
import Joi from "joi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnitTypeList,
  addOrUpdateUnitType,
  deleteUnitType,
} from "actions/Catalogs";
import { notify } from "utils";
import { format, parseISO } from "date-fns";
import { filterDate } from "components/DateCatalogs/filterDate";

const UnitType = ({ view }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");
  const [openModal, setOpenModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const { unitType } = useSelector((state) => state.catalog);

  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);
  const { permissions } = view;

  const { state, setData, setExplicitField, validate, reset } = useValidator({
    initialData: {
      name: null,
      description: null,
    },
    schema: Joi.object({
      name: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      description: Joi.string()
        .min(2)
        .max(250)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '250' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      name: false,
      description: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  const buttonRef = React.useRef();

  React.useEffect(() => {
    dispatch(getUnitTypeList());
  }, [dispatch]);

  const handleOnSubmit = async () => {
    let dto = {
      name: state.$data.name,
      description: state.$data.description,
      id,
    };
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      setShowError(true);
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    let isSuccess = await dispatch(addOrUpdateUnitType(dto));
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);

    if (isSuccess) {
      reset();
      setId(null);
      setOpenModal(false);
    }
  };

  const handleDelete = async (idUnitType) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteUnitType(idUnitType));
      }
    });
  };

  return (
    <>
      <Grid
        title={t("unitsType.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("unitsType.name"),
            accessor: "name",
            filter: "text",
            width: '30%'
          },
          {
            Header: t("unitsType.description"),
            accessor: "description",
            filter: false,
            width: '30%'
          },
          {
            Header: t("unitsType.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '20%'
          },
        ]}
        data={unitType || []}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const { id, description, name } = row;
          setId(id);
          setData((old) => ({
            ...old,
            name,
            description,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />

      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            reset()
            setOpenModal(false);
            setId(null);
          }}
          titleModal={t("unitsType.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("unitsType.info_modal")}
          </h4>
          <div className="flex flex-wrap ">
            <InputCustom
              type={"text"}
              labelText={t("unitsType.name")}
              errorMessage={
                showError && state.$errors.name.length > 0
                  ? state.$errors.name[0].$message
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name ? state.$data.name : ""}
              onBlur={() => setExplicitField("name", true)}
              key="name"
            />
            <InputCustom
              type={"text"}
              labelText={t("unitsType.description")}
              errorMessage={
                showError && state.$errors.description.length > 0
                  ? state.$errors.description[0].$message
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  description: e.target.value,
                }));
              }}
              value={state.$data.description ? state.$data.description : ""}
              onBlur={() => setExplicitField("description", true)}
              key="description"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default UnitType;
