import { AuthAction } from "actions/Auth";
import ButtonLogin from "components/ButtonLogin";
import InputLogin from "components/InputLogin";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isExpiredToken, notify } from "utils";
import { ReactComponent as Logo } from "../../assets/logos/logotipo_oficial.svg";
export default function AuthPage() {
  const dispatch = useDispatch();
  const { remember, token } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation("global");
  const [frmValues, setFrmValues] = React.useState({});
  const [messageError, setMessageError] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const [check, setCechk] = React.useState(false);
  const { PUBLIC_URL } = process.env;
  const navigation = useNavigate();

  React.useEffect(() => {
    if (remember && check === false) {
      let isNotValid = isExpiredToken(token);
      if (!isNotValid) {
        navigation("dashboard");
      }
    }
  }, [remember, navigation, token, check]);

  const AuthValidate = async () => {
    const { user, password } = frmValues;
    if (!user || !password) {
      setMessageError("Ingrese usuario y contraseña");
      return;
    }
    setIsLoad(true);
    let { responseApi, changePassword, routerDefault } = await dispatch(
      AuthAction({ user, password, remember: frmValues.remember })
    );
    setIsLoad(false);

    if (responseApi) {
      //NAVEGACIÓN A DASHBOARD
      if (routerDefault) {
        changePassword
          ? navigation("/change-password")
          : navigation(routerDefault);
      } else {
        notify("error", "No tienes permisos para acceder", 6000);
      }
    }
  };
  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center  bg-no-repeat bg-cover bg-center "
      style={{ backgroundImage: `url(${PUBLIC_URL}/assets/bg_1.jpg)` }}
    >
      <div className="flex flex-row gap-x-1 absolute top-4 right-3">
        <button
          className={`rounded border-[1px] border-black m-1 w-[30px] text-center px-1 py-1 text-sm ${
            i18n.language === "es" ? " bg-[#222222] text-white" : " text-black"
          }`}
          onClick={() => {
            localStorage.setItem("language", "es");
            i18n.changeLanguage("es");
          }}
        >
          ES
        </button>
        <button
          className={`rounded border-[1px] border-black m-1 w-[30px] text-center px-1 py-1 text-sm ${
            i18n.language === "en" ? " bg-[#222222] text-white" : " text-black"
          }`}
          onClick={() => {
            localStorage.setItem("language", "en");
            i18n.changeLanguage("en");
          }}
        >
          EN
        </button>
      </div>
      <div className="flex flex-col bg-white shadow-md sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md min-h-[50%]">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <div style={{ padding: 35 }} />
        <InputLogin
          label={t("login.label_user")}
          value={""}
          onChange={(event) =>
            setFrmValues({ ...frmValues, user: event.target.value })
          }
          className="py-2	"
          autocomplete="off"
        />
        <InputLogin
          label={t("login.label_pwd")}
          value={""}
          type={"password"}
          onChange={(event) =>
            setFrmValues({ ...frmValues, password: event.target.value })
          }
          className="py-2"
          autocomplete="off"
        />
        <div className="py-4">
          <label
            htmlFor=""
            className={`text-[#EA0000] ${!messageError && "hidden"}`}
            id="error"
          >
            {messageError}
          </label>
        </div>

        <div className="flex flex-row items-center gap-x-0.5 py-2	">
          <input
            type="checkbox"
            onChange={(e) => {
              setCechk(e.target.checked);
              setFrmValues({ ...frmValues, remember: e.target.checked });
            }}
            defaultValue={remember}
            id="remember"
          />
          <label htmlFor="remember" className="font-['Gravity']">
            {t("login.label_remember")}
          </label>
        </div>
        <span
          onClick={(event) => {
            event.preventDefault();
            navigation("recover-password");
          }}
          className="underline py-2 font-['Gravity'] cursor-pointer"
        >
          {t("login.label_recover")}
        </span>

        <div className="flex flex-row justify-center items-center gap-x-0.5 py-8 px-8">
          <ButtonLogin
            text={t(isLoad ? "login.load" : "login.btn_login")}
            onClick={AuthValidate}
            disabled={isLoad}
          />
        </div>
      </div>
    </div>
  );
}
