import apisauce from "apisauce";
import store from "../redux/store";

const { REACT_APP_URL_BASE } = process.env;
const api_env = REACT_APP_URL_BASE;
const create = (endpoint = api_env) => {
  const api = apisauce.create({
    baseURL: endpoint,
    timeout: 122000,
  });

  api.addRequestTransform((request) => {
    let accessToken = store.getState().auth.token;
    if (!accessToken) return;
    if (!(request.url.indexOf("auth/access-token") > -1)) {
      request.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    request.headers["Accept-Language"] =
      localStorage.getItem("language") || "es";
  });

  const post = (endpoint, data) => api.post(endpoint, data);
  const get = (endpoint) => api.get(endpoint);
  const put = (endpoint, data) => api.put(endpoint, data);
  const remove = (endpoint, data) => api.delete(endpoint, data);
  const setToken = (token) => api.setHeader("Authorization", `Bearer ${token}`);
  return {
    post,
    get,
    put,
    remove,
    setToken,
    axiosInstance: api.axiosInstance,
  };
};

const api = create();

export default api;
