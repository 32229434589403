import React from "react";
import InputLogin from "components/InputLogin";
import ButtonLogin from "components/ButtonLogin";
import { useDispatch } from "react-redux";
import { ChangePass } from "actions/User";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notify } from "utils";
import { ReactComponent as Logo } from "../../assets/logos/logotipo_oficial.svg";

const ChangePassword = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const [frmValues, setFrmValues] = React.useState({});
  const [messageError, setMessageError] = React.useState(false);
  const [length, setLength] = React.useState(false);
  const [letter, setLetter] = React.useState(false);
  const [number, setNumber] = React.useState(false);
  const [caracter, setCaracter] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const { PUBLIC_URL } = process.env;
  const navigate = useNavigate();
  const language = localStorage.getItem("language") || "es";
  const isSpanish = language === "es";

  const handleRecover = async () => {
    const { currentPassword, newPassword, newConfirmPassword } = frmValues;


    let error_200 = 'Verificar que la nueva contraseña y la confirmación sean iguales'
    let error_300 = 'Verificar que los campos no se encuentren vacios'

    if (!newPassword || !newConfirmPassword || !currentPassword) {
      if (!isSpanish) {
        error_300 = 'Verify that the fields are not empty'
      }
      setMessageError(error_300);
      return;
    }

    if (newPassword !== newConfirmPassword) {

      if (!isSpanish) {
        error_200 = 'Verify that the new password and confirmation are the samey'
      }
      setMessageError(error_200);
      return;
    } else {
      setMessageError(false);
    }

    if (newPassword.length < 8) {
      setLength(true);
      return;
    } else {
      setLength(false);
    }
    //eslint-disable-next-line
    if (!/[a-z]/i.test(newPassword)) {
      setLetter(true);
      return;
    } else {
      setLetter(false);
    }

    if (!/[0-9]/.test(newPassword)) {
      setNumber(true);
      return;
    } else {
      setNumber(false);
    }
    /* eslint-disable */
    let regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!regex.test(newPassword)) {
      setCaracter(true);
      return;
    } else {
      setCaracter(false);
    }

    setIsLoad(true);
    let isSuccess = await ChangePass({
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
    if (isSuccess) {
      //NAVEGACIÓN A DASHBOARD
      notify("success", `La contraseña fue cambiada con exito`, 3500);
      dispatch({ type: "LOGOUT" });
      navigate("/");
    }
    setIsLoad(false);
  };
  return (
    <div
      class="min-h-screen flex flex-col items-center justify-center bg-cover  bg-no-repeat bg-center "
      style={{ backgroundImage: `url(${PUBLIC_URL}/assets/bg_1.jpg)` }}
    >
      <div class="flex flex-col bg-white shadow-md sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md min-h-[50%]">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <div className="py-10" />
        <h3 className="font-bold font-[Gravity] text-[#000000]">
          {isSpanish ? 'Nueva contraseña' : 'New password'}
        </h3>
        <span className="font-[Gravity] text-[#000000] py-2">
          {isSpanish ? 'La contraseña debe contar con' : 'The password must have:'}
        </span>
        <ul class="list-disc">
          {!length ? (
            <li>{isSpanish ? '8 carácteres mínimo' : '8 characters minimum'}</li>
          ) : (
            <li className={`text-[#EA0000]`}>{isSpanish ? '8 carácteres mínimo' : '8 characters minimum'}</li>
          )}
          {!letter ? (
            <li>{isSpanish ? 'Mínimo 1 letra' : 'Minimum 1 letter'}</li>
          ) : (
            <li className={`text-[#EA0000]`}>{isSpanish ? 'Mínimo 1 letra' : 'Minimum 1 letter'}</li>
          )}
          {!number ? (
            <li>{isSpanish ? 'Mínimo 1 número' : 'Minimum 1 number'}</li>
          ) : (
            <li className={`text-[#EA0000]`}>{isSpanish ? 'Mínimo 1 número' : 'Minimum 1 number'}</li>
          )}
          {!caracter ? (
            <li>{isSpanish ? 'Mínimo 1 caracter especial' : 'Minimum 1 special character'}</li>
          ) : (
            <li className={`text-[#EA0000]`}>{isSpanish ? 'Mínimo 1 caracter especial' : 'Minimum 1 special character'}</li>
          )}
        </ul>
        <InputLogin
          label={t("changePassword.inputCurrentPassword")}
          type="password"
          value={""}
          onChange={(event) =>
            setFrmValues({ ...frmValues, currentPassword: event.target.value })
          }
          className="py-2	"
        />
        <InputLogin
          label={t("changePassword.inputNewPassword")}
          type="password"
          value={""}
          onChange={(event) =>
            setFrmValues({ ...frmValues, newPassword: event.target.value })
          }
          className="py-2	"
        />
        <InputLogin
          label={t("changePassword.inputNewConfirmPassword")}
          type="password"
          value={""}
          onChange={(event) =>
            setFrmValues({
              ...frmValues,
              newConfirmPassword: event.target.value,
            })
          }
          className="py-2	"
        />

        <div className="py-4">
          <label
            htmlFor=""
            className={`text-[#EA0000] ${!messageError && "hidden"}`}
          >
            {messageError}
          </label>
        </div>

        <div className="flex flex-row justify-center items-center gap-x-0.5 py-8 px-8">
          <ButtonLogin
            text={t(isLoad ? "login.load" : "changePassword.btn")}
            onClick={handleRecover}
            disabled={isLoad}
          />
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
