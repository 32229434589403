import { ResponseTransform } from "utils";
import api from "api";
import { setUserList } from "redux/user.slice";
export const LOGIN_ACTION = "LOGIN_ACTION";

export const RecoverAction = async ( user ) => {

  let response = await api
    .post(`api/user/recovery-password/`,user)
    .then(ResponseTransform);
  return response;
};


export const ChangePass = async (data) => {
  let response = await api
    .post(`api/user/change-password/`,data)
    .then(ResponseTransform);
  return response;
};

//Crud User
export const getUserList = () => async (dispatch) => {
  let responseApi = await api.get(`api/user`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setUserList(responseApi));
  }
  return responseApi;
};

export const getListUserAndRolByProject = (id) => async (dispatch) => {
  let responseApi = await api
    .get(`api/user/project/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setUserList(responseApi));
  }
  return responseApi;
};

export const addOrUpdateUser = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/user/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/user/add-user-process", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getListUserAndRolByProject(data.idProject));
  }
  return responseApi;
};

export const deleteUser = (id, idProject) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/user/project/${id}/${idProject}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getListUserAndRolByProject(idProject));
  }
  return responseApi;
};
//end Crud user

//Asignation roles
export const asignationProfile = (data) => async (dispatch) => {
  let responseApi = await api
    .put(`api/rol/user-assigned-role`, data)
    .then(ResponseTransform);
  return responseApi;
};

//end asignation roles


export const activeUser = (data,id) => async (dispatch) => {
  let responseApi = await api
    .put(`api/rol/user-assigned-role`, data)
    .then(ResponseTransform);
    
    if(responseApi){
      dispatch(getListUserAndRolByProject(id));
    }
  return responseApi;
}
