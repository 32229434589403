const IconCamion = ({ width, height, bg, fill }) => {
  return (
    <svg
      width={width || "150"}
      height={height || "150"}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill="none" />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-672 -769)"
          fill="none"
        />
        <g id="icono_camion">
          <g id="dEaBA7.tif">
            <g id="Group">
              <path
                id="Vector"
                d="M0 98.0233C0 75.3488 0 52.6744 0 30C0.872053 30 0.872053 30 1.74411 30C34.0101 30 66.2761 30 97.67 30C98.542 30 98.542 30 99.4141 30C99.4141 52.6744 99.4141 75.3488 99.4141 98.0233C98.542 98.0233 98.542 98.0233 98.542 98.0233C79.3569 98.0233 60.1717 98.0233 40.1145 98.0233C39.2424 98.0233 38.3704 98.0233 38.3704 97.1512C32.266 91.9186 23.5454 91.9186 17.4411 97.1512C16.569 98.0233 15.697 98.0233 15.697 98.0233C9.59259 98.0233 4.36027 98.0233 0 98.0233Z"
                fill={fill}
              />
              <path
                id="Vector_2"
                d="M0 101.505C4.36027 101.505 8.72054 101.505 13.0808 101.505C12.2087 103.249 12.2087 104.993 11.3367 106.737C7.84848 106.737 3.48821 106.737 0 106.737C0 104.993 0 103.249 0 101.505Z"
                fill={fill}
              />
              <path
                id="Vector_3"
                d="M134.302 54.4152C133.43 54.4152 132.558 54.4152 132.558 54.4152C126.454 54.4152 119.477 54.4152 113.373 54.4152C111.629 54.4152 110.757 55.2873 110.757 57.0315C110.757 64.8803 110.757 72.7292 110.757 80.578C110.757 82.3222 111.629 83.1943 113.373 83.1943C124.71 83.1943 136.918 83.1943 148.255 83.1943C149.127 83.1943 149.127 83.1943 149.999 83.1943C149.999 91.0431 149.999 98.8919 149.999 107.613C147.383 107.613 145.639 107.613 143.023 107.613L142.151 106.741C140.407 99.764 136.918 95.4036 129.942 94.5315C121.221 92.7873 112.501 98.0199 111.629 106.741C111.629 107.613 110.757 108.485 109.885 108.485C107.268 108.485 105.524 108.485 102.908 108.485C102.908 88.4268 102.908 68.3687 102.908 48.3105C103.78 48.3105 103.78 48.3105 104.652 48.3105C112.501 48.3105 121.221 48.3105 129.07 48.3105C129.942 48.3105 130.814 48.3105 130.814 49.1826C131.686 50.0547 133.43 51.7989 134.302 54.4152Z"
                fill={fill}
              />
              <path
                id="Vector_4"
                d="M114.236 78.8338C114.236 71.8571 114.236 64.8803 114.236 57.0315H115.108C122.085 57.0315 128.189 57.0315 135.166 57.0315C136.038 57.0315 136.91 57.0315 136.91 57.9036C141.27 64.8803 144.758 70.985 149.118 77.9617C149.118 77.9617 149.118 77.9617 149.118 78.8338C137.782 78.8338 125.573 78.8338 114.236 78.8338Z"
                fill={fill}
              />
              <path
                id="Vector_5"
                d="M127.312 120.694C120.336 120.694 115.104 115.462 115.104 108.485C115.104 101.508 120.336 96.2756 127.312 96.2756C134.289 96.2756 139.521 101.508 139.521 108.485C139.521 115.462 133.417 120.694 127.312 120.694ZM120.336 108.485C120.336 111.973 122.952 114.59 126.44 114.59C129.928 114.59 132.545 111.973 132.545 107.613C132.545 104.124 129.928 101.508 126.44 101.508C122.952 102.38 120.336 104.997 120.336 108.485Z"
                fill={fill}
              />
              <path
                id="Vector_6"
                d="M39.2359 108.485C39.2359 115.462 34.0035 120.694 27.0271 120.694C20.0507 120.694 14.8184 115.462 14.8184 108.485C14.8184 101.508 20.0507 96.2756 27.0271 96.2756C34.0035 96.2756 39.2359 101.508 39.2359 108.485ZM20.9227 108.485C20.9227 111.973 23.5389 114.59 27.0271 114.59C30.5153 114.59 33.1315 111.973 33.1315 108.485C33.1315 104.997 30.5153 102.38 27.0271 102.38C23.5389 101.508 20.9227 104.997 20.9227 108.485Z"
                fill={fill}
              />
              <path
                id="Vector_7"
                d="M42.7246 106.737C41.8525 104.993 41.8525 103.249 40.9805 101.505C60.1656 101.505 79.3508 101.505 98.536 101.505C98.536 103.249 98.536 104.993 98.536 106.737C80.2229 106.737 61.9098 106.737 42.7246 106.737Z"
                fill={fill}
              />
              <path
                id="Vector_8"
                d="M123.824 108.482C123.824 106.737 125.568 105.865 127.312 105.865C129.057 105.865 129.929 107.609 129.929 109.354C129.929 111.098 128.184 112.842 126.44 111.97C124.696 111.098 123.824 110.226 123.824 108.482Z"
                fill={fill}
              />
              <path
                id="Vector_9"
                d="M30.5155 108.492C30.5155 110.236 28.7714 111.98 27.0273 111.98C25.2832 111.98 24.4111 110.236 23.5391 109.364C23.5391 107.62 24.4111 106.748 26.1552 105.876C28.7714 105.004 30.5155 106.748 30.5155 108.492Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconCamion;
