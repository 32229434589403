import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

import authSlice from "redux/auth.slice";
import catalogSlice from "./catalog.slice";
import monitoringSlice from "./monitoring.slice";
import planningSlice from "./planning.slice";
import userSlice from "./user.slice";
import configSlice from "./config.slice";

const reducers = combineReducers({
  auth: authSlice,
  catalog: catalogSlice,
  config: configSlice,
  planning: planningSlice,
  user: userSlice,
  monitoring: monitoringSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return reducers(state, action);
};

let REACT_APP_REDUX_KEY = process.env.REACT_APP_REDUX_KEY;
const persistConfig = {
  key: `gtrack"${REACT_APP_REDUX_KEY || ""}`,
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: REACT_APP_REDUX_KEY !== undefined,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
