import { Routes, Route,Navigate } from "react-router-dom";
import Monitoring from "./Modules/Monitoring";
import MonitoringTrips from "./Modules/MonitoringTrips";
import RestrictionRoute from "routes/RestrictionRoute";

const RouterMonitoring = ({views}) => {
  return (
    <Routes>
      <Route path="/maps" element={<RestrictionRoute views={views}><Monitoring /></RestrictionRoute>} />
      <Route path="/monitoring-trips" element={<RestrictionRoute views={views}><MonitoringTrips /></RestrictionRoute>} />
      <Route path="*" element={<Navigate to="../maps" replace />} />
    </Routes>
  );
};

export default RouterMonitoring;
