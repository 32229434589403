import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  token: "",
  tokenRefresh: "",
  user: {},
  remember: false,
  role: {},
  currentProject: {},
  myProjectsList: [],
  views: [],
  prevProject: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveAuth(state, action) {
      const { token, refreshToken, user, remember, roles } = action.payload;
      return { ...state, token, refreshToken, user, remember, roles };
    },
    logout() {
      return initialState;
    },
    setProject(state, action) {
      return {
        ...state,
        currentProject: action.payload,
        prevProject: state.currentProject,
      };
    },
    setView(state, action) {
      return {
        ...state,
        views: action.payload,
      };
    },
    setInfoUser(state, action) {
      const { views, projects, role } = action.payload;
      return {
        ...state,
        myProjectsList: projects,
        views,
        role,
        currentProject: projects[0],
      };
    },
    addProjectNewList(state, action) {
      let listProjects = [...state.myProjectsList];
      listProjects.push(action.payload);
      return {
        ...state,
        myProjectsList: listProjects,
      };
    },
    deleteProjectListUser(state, action) {
      try {
        if (!state.myProjectsLis && state.myProjectsList.length === 0) {
          return state;
        }
        let listProjects = [...state.myProjectsList];
        let id = action.payload;
        let index = listProjects.findIndex((item) => item.id === id);
        if (index > -1) {
          listProjects.splice(index, 1);
        }
        return {
          ...state,
          myProjectsList: listProjects,
        };
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export const {
  saveAuth,
  logout,
  setProject,
  setView,
  setInfoUser,
  addProjectNewList,
  deleteProjectListUser,
} = authSlice.actions;
export default authSlice.reducer;
