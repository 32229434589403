import React from "react";
import Layout from "layout";
import IconHistorico from "assets/icons/IconHistorico";
import IconCamion from "assets/icons/IconCamion";
import IconCalendar from "assets/icons/IconCalendar";
import IconAlerta from "assets/icons/IconAlerta";
import MapMonitor from "components/MapMonitor";
import IsoTipo from "assets/logos/IsoTipo";
import { useSelector } from "react-redux";
import { getCedisDestino, getCedisOrigin } from "actions/Catalogs";
import { useDispatch } from "react-redux";
import * as signalR from "@microsoft/signalr";
import { getGeofences } from "actions/Geofences";
import { getTripWithRoute } from "actions/Monitoring";
import { useTranslation } from "react-i18next";
import FiltersMonitoring from "components/FiltersMonitoring";
import { getIndicadores } from "actions/Admin";
import ErrorBoundary from "error/ErrorBoundary";


const Admin = () => {
  const [t2] = useTranslation("global");

  const { REACT_APP_URL_WEB_SOCKET } = process.env;
  const dispatch = useDispatch();
  const { currentProject, prevProject } = useSelector((state) => state.auth);
  const [connection, setConnection] = React.useState();
  const [isConnect, setIsConnect] = React.useState(false);
  const [geofences, setGeofences] = React.useState([]);
  const [indicators, setIndicators] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const { i18n } = useTranslation();

  React.useEffect(() => {
    dispatch(getCedisDestino());
    dispatch(getCedisOrigin());
  }, [dispatch]);

  React.useEffect(() => {
    getIndicadores(currentProject.id).then((response) => {
      let timeoutId = setTimeout(() => {
        setIndicators(response);
        if (timeoutId) clearTimeout(timeoutId);
      }, 2000);
    });
  }, [currentProject, i18n.language]);

  React.useEffect(() => {
    let connectionConfig = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_URL_WEB_SOCKET, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    connectionConfig
      .start()
      .then(() => {
        setConnection(connectionConfig);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      if (connectionConfig) {
        connectionConfig.stop();
      }
    };
  }, [REACT_APP_URL_WEB_SOCKET]);

  React.useEffect(() => {
    if (connection && currentProject && currentProject?.name) {
      connection
        .invoke("JoinGroupAsync", currentProject.name)
        .then(() => {
          setIsConnect(true);
          console.log("Conectado a", currentProject.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [connection, currentProject]);

  React.useEffect(() => {
    if (currentProject !== null && currentProject?.id) {
      getGeofences(currentProject.id).then((response) => {
        setGeofences(response);
      });
    }
  }, [currentProject]);

  React.useEffect(() => {
    if (prevProject && prevProject.id && connection) {
      connection
        .invoke("LeaveGroupAsync", prevProject.name)
        .then(() => {
          console.log("Desconectando de", prevProject.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [prevProject, connection]);

  const getIcon = (iconName) => {
    switch (iconName) {
      case "time": {
        return (
          <IconHistorico height={40} width={40} bg="none" fill={"#000000"} />
        );
      }
      case "car": {
        return <IconCamion height={40} width={40} bg="none" fill={"#000000"} />;
      }
      case "calendar": {
        return (
          <IconCalendar height={40} width={40} bg="none" fill={"#000000"} />
        );
      }
      case "warning": {
        return <IconAlerta height={40} width={40} bg="none" fill={"#000000"} />;
      }
      default: {
        return null;
      }
    }
  };

  const renderFilters = () => {
    return (
      <FiltersMonitoring
        hiddenFilterOperador={true}
        handleSearch={(values) => setFilters(values)}
      />
    );
  };

  const renderCards = () => {
    if (!indicators || indicators.length === 0) {
      return [...new Array(4)].map(() => (
        <div
          role="status"
          className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
        >
          <div className="w-full">
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
            <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
          </div>
        </div>
      ));
    }

    return indicators.map((item, index) => {
      const { type, value, color, icon } = item;
      return (
        <div
          className="flex flex-col"
          style={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          }}
          key={`card_${index}`}
        >
          <div className="bg-[#222222] h-[56px] flex justify-start items-center rounded-t-lg pl-2">
            <IsoTipo width={25} height={25} />
            <span className="text-white text-[20px] font-[700] ml-2">
              {type}
            </span>
          </div>
          <div className="flex flex-row bg-white flex justify-center items-center flex-row min-h-[80px]">
            {icon && getIcon(icon)}
            <div
              className={`w-[141px] h-[50px] rounded-[10px] flex  justify-center items-center mx-2`}
              style={{ backgroundColor: color }}
            >
              <h6 className="text-white text-[40px] text-center pr-4 font-[400]">
                {value}
              </h6>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <Layout iconName={"home"} textRoute={t2("modules.title_dashboard")}>
      <ErrorBoundary>
        <div className="grid grid-rows-dashboard gap-2 h-full">
          <div className="mt-8 w-full grid grid-cols-4 gap-4">
            {renderCards()}
          </div>
          <div className="grid grid-rows-bar-map py-2">

            <div className="bg-[#222222] w-full flex justify-between rounded-t-lg pl-2">
              <div className="flex flex-row justify-start items-center gap-x-2">
                <IsoTipo width={40} height={40} />
                <span className="text-white text-[20px] font-[700]">
                  {t2("modules.title_monitoring")}
                </span>
              </div>
              <div className="flex flex-row py-2 px-2 justify-center items-center">
                {renderFilters()}
              </div>
            </div>
            <div className="" key="map_key">

              <MapMonitor
                geofences={geofences}
                getDataTripWithRoute={getTripWithRoute}
                conectionSocket={connection}
                isConnect={isConnect}
                filters={filters}
                projectId={currentProject?.id}
              />

            </div>

          </div>
        </div>
      </ErrorBoundary>
    </Layout>
  );
};

export default Admin;
