import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const MapAddress = ({
  zoom,
  center,
  position,
  setPosition,
  refreshDragMarker,
}) => {
  const { REACT_APP_API_KEY_MAPS } = process.env;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_API_KEY_MAPS,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="h-[90%] w-full"
      zoom={zoom}
      center={center}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
      }}
      onClick={(event) => {
        let coords = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        setPosition(coords);
        refreshDragMarker(coords);
      }}
    >
      {position ? (
        <Marker
          position={{lat: position.lat, lng: position.lng}}
          draggable={true}
          onDragEnd={({ latLng }) => {
            refreshDragMarker({ lat: latLng.lat(), lng: latLng.lng() });
          }}
          key="address-point"
        />
      ) : null}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapAddress;
