import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Joi from "joi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import InputCustom from "components/InputCustom";
import {
  getOperatorList,
  addOrUpdateOperator,
  deleteOperator,
  getCarrier,
} from "actions/Catalogs";
import { notify } from "utils";
import { format, parseISO } from "date-fns";
import { multiFilter, SelectColumnFilterStatus } from "components/SelectFilterStatus/SelectFilterStatus";
import { filterDate } from "components/DateCatalogs/filterDate";


const Operator = ({ view }) => {
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");
  const dispatch = useDispatch();
  const { operators, carriersList } = useSelector((state) => state.catalog);
  const { currentProject } = useSelector((state) => state.auth);

  const [openModal, setOpenModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);

  const { permissions } = view;

  const { state, setData, setExplicitField, validate } = useValidator({
    initialData: {
      idLicenseType: null,
      idCarrier: null,
      email: null,
      name: null,
      lastName: null,
      secondLastName: null,
    },
    schema: Joi.object({
      idLicenseType: Joi.number().required(),
      idCarrier: Joi.number().required(),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .min(2)
        .max(60)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.email': t("error.email"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '60' }),
          'any.required': t("error.required")
        }),
      name: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      lastName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      secondLastName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      idLicenseType: false,
      idCarrier: false,
      email: false,
      name: false,
      lastName: false,
      secondLastName: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });


  const buttonRef = React.useRef();

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispatch(getOperatorList(currentProject.id));
    }
  }, [dispatch, currentProject]);


  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispatch(getCarrier(currentProject.id));
    }
  }, [dispatch, currentProject]);


  const handleOnSubmit = async () => {
    const { idLicenseType, idCarrier, email, name, lastName, secondLastName } =
      state.$data;
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      setShowError(true);
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    let isSuccess = await dispatch(
      addOrUpdateOperator({
        idLicenseType,
        idCarrier,
        idProject: currentProject.id,
        user: { email, name, lastName, secondLastName },
        id,
      })
    );

    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    setIsLoad(false);
    setShowError(false);

    if (isSuccess) {
      setData((old) => ({
        ...old,
        idLicenseType: null,
        idCarrier: null,
        email: null,
        name: null,
        lastName: null,
        secondLastName: null,
      }));
      setId(null);
      setOpenModal(false);
    }
  };


  const handleActive = async (data) => {
    let title = t("active.title_enable");
    let description = t("active.enable");

    if (data.user["active"]) {
      title = t("active.title_disable");
      description = t("active.disable");
    }

    Swal.fire({
      title: title,
      text: description,
      icon: "warning",
      confirmButtonText: t("active.btn_continue"),
      cancelButtonText: t("active.btn_cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && data) {
        let active = true;
        const { id, idLicenseType, idCarrier, user } = data;
        const { email, name, lastName, secondLastName } = user;
        if (data.user["active"]) {
          active = false;
        }
        await dispatch(
          addOrUpdateOperator({
            idLicenseType,
            idCarrier,
            idProject: currentProject.id,
            user: { email, name, lastName, secondLastName, active },
            id,
          })
        );
      }
    });
  };

  const handleDelete = async (idOperator) => {
    Swal.fire({
      title: tg("button.title"),
      text: t("operators.delete"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && idOperator) {
        dispatch(deleteOperator(idOperator, currentProject.id));
      }
    });
  };

  return (
    <>
      <Grid
        title={t("operators.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: 50
          },
          {
            Header: t("operators.name"),
            accessor: "user.name",
            filter: "text",
            width: 300
          },
          {
            Header: t("operators.last_name"),
            accessor: "user.lastName",
            filter: "text",
            width: 300
          },
          {
            Header: t("operators.second_last_name"),
            accessor: "user.secondLastName",
            filter: "text",
            width: 300
          },
          {
            Header: t("operators.email"),
            accessor: "user.email",
            filter:true,
            width: 300
          },
          {
            Header: "Status",
            accessor: "user.active",
            Filter: SelectColumnFilterStatus,
            filter:multiFilter,
            Cell: ({ value }) => {
              return value ? (
                <div className="flex items-center ">
                  <div className="h-2 w-2 bg-green-500 rounded-full"></div>
                </div>
              ) : (
                <div className="flex items-center ">
                  <div className="h-2 w-2 bg-red-500 rounded-full"></div>
                </div>
              );
            },
            width: 100
          },
          {
            Header: t("operators.updated_at"),
            accessor: "user.updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: 300
          },
        ]}
        data={operators || []}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const {
            idLicenseType,
            idCarrier,
            user: { email, name, lastName, secondLastName },
            id,
          } = row;
          setId(id);
          setData((old) => ({
            ...old,
            idLicenseType,
            idCarrier,
            email,
            name,
            lastName,
            secondLastName,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ user }) => handleDelete(user.id)}
        write={permissions.write}
        AdditionalOptionsRows={({ row }) => {
          const { original } = row;
          return permissions?.write ? (
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-gray-200 hover:text-gray-900"
              onClick={() => {
                handleActive(original);
              }}
            >
              {original.user.active ? t("disable") : t("enable")}
            </button>
          ) : null;
        }}
      />
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setData((old) => ({
              ...old,
              idLicenseType: null,
              idCarrier: null,
              email: null,
              name: null,
              lastName: null,
              secondLastName: null,
            }));
            setShowError(false);
            setOpenModal(false);
            setId(null);
          }}
          titleModal={t("operators.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("operators.info_modal")}
          </h4>
          <div className="flex flex-wrap ">
            <InputCustom
              type={"select"}
              labelText={t("operators.license_type")}
              errorMessage={
                showError && state.$errors.idLicenseType.length > 0 ? t("error.general") : null
              }
              options={[
                {
                  value: 1,
                  text: "A",
                },
                {
                  value: 2,
                  text: "B",
                },
              ]}
              onBlur={() => setExplicitField("idLicenseType", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idLicenseType: e.target.value,
                }));
              }}
              value={state.$data.idLicenseType}
            />
            <InputCustom
              type={"select"}
              labelText={t("operators.carrier")}
              errorMessage={
                showError && state.$errors.idCarrier.length > 0 ? t("error.general") : null
              }
              options={
                carriersList &&
                carriersList.map((carrier) => ({
                  value: carrier.id,
                  text: carrier.name,
                }))
              }
              onBlur={() => setExplicitField("idCarrier", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idCarrier: e.target.value,
                }));
              }}
              value={state.$data.idCarrier}
            />
            <InputCustom
              type={"text"}
              labelText={t("operators.email")}
              errorMessage={
                showError && state.$errors.email.length > 0 ? state.$errors.email[0].$message : null
              }
              onBlur={() => setExplicitField("email", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  email: e.target.value,
                }));
              }}
              value={state.$data.email}
              disabled={id ? true : false}
            />
            <InputCustom
              type={"text"}
              labelText={t("operators.name")}
              errorMessage={
                showError && state.$errors.name.length > 0 ? state.$errors.name[0].$message : null
              }
              onBlur={() => setExplicitField("name", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name}
            />
            <InputCustom
              type={"text"}
              labelText={t("operators.last_name")}
              errorMessage={
                showError && state.$errors.lastName.length > 0 ? state.$errors.lastName[0].$message : null
              }
              onBlur={() => setExplicitField("lastName", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  lastName: e.target.value,
                }));
              }}
              value={state.$data.lastName}
            />
            <InputCustom
              type={"text"}
              labelText={t("operators.second_last_name")}
              errorMessage={
                showError && state.$errors.secondLastName.length > 0 ? state.$errors.secondLastName[0].$message : null
              }
              onBlur={() => setExplicitField("secondLastName", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  secondLastName: e.target.value,
                }));
              }}
              value={state.$data.secondLastName}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Operator;
