import { ResponseTransform } from "utils";
import api from "api";

//Crud User

export const getMonitorinTrips = async (idProject, startDate, endDate,idOperator) => {
  let queryParameters = '';
  if(idOperator) {
    queryParameters += `idOperator=${idOperator}&`
  }
  if(startDate) {
    queryParameters += `dateInit=${startDate}&`
  }
  if(endDate) {
    queryParameters += `dateEnd=${endDate}`
  }
  let endPoint = `/api/Trip/historyByProject/${idProject}?${queryParameters}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const getStatus = async () => {
  let endPoint = `/api/Trip/status-trips`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const getCarrier = async (idCarrier) => {
  let endPoint = `/api/carrier/${idCarrier}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const getStage = async (idTrip) => {
  let endPoint = `/api/trip/itinerary/${idTrip}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const getAnswers = async (idResponseStage) => {
  let endPoint = `/api/stage/${idResponseStage}/answers`;
  const responseApi = await api.get(endPoint).then(ResponseTransform);
  return responseApi;
};

export const getTripWithRoute = async (idTrip) => {
  let endPoint = `/api/trip/get-trip-with-route/${idTrip}`;
  let responseApi = await api.get(endPoint).then(ResponseTransform);
  return responseApi;
};

export const getBodyAlert = async (idTrip) => {
  let endPoint = `/api/trip/${idTrip}/body-trip-alert`;
  let responseApi = await api.get(endPoint).then(ResponseTransform);
  return responseApi;
};

//get Operator inut select
export const getOperatorWithTrip = async (idProject) => {
  let endPoint = `/api/Project/${idProject}/operators-with-trip`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};
