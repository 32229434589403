import { useMemo } from "react";

export const SelectColumnFilterStatus = ({

    column: { filterValue, setFilter, preFilteredRows },
}) => {
    const options = useMemo(() => {

        let variable;
        const options = new Set();
        preFilteredRows.forEach((row) => {
            if (String(row.original.user['active']) === 'true') {
                variable = "Activo"
                if (localStorage.getItem("language") === "en") {
                    variable = "Enable"
                }
            }
            if (String(row.original.user['active']) === 'false') {
                variable = "Inactivo"
                if (localStorage.getItem("language") === "en") {
                    variable = "Disable"
                }
            }

            options.add(variable);
        });
        return [...options.values()];
    }, [preFilteredRows]);

    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export function multiFilter(rows, columnIds, filterValue) {

    let validacion;
    switch (filterValue) {
        case 'Activo':
        case 'activo':
        case 'Enable':
        case 'enable':
            validacion = 'true';
            break;
        case 'Inactivo':
        case 'inactivo':
        case 'Disable':
        case 'disable':
            validacion = 'false';
            break;
        default:
            validacion = filterValue;
            break;
    }

    return filterValue.length === 0
        ? rows
        : rows.filter((row) =>
            validacion.includes(String(row.original.user['active'])),
        );
}