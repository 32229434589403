import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import InputCustom from "components/InputCustom";
import Joi from "joi";
import Swal from "sweetalert2";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { notify } from "utils";
import {
  getCedisDestino,
  addOrUpdateCedisDestino,
  deleteCedisDestino,
  getClientList,
} from "actions/Catalogs";
import AddressForm from "components/AddressForm";
import { parseISO, format } from "date-fns/esm";
import { filterDate } from "components/DateCatalogs/filterDate";

const CedisDestination = ({ view }) => {
  const dispatch = useDispatch();
  const { clients, cedisDestinationList } = useSelector(
    (state) => state.catalog
  );
  const { currentProject } = useSelector((state) => state.auth);

  const [openModal, setOpenModal] = React.useState(false);
  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);
  const [idAddress, setIdAddress] = React.useState();
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");

  const [coords, setCoords] = React.useState({});
  const [defaultCoords, setDefaultCoords] = React.useState({});

  const { permissions } = view;

  const { state, setData, setExplicitField, validate, reset } = useValidator({
    initialData: {
      code: null,
      shipToName: null,
      shipTo: null,
      soldToName: null,
      soldTo: null,
      projectId: null,
      country: null,
      state: null,
      city: null,
      cologne: null,
      postalCode: null,
      street: null,
      exteriorNumber: null,
      interiorNumber: "",
      codeAddress: null,
      description: null,
    },
    schema: Joi.object({
      code: Joi.string()
        .min(2)
        .max(100)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '100' }),
          'any.required': t("error.required")
        }),
      shipToName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      shipTo: Joi.string()
        .min(2)
        .max(20)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '20' }),
          'any.required': t("error.required")
        }),
      soldToName: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
      soldTo: Joi.string()
        .min(2)
        .max(10)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '10' }),
          'any.required': t("error.required")
        }),
      projectId: Joi.number().required(),
      country: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
      state: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
      city: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
      cologne: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
      postalCode: Joi.string().length(5).message(t("error.max_length")).pattern(/^[0-9]+$/).message(t("error.number_format")).required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'number' }),
          'any.required': t("error.required")
        }),
      street: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
      exteriorNumber: Joi.string()
        .min(2)
        .max(10)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '10' }),
          'any.required': t("error.required")
        }),
      interiorNumber: Joi.string()
        .min(2)
        .max(10)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '10' }),
          'any.required': t("error.required")
        }),
      codeAddress: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      description: Joi.string()
        .min(2)
        .max(150)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '150' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      code: false,
      shipToName: false,
      shipTo: false,
      soldToName: false,
      soldTo: false,
      projectId: false,
      country: false,
      state: false,
      city: false,
      cologne: false,
      postalCode: false,
      street: false,
      exteriorNumber: false,
      interiorNumber: false,
      codeAddress: false,
      description: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  const buttonRef = React.useRef();

  React.useEffect(() => {
    dispatch(getClientList());
  }, [dispatch]);

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispatch(getCedisDestino(currentProject?.id));
    }
  }, [dispatch, currentProject]);

  const handleOnSubmit = async () => {
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    const {
      code,
      shipToName,
      shipTo,
      soldToName,
      soldTo,
      projectId,
      country,
      city,
      cologne,
      postalCode,
      street,
      exteriorNumber,
      interiorNumber,
      codeAddress,
      description,
    } = state.$data;

    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    let isSuccess = await dispatch(
      addOrUpdateCedisDestino({
        code,
        shipToName,
        shipTo,
        soldToName,
        soldTo,
        IdProject: projectId,
        address: {
          country,
          state: state.$data.state,
          city,
          cologne,
          postalCode,
          street,
          exteriorNumber,
          interiorNumber,
          code: codeAddress,
          description,
          lat: coords ? coords.lat : 0,
          lon: coords ? coords.lng : 0,
          IdProject: projectId,
          id: idAddress,
        },
        id,
      })
    );
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);

    if (isSuccess) {
      clearForm();
      setIdAddress(null);
      setOpenModal(false);
    }
  };

  const handleDelete = async (idCedis, idProject) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteCedisDestino(idCedis, idProject));
      }
    });
  };
  const clearForm = () => {
    setDefaultCoords({});
    reset();
    setId(null);
  };

  return (
    <>
      <Grid
        title={t("cedisDestination.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
          },
          {
            Header: t("cedisDestination.shipt_to"),
            accessor: "shipTo",
            filter: "text",
          },
          {
            Header: t("cedisDestination.shipt_to_name"),
            accessor: "shipToName",
            filter: false,
          },
          {
            Header: "Sold To",
            accessor: "soldTo",
            filter: "text",
          },
          {
            Header: "Sold To Name",
            accessor: "soldToName",
            filter: "text",
          },
          {
            Header: tg("addressForm.address"),
            accessor: "address.code",
            filter: false,
          },
          {
            Header: tg("addressForm.references"),
            accessor: "address.description",
            filter: false,
          },
          {
            Header: tg("addressForm.country"),
            accessor: "address.country",
            filter: false,
          },
          {
            Header: tg("addressForm.state"),
            accessor: "address.state",
            filter: false,
          },
          {
            Header: tg("addressForm.municipality"),
            accessor: "address.city",
            filter: false,
          },
          {
            Header: tg("addressForm.colony"),
            accessor: "address.cologne",
            filter: false,
          },
          {
            Header: tg("addressForm.street"),
            accessor: "address.street",
            filter: false,
          },
          {
            Header: tg("addressForm.outside_number"),
            accessor: "address.exteriorNumber",
            filter: false,
          },
          {
            Header: tg("addressForm.inside_number"),
            accessor: "address.interiorNumber",
            filter: false,
          },
          {
            Header: tg("addressForm.postal_code"),
            accessor: "address.postalCode",
            filter: false,
          },
          {
            Header: t("cedisDestination.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
          },
        ]}
        data={cedisDestinationList || []}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const {
            id,
            code,
            shipToName,
            shipTo,
            soldToName,
            soldTo,
            idProject,
            address: {
              country,
              city,
              cologne,
              postalCode,
              street,
              exteriorNumber,
              interiorNumber,
              description,
              lat,
              lon,
            },
          } = row;

          setId(id);
          setIdAddress(row.address.id);
          setData((old) => ({
            ...old,
            code,
            shipToName,
            shipTo,
            soldToName,
            soldTo,
            projectId: idProject,
            country,
            state: row.address.state,
            city,
            cologne,
            postalCode: postalCode.toString(),
            street,
            exteriorNumber,
            interiorNumber,
            codeAddress: row.address.code,
            description,
          }));
          setDefaultCoords({ lat: lat, lng: lon });
          setOpenModal(true);
        }}
        handleDelete={({ id, idProject }) => handleDelete(id, idProject)}
        write={permissions.write}
      />

      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            clearForm();
            setOpenModal(false);
            setId(null);
          }}
          titleModal={t("cedisDestination.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
          size={"xlg"}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("cedisDestination.info_modal")}
          </h4>
          <div className="grid grid-cols-3 gap-2">
            <InputCustom
              type={"text"}
              labelText="Code:"
              errorMessage={
                state.$errors.code.length > 0 ? state.$errors.code[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  code: e.target.value,
                }));
              }}
              value={state.$data.code ? state.$data.code : ""}
              onBlur={() => setExplicitField("code", true)}
              keyId="code"
              toolTip={t("cedisDestination.tooltip.general")}
            />
            <InputCustom
              type={"text"}
              labelText={t("cedisDestination.shipt_to")}
              errorMessage={
                state.$errors.shipTo.length > 0 ? state.$errors.shipTo[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  shipTo: e.target.value,
                }));
              }}
              value={state.$data.shipTo ? state.$data.shipTo : ""}
              onBlur={() => setExplicitField("shipTo", true)}
              keyId="shipto"
              toolTip={t("cedisDestination.tooltip.general")}
            />
            <InputCustom
              type={"text"}
              labelText={t("cedisDestination.shipt_to_name")}
              errorMessage={
                state.$errors.shipToName.length > 0 ? state.$errors.shipToName[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  shipToName: e.target.value,
                }));
              }}
              value={state.$data.shipToName ? state.$data.shipToName : ""}
              onBlur={() => setExplicitField("shipToName", true)}
              key="shiptoname"
              toolTip={t("cedisDestination.tooltip.general")}
            />
            <InputCustom
              type={"text"}
              labelText={t("cedisDestination.sold_to_name")}
              errorMessage={
                state.$errors.soldToName.length > 0 ? state.$errors.soldToName[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  soldToName: e.target.value,
                }));
              }}
              value={state.$data.soldToName ? state.$data.soldToName : ""}
              onBlur={() => setExplicitField("soldToName", true)}
              keyId="soldToName"
              toolTip={t("cedisDestination.tooltip.general")}
            />
            <InputCustom
              type={"text"}
              labelText={t("cedisDestination.sold_to")}
              errorMessage={
                state.$errors.soldTo.length > 0 ? state.$errors.soldTo[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  soldTo: e.target.value,
                }));
              }}
              value={state.$data.soldTo ? state.$data.soldTo : ""}
              onBlur={() => setExplicitField("soldTo", true)}
              keyId="soldTo"
              toolTip={t("cedisDestination.tooltip.general")}
            />
            <InputCustom
              type={"select"}
              labelText={t("cedisDestination.project")}
              errorMessage={
                state.$errors.projectId.length > 0 ? t("error.general") : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  projectId: e.target.value,
                }));
              }}
              options={
                clients &&
                clients.map((item) => ({
                  value: item.id,
                  text: item.name,
                }))
              }
              value={state.$data.projectId ? state.$data.projectId : ""}
              onBlur={() => setExplicitField("projectId", true)}
              keyId="projectId"
            />
          </div>
          <AddressForm
            setData={setData}
            setExplicitField={setExplicitField}
            state={state}
            setCoords={setCoords}
            defaultCoords={defaultCoords}
          />
        </Modal>
      ) : null}
    </>
  );
};
export default CedisDestination;
