import React from "react";
import Layout from "layout";
import { useTranslation } from "react-i18next";

const Default = () => {
  const [t] = useTranslation("default");

  return (
    <Layout iconName={"default"}>
      <main class="h-screen w-full flex flex-col justify-center items-center ">
        <h1 class="text-9xl font-extrabold  tracking-widest">401</h1>
        <div class="bg-[#474b4e] px-2 text-sm rounded rotate-12 absolute">
          <span className="font-[Gravity] text-[white]">{t("default.title")}</span>
        </div>
        <button class="mt-5">
          <a
            class="relative inline-block text-sm font-medium text-[white] group active:text-orange-500 focus:outline-none focus:ring"
            href="#blanck"
          >
            <span class="relative block px-8 py-3 bg-[#1A2238] border border-current font-[Gravity]">
              {t("default.description")}
            </span>
          </a>
        </button>
      </main>
    </Layout>
  );
};

export default Default;
