import * as React from "react";
import Layout from "layout";
import RouterMonitoring from "./RouterMonitoring";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "error/ErrorBoundary";

const Monitoring = ({ childrenMenu = [] }) => {
  const [t] = useTranslation("global");
  return (
    <Layout iconName={"monitoring"} textRoute={t("modules.title_monitoring")}>
      <ErrorBoundary>
        <RouterMonitoring views={childrenMenu} />
      </ErrorBoundary>
    </Layout>
  );
};

export default Monitoring;
