import * as React from "react";
import Layout from "layout";
import RoutersConfig from "./RoutersConfig";
import { useSelector } from "react-redux";
import BarVertical from "components/BarVertical";
import MenuItemsModule from "components/MenuItemsModule";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "error/ErrorBoundary";

const Config = ({ childrenMenu = [] }) => {
  const { clients, unitType, unit } = useSelector((state) => state.catalog);
  const [t] = useTranslation("global");
  const [filter, setFilter] = React.useState([]);


  React.useEffect(() => {
    let menuChildren = childrenMenu.filter((row) => row.description !== 'group-option-id');
    setFilter(menuChildren);
  }, [childrenMenu])


  return (
    <Layout iconName={"config"} textRoute={t("modules.title_configuration")}>
      <div className="mt-10 grid grid-cols-20/80 gap-x-2 min-h-[450px] pr-2">
        <BarVertical title={t("modules.title_configuration")}>
          <ErrorBoundary>
            <MenuItemsModule items={filter} />
          </ErrorBoundary>
        </BarVertical>{" "}
        <div className="overflow-x-hidden">
          <ErrorBoundary>
            <RoutersConfig
              clients={clients}
              unitType={unitType}
              units={unit}
              views={childrenMenu}
            />
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  );
};
export default Config;
