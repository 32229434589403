import { ResponseTransform } from "utils";
import { saveAuth, setInfoUser, setView } from "redux/auth.slice";
import api from "api";
import { Buffer } from "buffer";

export const AuthAction =
  ({ user, password, remember }) =>
  async (dispatch) => {
    let responseApi = await api
      .post("api/auth/access-token", {
        user,
        password,
      })
      .then(ResponseTransform);
    if (responseApi) {
      let dataJwt = responseApi.token.split(".")[1];
      let buff = Buffer.from(dataJwt, "base64");
      let jsonString = buff.toString("utf-8");
      let dataUserObject = JSON.parse(jsonString);
      let informationUser = await dispatch(
        GetInformationUserByToken(responseApi.token)
      );
      let routerDefault = "/default";
      if (informationUser) {
        const { views } = informationUser;
        if (views && views[0]) {
          routerDefault = views[0].url;
        }
      }
      dispatch(
        saveAuth({
          ...responseApi,
          remember,
          user: dataUserObject,
        })
      );
      return {
        responseApi,
        changePassword: dataUserObject.changePassword.toLowerCase() === "true",
        routerDefault,
      };
    }
    return {};
  };

export const GetInformationUserByToken = (token) => async (dispatch) => {
  api.setToken(token);
  let responseApi = await api.get("api/user/info-user").then(ResponseTransform);
  if (responseApi) {
    dispatch(setInfoUser(responseApi));
    return responseApi;
  }
  return null;
};

export const GetInformationUserByTokenView = (token) => async (dispatch) => {
  api.setToken(token);
  let responseApi = await api.get("api/user/info-user").then(ResponseTransform);
  if (responseApi) {
    const {views}= responseApi;
    dispatch(setView(views));
    return responseApi;
  }
  return null;
};
