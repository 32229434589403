import React from "react";
import { useTranslation } from "react-i18next";
import { getCedisOrigin, getCedisDestino } from "actions/Catalogs";
import InputCustom from "components/InputCustom";
import { useDispatch, useSelector } from "react-redux";
import { getOperatorWithTrip } from "actions/Monitoring";

const FiltersMonitoring = ({
  hiddenFilterOperador,
  handleSearch = () => { },
}) => {
  const [t2] = useTranslation("monitoring");
  const { PUBLIC_URL } = process.env;
  const { cedisDestinationList, cedisOriginList } = useSelector(
    (state) => state.catalog
  );
  const { currentProject } = useSelector((state) => state.auth);
  const [formFilters, setFormFilters] = React.useState({});
  const [operatorList, setOperatorList] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispatch(getCedisOrigin(currentProject?.id));
      dispatch(getCedisDestino(currentProject?.id));
    }
  }, [dispatch, currentProject]);

  React.useEffect(() => {
    if (currentProject?.id && !hiddenFilterOperador) {
      getOperatorWithTrip(currentProject?.id).then((response) => {
        setOperatorList(response);
      });
    }
  }, [currentProject, hiddenFilterOperador]);


  return (
    <>
      {!hiddenFilterOperador && (
        <div className="flex flex-col items-start p-1">
          <label className="text-white text-[12px] font-[Gravity]">
            {t2("monitoring.operator")}:
          </label>
          <InputCustom
            type={"select"}
            onChange={({ target: { value } }) => {
              setFormFilters((oldValues) => ({
                ...oldValues,
                idOperator: value,
              }));
            }}
            options={
              operatorList
                ? operatorList.map((item) => ({
                  value: item.id,
                  text: `${item.user['name']} ${item.user['lastName']}`,
                }))
                : []}
          />
        </div>
      )}
      <div className="flex flex-col items-start p-1 pt-5">
        <InputCustom
          type="text"
          onChange={({ target: { value } }) =>
            setFormFilters((oldValues) => {
              return { ...oldValues, loadId: value, delivery: value };
            })
          }
          placeholder="Buscar por load id, delivery"
          size={"m-sm"}
        />
      </div>
      <div className="flex flex-col items-start p-1">
        <label className="text-white text-[12px] font-[Gravity]">
          {t2("monitoring.cedis_origin")}:
        </label>
        <InputCustom
          type="select"
          onChange={({ target: { value } }) =>
            setFormFilters((oldValues) => {
              return { ...oldValues, shPt: value };
            })
          }
          options={
            cedisOriginList
              ? cedisOriginList.map((item) => ({
                value: item.shPt,
                text: item.shPt,
              }))
              : []
          }
          size={"m-sm"}
        />
      </div>
      <div className="flex flex-col items-start p-1">
        <label className="text-white text-[12px] font-[Gravity]">
          {t2("monitoring.cedis_destination")}
        </label>
        <InputCustom
          type="select"
          onChange={({ target: { value } }) =>
            setFormFilters((oldValues) => {
              return { ...oldValues, shipTo: value };
            })
          }
          options={
            cedisDestinationList
              ? cedisDestinationList.map((item) => ({
                value: item.shipTo,
                text: item.shipTo,
              }))
              : []
          }
          size={"m-sm"}
        />
      </div>
      <button
        className="bg-[#d3d3d3] rounded-full h-[30px] w-[30px]  hover:bg-gray-200 p-2 flex items-center justify-center"
        onClick={() => {
          handleSearch && handleSearch(formFilters);
        }}
        title={t2("monitoring.search_trips")}
      >
        <img
          src={`${PUBLIC_URL}/icons/btns/search.png`}
          className={"w-[100%] h-[25px] object-cover"}
          alt="#"
        />
      </button>
    </>
  );
};

export default FiltersMonitoring;
