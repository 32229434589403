import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-tailwind/react";

const InputCustom = ({
  type,
  labelText,
  onChange,
  value,
  onBlur,
  keyId,
  options,
  size,
  disabled,
  placeholder,
  iconLeft,
  errorMessage,
  toolTip,
  defaultValue
}) => {
  const [t] = useTranslation("global");
  const renderInput = ({ padding = true }) => {
    let className = "py-1 px-1";
    if (type === "checkbox") {
      return <input type={"checkbox"} onChange={onChange} onBlur={onBlur} />;
    }

    if (type === "select") {
      return (
        <select
          className={`rounded-[10px] bg-[#DEDEDE] outline-none ${
            padding ? className : ""
          }`}
          key={keyId}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          onBlur={onBlur}
          disabled={disabled}
        >
          <option value={""}>{t("input.select_input")}</option>
          {options
            ? options.map((item, index) => (
                <option value={item.value} key={`${item.id}-${index}`}>
                  {item.text}
                </option>
              ))
            : null}
        </select>
      );
    }

    return (
      <input
        type={type}
        className={`rounded-[10px] bg-[#DEDEDE] outline-none ${disabled ? 'shadow-md shadow-gray-800':''} ${
          padding ? className : ""
        }`}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        key={keyId}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    );
  };

  const renderIcon = () => {
    return (
      <div className=" rounded-[10px] bg-[#DEDEDE] py-1 px-1 flex flex-wrap items-center">
        <div className="px-1">
          <img
            src={iconLeft}
            className={"w-[100%] h-[20px] object-cover"}
            alt="#"
          />
        </div>
        {renderInput({ padding: false })}
      </div>
    );
  };

  const getSize = () => {
    if (size === "sm") return "min-w-[100px] my-1 mx-1";
    if (size === "m-sm") return "max-w-[150px] my-1 mx-1";
    if (size === "lg-min") return "max-w-[250px] my-1 mx-1";
    if (size === "xm") return "min-w-[40px] my-[.5px] mx-1";
    return "min-w-[250px] my-[.5px] mx-1";
  };
  return (
    <div className={`flex flex-col   ${getSize()}`}>
      <div className="flex flex-row justify-cente items-center">
        {labelText ? (
          <label className="text-black font-[Gravity] text-[14px] font-[350] ">
            {labelText}&nbsp;
            {errorMessage ? (
              <span className="font-[Gravity] text-[#FF3447] text-[10px] ">
                {errorMessage}
              </span>
            ) : null}
          </label>
        ) : null}
        {toolTip ? (
          <>
            <Tooltip
              content={toolTip}
              className="z-[9999]"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
              style={{ zIndex: 9999, fontFamily: "Gravity" }}
            >
              <button className="w-2 h-2 rounded-full ml-4 mb-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 150 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M75 0C33.5826 0 0 33.5826 0 75C0 116.417 33.5826 150 75 150C116.417 150 150 116.417 150 75C150 33.5826 116.417 0 75 0ZM75 118.527C71.3002 118.527 68.3036 115.53 68.3036 111.83C68.3036 108.131 71.3002 105.134 75 105.134C78.6998 105.134 81.6964 108.131 81.6964 111.83C81.6964 115.53 78.6998 118.527 75 118.527ZM85.5301 81.7801C84.014 82.3656 82.7095 83.3943 81.7868 84.7322C80.864 86.0701 80.3658 87.6549 80.3571 89.2801V93.0804C80.3571 93.817 79.7545 94.4196 79.0179 94.4196H70.9821C70.2455 94.4196 69.6429 93.817 69.6429 93.0804V89.481C69.6429 85.6138 70.7645 81.7969 72.9743 78.6161C75.1339 75.5022 78.1473 73.125 81.6964 71.769C87.3884 69.5759 91.0714 64.8047 91.0714 59.5982C91.0714 52.2154 83.856 46.2054 75 46.2054C66.144 46.2054 58.9286 52.2154 58.9286 59.5982V60.8705C58.9286 61.6071 58.3259 62.2098 57.5893 62.2098H49.5536C48.817 62.2098 48.2143 61.6071 48.2143 60.8705V59.5982C48.2143 53.019 51.0937 46.875 56.317 42.3047C61.3393 37.9018 67.9688 35.4911 75 35.4911C82.0313 35.4911 88.6607 37.9185 93.683 42.3047C98.9062 46.875 101.786 53.019 101.786 59.5982C101.786 69.2746 95.4074 77.9799 85.5301 81.7801Z"
                    fill="#222222"
                  />
                </svg>
              </button>
            </Tooltip>
          </>
        ) : null}
      </div>
      {iconLeft ? renderIcon() : renderInput({ padding: true })}
    </div>
  );
};

export default InputCustom;
