import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  clients: [],
  unitType: [],
  unit: [],
  operators: [],
  brandsList: [],
  profiles: [],
  cedisDestinationList: [],
  cedisOriginList: [],
  sondeos: [],
  sondeosList: [],
  etapasList: [],
  componentsList:[],
  carriersList: [],
  categoriesProduct:[],
  groupOptionList:[],
  groupOptionByIdList:[],
  questionsList:[]
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setListClient(state, action) {
      return { ...state, clients: action.payload };
    },
    setUnitTypeList(state, action) {
      return { ...state, unitType: action.payload };
    },
    setUnit(state, action) {
      return { ...state, unit: action.payload };
    },
    setOperator(state, action) {
      return { ...state, operators: action.payload };
    },
    setBrands(state, action) {
      return { ...state, brandsList: action.payload };
    },
    setProfiles(state, action) {
      return { ...state, profiles: action.payload };
    },
    setCedisDestination(state, action) {
      return { ...state, cedisDestinationList: action.payload };
    },
    setCedisOrigin(state, action) {
      return { ...state, cedisOriginList: action.payload };
    },
    setSondeos(state, action) {
      return { ...state, sondeosList: action.payload };
    },
    setEtapas(state, action) {
      return { ...state, etapasList: action.payload };
    },
    setCarriers(state, action) {
      return { ...state, carriersList: action.payload };
    },
    setCategoriesProduct(state, action) {
      return { ...state, categoriesProduct: action.payload };
    },
    setComponents(state, action) {
      return { ...state, componentsList: action.payload };
    },
    setGroupOption(state, action) {
      return { ...state, groupOptionList: action.payload };
    },
    setGroupOptionById(state, action) {
      return { ...state, groupOptionByIdList: action.payload };
    },
    setQuestions(state, action) {
      return { ...state, questionsList: action.payload };
    },
  },
});

export const {
  setListClient,
  setUnitTypeList,
  setUnit,
  setOperator,
  setBrands,
  setProfiles,
  setCedisDestination,
  setCedisOrigin,
  setSondeos,
  setEtapas,
  setCarriers,
  setCategoriesProduct,
  setComponents,
  setGroupOption,
  setGroupOptionById,
  setQuestions
} = catalogSlice.actions;
export default catalogSlice.reducer;
