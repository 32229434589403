import React from "react";
import InputCustom from "components/InputCustom";
import MapAddress from "components/MapAddress";
import { useTranslation } from "react-i18next";
import { getObjectAddressGeocoder } from "utils";

const AddressForm = ({
  setData = () => {},
  state = {},
  setExplicitField = () => {},
  fieldDescriptionName = "description",
  setCoords = () => {},
  defaultCoords = {},
}) => {
  const [position, setPosition] = React.useState({ lat: 0, lng: 0 });
  const [center, setCenter] = React.useState({
    lat: 18.1932342,
    lng: -101.9061163,
  });
  const [t] = useTranslation("global");
  const [tc] = useTranslation("catalog");
  const [zoom] = React.useState(6);

  React.useEffect(() => {
    if (defaultCoords && defaultCoords.lat && defaultCoords.lng) {
      setCenter(defaultCoords)
     setPosition(defaultCoords);
    }
  }, [defaultCoords]);

 

  React.useState(() => {
   if (position) setCoords(position);
  }, [position, setCoords]);

  

  const handleDragMarker = async ({ lat, lng }) => {
    let addressDTO = await getObjectAddressGeocoder({ lat, lng });
    
    setData((old) => ({
      ...old,
      ...addressDTO,
    }));
    setCoords({ lat, lng });
  };

  return (
    <div className="grid grid-cols-2 gap-1">
      <div className="m-1 min-h-[200px]">
        <MapAddress
          zoom={zoom}
          center={center}
          position={position}
          setPosition={setPosition}
          refreshDragMarker={handleDragMarker}
        />
      </div>
      <div className=" m-1 min-h-[200px] grid grid-cols-2 gap-2">
        <InputCustom
          type={"text"}
          labelText={t("addressForm.address")}
          errorMessage={
            state.$errors.codeAddress.length > 0 ? state.$errors.codeAddress[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              codeAddress: e.target.value,
            }));
          }}
          value={state.$data.codeAddress ? state.$data.codeAddress : ""}
          onBlur={() => setExplicitField("codeAddress", true)}
          keyId="codeAddress"
          size={"sm"}
          toolTip={tc("cedisOrigin.tooltip.general")}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.references")}
          errorMessage={
            state.$errors.description.length > 0 ? state.$errors.description[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              [fieldDescriptionName]: e.target.value,
            }));
          }}
          value={
            state.$data[fieldDescriptionName]
              ? state.$data[fieldDescriptionName]
              : ""
          }
          onBlur={() => setExplicitField(fieldDescriptionName, true)}
          keyId="description"
          size={"sm"}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.country")}
          disabled={true}
          errorMessage={
            state.$errors.country.length > 0 ? state.$errors.country[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              country: e.target.value,
            }));
          }}
          value={state.$data.country ? state.$data.country : ""}
          onBlur={() => setExplicitField("country", true)}
          keyId="country"
          size={"sm"}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.state")}
          errorMessage={
            state.$errors.state.length > 0 ? state.$errors.state[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              state: e.target.value,
            }));
          }}
          value={state.$data.state ? state.$data.state : ""}
          onBlur={() => setExplicitField("state", true)}
          keyId="state"
          size={"sm"}
          disabled={true}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.municipality")}
          errorMessage={
            state.$errors.city.length > 0 ? state.$errors.city[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              city: e.target.value,
            }));
          }}
          value={state.$data.city ? state.$data.city : ""}
          onBlur={() => setExplicitField("city", true)}
          keyId="city"
          size={"sm"}
          disabled={true}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.colony")}
          errorMessage={
            state.$errors.cologne.length > 0 ? state.$errors.cologne[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              cologne: e.target.value,
            }));
          }}
          value={state.$data.cologne ? state.$data.cologne : ""}
          onBlur={() => setExplicitField("cologne", true)}
          keyId="cologne"
          size={"sm"}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.street")}
          errorMessage={
            state.$errors.street.length > 0 ? state.$errors.street[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              street: e.target.value,
            }));
          }}
          value={state.$data.street ? state.$data.street : ""}
          onBlur={() => setExplicitField("street", true)}
          keyId="street"
          size={"sm"}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.outside_number")}
          errorMessage={
            state.$errors.exteriorNumber.length > 0 ? state.$errors.exteriorNumber[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              exteriorNumber: e.target.value,
            }));
          }}
          value={state.$data.exteriorNumber ? state.$data.exteriorNumber : ""}
          onBlur={() => setExplicitField("exteriorNumber", true)}
          keyId="exteriorNumber"
          size={"sm"}
        />
        <InputCustom
          type={"text"}
          labelText={t("addressForm.inside_number")}
          errorMessage={
            state.$errors.interiorNumber.length > 0 ? state.$errors.interiorNumber[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              interiorNumber: e.target.value,
            }));
          }}
          value={state.$data.interiorNumber ? state.$data.interiorNumber : ""}
          onBlur={() => setExplicitField("interiorNumber", true)}
          keyId="soldtoname"
          size={"sm"}
        />
        <InputCustom
          type={"number"}
          labelText={t("addressForm.postal_code")}
          errorMessage={
            state.$errors.postalCode.length > 0 ? state.$errors.postalCode[0].$message : null
          }
          onChange={(e) => {
            e.persist();
            setData((old) => ({
              ...old,
              postalCode: e.target.value,
            }));
          }}
          value={state.$data.postalCode ? state.$data.postalCode : ""}
          onBlur={() => setExplicitField("postalCode", true)}
          keyId="postalCode"
          size={"sm"}
        />
      </div>
    </div>
  );
};

export default AddressForm;
