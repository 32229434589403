import React from 'react';
import format from 'date-fns/format';

import Joi from 'joi';
import parseISO from 'date-fns/parseISO';
import useValidator from 'react-joi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { addOrUpdateGroupOptions, deleteGroupOptions, getGroupOptions, getGroupOptionsById } from 'actions/Catalogs';

import Grid from 'components/Grid';
import Modal from 'components/Modal';
import InputCustom from 'components/InputCustom';
import { notify } from 'utils';
import Swal from 'sweetalert2';
import { fDate } from 'components/DateCatalogs/filterDate';




const GroupOption = ({ view }) => {
    const [t] = useTranslation("catalog");
    const [tg] = useTranslation("global");
    const dispatch = useDispatch();
    const { groupOptionList } = useSelector((state) => state.catalog);
    const [answers, setAnwers] = React.useState([]);
    const [deleteAnswer, setDeteleAnswer] = React.useState([]);
    const navigation = useNavigate();
    const inputRef = React.useRef();
    const { permissions } = view;

    const handleRemoveItem = async (idx) => {
        // assigning the list to temp variable
        const temp = [...answers];

        let newNums = await temp.filter((num, index, arra) => {
            return index === idx
        });


        setDeteleAnswer([...deleteAnswer, newNums[0]]);

        // removing the element using splice
        temp.splice(idx, 1);

        // updating the list
        setAnwers(temp);
    }

    const handleRemoveDeleteItem = async (idx) => {
        // assigning the list to temp variable
        const temp = [...deleteAnswer];

        let newNums = await temp.filter((num, index) => {
            return index === idx
        });

        setAnwers([...answers, newNums[0]]);

        // removing the element using splice
        temp.splice(idx, 1);

        // updating the list
        setDeteleAnswer(temp);
    }

    const handleAnswer = () => {
        let valueAnswer = inputRef.current.value;
        if (!valueAnswer) {
            notify("warning", "No se pueden ingresar respuestas vacias", 3500);
            return;
        }

        setAnwers([...answers, { description: valueAnswer }])

        inputRef.current.value = '';
    }

    function filtrarPorID(obj) {
        if ('id' in obj && typeof (obj.id) === 'number' && !isNaN(obj.id)) {
            return obj;
        }
    }

    function filtrarPorIDNaN(obj) {
        if (isNaN(obj.id)) {
            return obj;
        }
    }

    const [openModal, setOpenModal] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [id, setId] = React.useState();
    const [isLoad, setIsLoad] = React.useState(false);



    const { state, setData, setExplicitField, validate } = useValidator({
        initialData: {
            description: null,

        },
        schema: Joi.object({
            description: Joi.string()
                .min(2)
                .max(200)
                .required()
                .messages({
                    'string.empty': t("error.empty"),
                    'string.base': t("error.type", { type: 'string' }),
                    'string.min': t("error.min"),
                    'string.max': t("error.max", { quantity: '200' }),
                    'any.required': t("error.required")
                }),
        }),
        explicitCheck: {
            description: false,
        },
        validationOptions: {
            abortEarly: true,
        },
    });

    const buttonRef = React.useRef();

    React.useEffect(() => {
        dispatch(getGroupOptions());
    }, [dispatch]);


    const handleOnSubmit = async () => {
        const { description } = state.$data;
        let answersR;
        let deleteAnswers;
        const deletAnswer = [...deleteAnswer];
        const answersNaN = [...answers];
        let answerId = deletAnswer.filter(filtrarPorID).map(function (item) {
            return item.id;
        });
        let answersNan = answersNaN.filter(filtrarPorIDNaN).map(function (item) {
            return item.description;
        });

        answerId.length > 0 ? deleteAnswers = answerId : deleteAnswers = [];
        answersNan.length > 0 ? answersR = answersNan : answersR = [];

        if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
            setShowError(true);
            validate();
            notify("error", t("error.general_toast"), 1200);
            return;
        }

        setIsLoad(true);
        if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;

        let isSuccess = await dispatch(
            addOrUpdateGroupOptions({
                description,
                answers: answersR,
                deleteAnswers,
                id,
            })
        );
        if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
        setIsLoad(false);
        setShowError(false);
        if (isSuccess) {
            setData((old) => ({
                ...old,
                description: null,
            }));
            setId(null);
            setAnwers([]);
            setOpenModal(false);
            setAnwers([]);
            setDeteleAnswer([]);
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: tg("button.title"),
            text: tg("button.text"),
            icon: "error",
            confirmButtonText: tg("button.confirm"),
            cancelButtonText: tg("button.cancel"),
            cancelButtonColor: "#000000",
            confirmButtonColor: "#FF3447",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed && id) {
                dispatch(deleteGroupOptions(id));
            }
        });
    };

    return (
        <>
            <Grid

                title={t("groupOption.title")}
                columns={[
                    {
                        Header: "Id",
                        accessor: "id",
                        filter: "text",
                        width: '20%'
                    },
                    {
                        Header: t("groupOption.description"),
                        accessor: "description",
                        filter: "text",
                        width: '40%',
                    },

                    {
                        Header: t("groupOption.updated_at"),
                        accessor: "updatedAt",
                        filter: fDate,
                        Cell: ({ value }) => {
                            if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
                            return value;
                        },
                        width: '30%',
                    },
                ]}
                data={
                    groupOptionList || []
                }
                handleAdd={() => {
                    if (permissions.write) {
                        setOpenModal(true);
                    }
                }}
                handleEdit={(row) => {
                    const { description, id } = row;
                    setId(id);
                    setData((old) => ({
                        ...old,
                        description,
                    }));
                    setOpenModal(true);

                    dispatch(getGroupOptionsById(id)).then((response) => {
                        setAnwers(response.options);
                    })

                }}
                handleDelete={({ id }) => handleDelete(id)}
                write={permissions.write}
                AdditionalOptionsRows={({ row }) => {
                    const { original } = row;
                    return true ? (
                        <button
                            className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-gray-200 hover:text-gray-900"
                            onClick={() => navigation(`../group-option-id`, {
                                state: {
                                    "id": original.id
                                }
                            })}
                        >
                            Ver options
                        </button>
                    ) : null;
                }}
            />
            {openModal ? (
                <Modal
                    openModal={openModal}
                    closeModal={() => {
                        setData((old) => ({
                            ...old,
                            description: null,
                        }));
                        setAnwers([]);
                        setDeteleAnswer([]);
                        setShowError(false);
                        setOpenModal(false);
                        setId(null);
                    }}
                    titleModal={t("groupOption.title")}
                    onSubmit={handleOnSubmit}
                    disableAdd={state.$invalid}
                    iconEdit={id > 0 ? true : false}
                    buttonRef={buttonRef}
                    processFetch={isLoad}
                >
                    <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
                        {t("groupOption.info_modal")}
                    </h4>

                    <div className="flex flex-wrap ">
                        <div className="w-full">
                            <InputCustom
                                type={"text"}
                                labelText={t("groupOption.description")}
                                onBlur={() => setExplicitField("description", true)}
                                errorMessage={
                                    showError && state.$errors.description.length > 0 ? state.$errors.description[0].$message : null
                                }
                                onChange={(e) => {
                                    e.persist();
                                    setData((old) => ({
                                        ...old,
                                        description: e.target.value,
                                    }));
                                }}
                                value={state.$data.description ? state.$data.description : ""}
                            />
                        </div>
                        <div className="w-full flex items-center justify-between">
                            <div className="flex flex-col w-1/2 mt-2 max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow-lg shadow-gray-500/50 h-[200px]">
                                <div className='flex'>
                                    <input
                                        type="text"
                                        ref={inputRef}
                                        className="border w-full py-1 px-1"
                                    />
                                    <div onClick={handleAnswer} className="cursor-pointer bg-[#DEDEDE] ">
                                        <svg width="30" height="30" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M72.75 30C76.4779 30 79.5 33.0221 79.5 36.75L79.5 113.25C79.5 116.978 76.4779 120 72.75 120C69.0221 120 66 116.978 66 113.25L66 36.75C66 33.0221 69.0221 30 72.75 30Z" fill="black" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M120 72.75C120 76.4779 116.978 79.5 113.25 79.5L36.75 79.5C33.0221 79.5 30 76.4779 30 72.75C30 69.0221 33.0221 66 36.75 66L113.25 66C116.978 66 120 69.0221 120 72.75Z" fill="black" />
                                        </svg>
                                    </div>
                                </div>
                                <div className='w-full mt-2 no-scrollbar overflow-x-hidden overflow-y-auto'>
                                    {answers?.map((item, idx) => (
                                        <div key={idx} className="h-auto relative flex w-full justify-between px-4 border-b">
                                            <div className='h-auto flex flex-wrap text-wrap' >
                                                {item.description}
                                            </div>
                                            <div className="cursor-pointer px-2" onClick={() => handleRemoveItem(idx)}>
                                                <svg width="25" height="25" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M64.4468 41.1429H79.5922C78.3979 38.131 75.4566 36 72.0193 36C68.5815 36 65.6413 38.1303 64.4468 41.1429ZM85.8428 41.1429H99.8764C101.533 41.1429 102.876 42.486 102.876 44.1429V111C102.876 118.811 96.5444 125.143 88.7335 125.143H55.305C47.4941 125.143 41.1621 118.811 41.1621 111V44.1429C41.1621 42.486 42.5053 41.1429 44.1621 41.1429H58.1952C59.571 34.773 65.2378 30 72.0193 30C78.8011 30 84.4661 34.7719 85.8428 41.1429ZM47.1621 47.1429V111C47.1621 115.497 50.8078 119.143 55.305 119.143H88.7335C93.2307 119.143 96.8764 115.497 96.8764 111V47.1429H47.1621Z" fill="black" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30 44.1438C30 42.4869 31.3431 41.1438 33 41.1438H111C112.657 41.1438 114 42.4869 114 44.1438C114 45.8007 112.657 47.1438 111 47.1438H33C31.3431 47.1438 30 45.8007 30 44.1438Z" fill="black" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.8477 57.856C62.5045 57.856 63.8477 59.1991 63.8477 60.856V105.427C63.8477 107.084 62.5045 108.427 60.8477 108.427C59.1908 108.427 57.8477 107.084 57.8477 105.427V60.856C57.8477 59.1991 59.1908 57.856 60.8477 57.856Z" fill="black" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M83.1562 57.856C84.8131 57.856 86.1562 59.1991 86.1562 60.856V105.427C86.1562 107.084 84.8131 108.427 83.1562 108.427C81.4994 108.427 80.1562 107.084 80.1562 105.427V60.856C80.1562 59.1991 81.4994 57.856 83.1562 57.856Z" fill="black" />
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {deleteAnswer?.length > 0 && <div className='w-1/4 m-2 p-1 bg-red-200 border border-red-200 rounded-lg shadow-lg shadow-red-200/50 h-[200px] overflow-x-auto no-scrollbar'>
                                <div className='w-full mt-2 ml-2'>
                                    {deleteAnswer?.map((item, idx) => (
                                        <div key={idx} className="flex justify-between px-4">
                                            <div className='h-auto flex flex-wrap text-wrap w-3/4' >
                                                {item.description}
                                            </div>
                                            <div className="cursor-pointer px-2" onClick={() => handleRemoveDeleteItem(idx)}>
                                                <svg width="20" height="20" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.2066 129.733L2.25303 80.4733C-0.750997 77.4505 -0.750997 72.5495 2.25303 69.5267L51.2066 20.2671C54.2106 17.2443 59.0811 17.2443 62.0851 20.2671C65.0892 23.2899 65.0892 28.1909 62.0851 31.2137L26.2632 67.2596L142.308 67.2596C146.556 67.2596 150 70.7251 150 75C150 79.2749 146.556 82.7404 142.308 82.7404L26.2632 82.7404L62.0851 118.786C65.0892 121.809 65.0892 126.71 62.0851 129.733C59.0811 132.756 54.2106 132.756 51.2066 129.733Z" fill="#222222" />
                                                </svg>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </div>
                    </div>
                </Modal>
            ) : null}
        </>
    );
}

export default GroupOption