import api from "api";
import { ResponseTransform } from "utils";

export const addOrUpdate = async (dto) => {
  let responseApi;
  if (dto.id) {
    responseApi = await api
      .put(`api/project/${dto.idProject}/geofences/${dto.id}`, dto)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post(`api/project/geofences`, dto).then(ResponseTransform);
  }
  return responseApi;
};

export const getGeofences = async (idProject) => {
  let response = await api
    .get(`api/project/${idProject}/geofences`)
    .then(ResponseTransform);
  return response;
};

export const deleteGeofences = async (idProject, id) => {
  let response = await api
    .remove(`api/project/${idProject}/geofences/${id}`)
    .then(ResponseTransform);
  return response;
};
