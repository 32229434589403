import * as React from "react";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Joi from "joi";
import Swal from "sweetalert2";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import InputCustom from "components/InputCustom";
import { getBrands, addOrUpdateBrands, deleteBrands } from "actions/Catalogs";
import { notify } from "utils";
import { format, parseISO } from "date-fns";
import { filterDate } from "components/DateCatalogs/filterDate";


const Brands = ({ view }) => {
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");
  const dispatch = useDispatch();
  const { brandsList } = useSelector((state) => state.catalog);
  const [openModal, setOpenModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);


  const { permissions } = view;

  const { state, setData, setExplicitField, validate } = useValidator({
    initialData: {
      name: null,
      shortName: null,
    },
    schema: Joi.object({
      name: Joi.string()
        .min(2)
        .max(200)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '200' }),
          'any.required': t("error.required")
        }),
      shortName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      name: false,
      shortName: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  const buttonRef = React.useRef();

  React.useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);


  const handleOnSubmit = async () => {
    const { shortName, name } = state.$data;
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      setShowError(true);
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;

    let isSuccess = await dispatch(
      addOrUpdateBrands({
        name,
        shortName,
        id,
      })
    );
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);
    setShowError(false);
    if (isSuccess) {
      setData((old) => ({
        ...old,
        shortName: null,
        name: null,
      }));
      setId(null);

      setOpenModal(false);
    }


  };

  const handleDelete = async (idOperator) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && idOperator) {
        dispatch(deleteBrands(idOperator));
      }
    });
  };

  return (
    <>
      <Grid

        title={t("brands.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("brands.short_name"),
            accessor: "shortName",
            filter: "text",
            width: '30%',
          },
          {
            Header: t("brands.name"),
            accessor: "name",
            filter: "text",
            width: '30%',
          },
          {
            Header: t("brands.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '20%',
          },
        ]}
        data={
          brandsList || []
        }
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const { name, shortName, id } = row;
          setId(id);
          setData((old) => ({
            ...old,
            name,
            shortName,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setData((old) => ({
              ...old,
              name: null,
              shortName: null,
            }));
            setShowError(false);
            setOpenModal(false);
            setId(null);
          }}
          titleModal={t("brands.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("brands.info_modal")}
          </h4>
          <div className="flex flex-wrap ">
            <InputCustom
              type={"text"}
              labelText={t("brands.short_name")}
              onBlur={() => setExplicitField("shortName", true)}
              errorMessage={
                showError && state.$errors.shortName.length > 0 ? state.$errors.shortName[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  shortName: e.target.value,
                }));
              }}
              value={state.$data.shortName ? state.$data.shortName : ""}
            />
            <InputCustom
              type={"text"}
              labelText={t("brands.name")}
              errorMessage={
                showError && state.$errors.name.length > 0 ? state.$errors.name[0].$message : null
              }
              onBlur={() => setExplicitField("name", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name ? state.$data.name : ""}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Brands;
