import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Joi from "joi";
import Swal from "sweetalert2";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import InputCustom from "components/InputCustom";
import { useTranslation } from "react-i18next";
//actions
import {
  addOrUpdateCarrier,
  getCarrier,
  deleteCarrier,
} from "actions/Catalogs";
import { notify } from "utils";
import { format, parseISO } from "date-fns";
import { filterDate } from "components/DateCatalogs/filterDate";


const Carrier = ({ view }) => {
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");
  const dispatch = useDispatch();
  const { carriersList } = useSelector((state) => state.catalog);
  const { currentProject } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);
  const { permissions } = view;

  const { state, setData, setExplicitField, validate } = useValidator({
    initialData: {
      name: null,
      contactName: null,
      contactEmail: null,
      contactPhone: null,
    },
    schema: Joi.object({
      name: Joi.string()
        .min(2)
        .max(100)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '100' }),
          'any.required': t("error.required")
        }),
      contactName: Joi.string()
        .min(2)
        .max(100)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '100' }),
          'any.required': t("error.required")
        }),
      contactEmail: Joi.string()
        .email({ tlds: { allow: false } })
        .min(2)
        .max(60)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.email': "El formato de correo es incorrecto",
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '60' }),
          'any.required': t("error.required")
        }),
      contactPhone: Joi.string()
        .min(2)
        .max(20)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '20' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      name: false,
      contactName: false,
      contactEmail: false,
      contactPhone: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  const buttonRef = React.useRef();

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispatch(getCarrier(currentProject.id));
    }
  }, [dispatch, currentProject]);


  const handleOnSubmit = async () => {
    const { name, contactName, contactEmail, contactPhone } = state.$data;

    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      setShowError(true);
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    let isSuccess = await dispatch(
      addOrUpdateCarrier({
        name,
        contactName,
        contactEmail,
        contactPhone,
        id,
        idProject: currentProject.id,
      })
    );
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);
    setShowError(false);
    if (isSuccess) {
      setData((old) => ({
        ...old,
        name: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
      }));
      setId(null);
      setOpenModal(false);
    }
  };

  const handleDelete = async (idOperator) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && idOperator) {
        dispatch(deleteCarrier(idOperator, currentProject.id));
      }
    });
  };


  return (
    <>
      <Grid
        title={t("carriers.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width:'5%'
          },
          {
            Header: t("carriers.name"),
            accessor: "name",
            filter: "text",
            width:'18%'
          },
          {
            Header: t("carriers.contact_name"),
            accessor: "contactName",
            filter: "text",
            width:'18%'
          },
          {
            Header: t("carriers.email_contact_name"),
            accessor: "contactEmail",
            filter: "text",
            width:'18%'
          },
          {
            Header: t("carriers.phone_contact_name"),
            accessor: "contactPhone",
            filter: "text",
            width:'18%'
          },
          {
            Header: t("carriers.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width:'18%'
          },
        ]}
        data={carriersList || []}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const { id, name, contactName, contactEmail, contactPhone } = row;
          setId(id);
          setData((old) => ({
            ...old,
            name,
            contactName,
            contactEmail,
            contactPhone,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setData((old) => ({
              ...old,
              name: null,
              contactName: null,
              contactEmail: null,
              contactPhone: null,
            }));
            setShowError(false);
            setOpenModal(false);
            setId(null);
          }}
          titleModal={t("carriers.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("carriers.info_modal")}
          </h4>
          <div className="grid grid-cols-2">

            <InputCustom
              type={"text"}
              labelText={t("carriers.name")}
              errorMessage={
                showError && state.$errors.name.length > 0 ? state.$errors.name[0].$message : null
              }
              onBlur={() => setExplicitField("name", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name}
              keyId="carrierName"
              
            />
            <InputCustom
              type={"text"}
              labelText={t("carriers.contact_name")}
              errorMessage={
                showError && state.$errors.contactName.length > 0 ? state.$errors.contactName[0].$message: null
              }
              onBlur={() => setExplicitField("contactName", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  contactName: e.target.value,
                }));
              }}
              value={state.$data.contactName}
              keyId="carrierContactName"
            />
            <InputCustom
              type={"text"}
              labelText={t("carriers.email_contact_name")}
              errorMessage={
                showError && state.$errors.contactEmail.length > 0 ? state.$errors.contactEmail[0].$message : null
              }
              onBlur={() => setExplicitField("contactEmail", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  contactEmail: e.target.value,
                }));
              }}
              value={state.$data.contactEmail}
              keyId="carrierContactEmail"
              disabled={id ? true : false}
            />
            <InputCustom
              type={"text"}
              labelText={t("carriers.phone_contact_name")}
              errorMessage={
                showError && state.$errors.contactPhone.length > 0 ? state.$errors.contactPhone[0].$message : null
              }
              onBlur={() => setExplicitField("contactPhone", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  contactPhone: e.target.value,
                }));
              }}
              value={state.$data.contactPhone}
              keyId="carrierContactPhone"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Carrier;
