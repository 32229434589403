import styled from "styled-components";

export const ButtonComponent = styled.button`
  background-color: #000000;
  height: 50px;
  width: 260px;
  border-radius: 30px;
  color: #ffffff;
  font-family: Gravity;
`;
