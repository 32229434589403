import React from "react";
import InputLogin from "components/InputLogin";
import ButtonLogin from "components/ButtonLogin";
import { useDispatch } from "react-redux";
import { RecoverAction } from "actions/User";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notify } from "utils";
import { ReactComponent as Logo } from "../../assets/logos/logotipo_oficial.svg";

const RecoverPassword = () => {
  const [t] = useTranslation("global");
  const [recoverT] = useTranslation("recover");
  const dispatch = useDispatch();
  const [frmValues, setFrmValues] = React.useState({});
  const [messageError, setMessageError] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const { PUBLIC_URL } = process.env;
  const language = localStorage.getItem("language") || "es";
  const isSpanish = language === "es";
  const navigate = useNavigate();

  const handleRecover = async () => {
    const { user } = frmValues;
    let error_100 = 'Verificar que los campos no se encuentren vacios'
    if (!isSpanish) {
      error_100 = 'Verify that the fields are not empty'
    }
    if (!user) {
      setMessageError(error_100);
      return;
    }
    setIsLoad(true);
    let isSuccess = await RecoverAction({ user: user });
    if (isSuccess) {
      //NAVEGACIÓN A DASHBOARD
      const text = recoverT("recover.message");
      notify("success", `${text} ${user}`, 3500);
      dispatch({ type: "LOGOUT" });
      navigate("/");
    }
    setIsLoad(false);
  };
  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center  bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${PUBLIC_URL}/assets/bg_1.jpg)` }}
    >
      <div className="flex flex-col bg-white shadow-md sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md min-h-[50%]">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <div className="py-10" />
        <h3 className="font-bold font-[Gravity] text-[#000000]">
          {recoverT("recover.recover")}
        </h3>
        <span className="font-[Gravity] text-[#000000] py-2">
          {recoverT("recover.instruction")}
        </span>
        <InputLogin
          label={t("recover.input")}
          value={""}
          onChange={(event) =>
            setFrmValues({ ...frmValues, user: event.target.value })
          }
          className="py-2	"
        />
        <div className="py-4">
          <label
            htmlFor=""
            className={`text-[#EA0000] ${!messageError && "hidden"}`}
          >
            {messageError}
          </label>
        </div>

        <div className="flex flex-row justify-center items-center gap-x-0.5 py-8 px-8">
          <ButtonLogin
            text={t(isLoad ? "login.load" : "recover.btn")}
            onClick={handleRecover}
            disabled={isLoad}
          />
        </div>
      </div>
    </div>
  );
};
export default RecoverPassword;
