import React from "react";
import Joi from "joi";
import { useValidator } from "react-joi";
import { Buffer } from "buffer";

import Grid from "components/Grid";
import { assignTrip, getPlanningForAssiggment } from "actions/Planeacion";
import Modal from "components/Modal";
import InputCustom from "components/InputCustom";
import {
  getCarrier,
  getOperatorListByCarrier,
  getUnitList,
} from "actions/Catalogs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { addDays, format, parseISO } from "date-fns";
import { notify } from "utils";
import { useLocation } from "react-router-dom";

const PlanningAssiggnment = ({ view }) => {
  const dispacth = useDispatch();
  const location = useLocation();
  const { PUBLIC_URL } = process.env;
  const [t] = useTranslation("planning");
  const [listPlanning, setListPlanning] = React.useState([]);
  const [selectData, setSelectData] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [operatorsList, setOperatorsList] = React.useState();
  const { unit, carriersList } = useSelector((state) => state.catalog);
  const { currentProject } = useSelector((state) => state.auth);
  const today = new Date();
  const { permissions } = view;

  const [isLoad, setIdLoad] = React.useState(false);
  const buttonRef = React.useRef();

  const [frmValuesFilter, setFrmValuesFilter] = React.useState({
    startDate: format(today, "yyyy-MM-dd"),
    endDate: format(addDays(today, 1), "yyyy-MM-dd"),
  });
  const { state, setData, setExplicitField } = useValidator({
    initialData: {
      idTruck: null,
      idOperator: null,
      comments: null,
    },
    schema: Joi.object({
      idTruck: Joi.number().required(),
      idOperator: Joi.number().required(),
      comments: Joi.string().required(),
    }),
    explicitCheck: {
      idTruck: false,
      idOperator: false,
      comments: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispacth(getUnitList());
      dispacth(getCarrier(currentProject.id));
    }
  }, [dispacth, currentProject]);

  React.useEffect(() => {
    if (
      location &&
      location?.search &&
      carriersList &&
      carriersList.length > 0
    ) {
      try {
        const { search } = location;
        if (!search) {
          setFrmValuesFilter((old) => {
            return { ...old, idCarrier: carriersList[0]?.id };
          });
          return;
        }
        const params = new URLSearchParams(search);
        const query = params.get("query");
        if (!query) {
          setFrmValuesFilter((old) => {
            return { ...old, idCarrier: carriersList[0]?.id };
          });
          return;
        }
        let objetoQuery = Buffer.from(query, "base64").toString("utf-8");
        objetoQuery = JSON.parse(objetoQuery);
        if (objetoQuery?.carrier) {
          setFrmValuesFilter((old) => {
            return { ...old, idCarrier: objetoQuery?.carrier };
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [location, carriersList]);

  React.useEffect(() => {
    if (selectData && selectData.carrierId) {
      getOperatorListByCarrier(selectData.carrierId).then((response) => {
        setOperatorsList(response);
      });
    }
  }, [selectData]);

  const handleSearch = () => {
    if (!frmValuesFilter.idCarrier) {
      notify("warning", t("assignment.carrier_filter"), 800);
      return null
    }

    if (!frmValuesFilter.startDate) {
      notify("warning", t("assignment.start_date_filter"), 800);
      return null
    }
    if (!frmValuesFilter.endDate) {
      notify("warning", t("assignment.end_date_filter"), 800);
      return null
    }

    if (frmValuesFilter.endDate && frmValuesFilter.startDate > frmValuesFilter.endDate) {
      notify("warning", t("assignment.alert_date"), 1500);
      return null;
    }

    getPlanningForAssiggment(frmValuesFilter).then((response) => {
      if (response) {
        setListPlanning(response);
        if (response.length === 0) {
          notify("warning", t("assignment.alert_information"), 1500);
          return null;
        }
        return;
      }
    });
  };

  const handleSave = () => {
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      notify("error", t("assignment.fields"), 800);
      return;
    }

    if (permissions.write) {
      if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
      setIdLoad(true);
      assignTrip(selectData.id, state.$data).then((res) => {
        if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
        setIdLoad(false);
        if (res) {
          handleSearch();
          setOpenModal(false);
          Swal.fire({
            title: t("handleSave.title"),
            text: t("handleSave.text"),
            confirmButtonColor: "#222222",
          });

          setData((old) => ({
            ...old,
            idTruck: null,
            idOperator: null,
            comments: null,
          }));
        }
      });
    }

  };
  return (
    <>
      <Grid
        title={t("assignment.title")}
        data={listPlanning || []}
        columns={[
          {
            Header: t("assignment.carrier"),
            accessor: "carrier.name",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("assignment.load_id"),
            accessor: "loadId",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("assignment.delivery"),
            accessor: "delivery",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("assignment.shpt"),
            accessor: "shPt",
            filter: "text",
            width: '10%'
          },
          {
            Header: "ShptTo",
            accessor: "shipTo",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("assignment.dv"),
            accessor: "dv",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("assignment.appointments_for_charge"),
            accessor: "pU_AppointmentDay",
            filter: "text",
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy");
              return value;
            },
            width: '10%'
          },
          {
            Header: t("assignment.appointments_for_delivery"),
            accessor: "d_AppointmentDay",
            filter: "text",
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy");
              return value;
            },
            width: '10%'
          },
          {
            Header: "Status",
            accessor: "tripDetail",
            filter: "text",
            Cell: ({ value }) => {
              return value === null ? (
                <div className="font-[Gravity] text-[14px]">{t("assignment.null")}</div>
              ) :
                value.trip['status'] === "Refused" ? (
                  <div className="font-[Gravity] text-[14px]">{t("assignment.refused")}</div>
                ) :
                  value.trip['status'] === "Pending" ?
                    <div className="font-[Gravity] text-[14px]">{t("assignment.pending")}</div>
                    : null;
            },
            width: '10%'
          },
          {
            Header: t("assignment.assign"),
            accessor: "null",
            disableFilters: true,
            Cell: ({ row }) => {
              return (
                <button
                  className="font-[Gravite] font-bold bg-[#222222] rounded-full h-[25px] w-[25px] flex items-center justify-center"
                  onClick={() => {
                    setSelectData(row.original);
                    setOpenModal(true);
                  }}
                >
                  <svg
                    width="20"
                    height="10"
                    viewBox="0 0 150 91"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M108.965 1.80551L148.195 41.0354C150.602 43.4427 150.602 47.3458 148.195 49.7532L108.965 88.9831C106.557 91.3904 102.654 91.3904 100.247 88.9831C97.8395 86.5757 97.8395 82.6726 100.247 80.2653L128.953 51.5587H0V39.2299H128.953L100.247 10.5233C97.8395 8.11592 97.8395 4.21285 100.247 1.80551C102.654 -0.601836 106.557 -0.601836 108.965 1.80551Z"
                      fill="white"
                    />
                  </svg>
                </button>
              );
            },
            width: '5%'
          },
        ]}
        hiddenDefaultOptions={true}
        controlsHeaderHidden={true}
        controlersHeaderComponents={() => (
          <div className="flex flex-row items-center">
            <InputCustom
              type={"select"}
              size={"m-sm"}
              options={
                carriersList
                  ? carriersList.map((item) => ({
                    text: item.name,
                    value: item.id,
                  }))
                  : null
              }
              onChange={(input) =>
                setFrmValuesFilter({
                  ...frmValuesFilter,
                  idCarrier: input.target.value,
                })
              }
              value={frmValuesFilter?.idCarrier}
            />
            <InputCustom
              type={"date"}
              size={"m-sm"}
              keyId={"startDate"}
              value={frmValuesFilter.startDate}
              onChange={(input) =>
                setFrmValuesFilter({
                  ...frmValuesFilter,
                  startDate: input.target.value,
                })
              }
            />
            <InputCustom
              type={"date"}
              size={"m-sm"}
              onChange={(input) =>
                setFrmValuesFilter({
                  ...frmValuesFilter,
                  endDate: input.target.value,
                })
              }
              value={frmValuesFilter?.endDate}
              keyId={"endDate"}
            />
            <button
              className="bg-[#d3d3d3] rounded-full h-[30px] w-[30px]  hover:bg-gray-200 p-2 flex items-center justify-center"
              onClick={handleSearch}
              title={t("assignment.search_trips")}
            >
              <img
                src={`${PUBLIC_URL}/icons/btns/search.png`}
                className={"w-[100%] h-[25px] object-cover"}
                alt="#"
              />
            </button>
          </div>
        )}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={true}
          titleModal={t("assignment.title_modal")}
          closeModal={() => {
            setSelectData({});
            setData((old) => ({
              ...old,
              idTruck: null,
              idOperator: null,
              comments: null,
            }));
            setOpenModal(false);
          }}
          onSubmit={() => handleSave()}
          disableAdd={state.$invalid}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <div className="grid grid-cols-2 gap-1">
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Transportista
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.carrier.name}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                LoadId
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.loadId}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Delivery
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.delivery}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">Dv</span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.dv}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">ShPt</span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.shPt}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Ship-to
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.shipTo}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Cita de Carga
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.pU_AppointmentDay
                  ? format(parseISO(selectData.pU_AppointmentDay), "dd/MM/yyyy")
                  : ""}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Hora de Carga
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.pU_AppointmentTime}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Cita de Entrega
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.d_AppointmentDay
                  ? format(parseISO(selectData.d_AppointmentDay), "dd/MM/yyyy")
                  : ""}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Hora de Entrega
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {selectData.d_AppointmentTime}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-[Gravity] font-bold text-[14px]">
                Status
              </span>
              <span className="font-[Gravity] font-500 text-[14px]">
                {
                  selectData.tripDetail === null ? (
                    t("assignment.null")
                  ) :
                    selectData.tripDetail.trip['status'] === "Refused" ? (
                      t("assignment.refused")
                    ) :
                      selectData.tripDetail.trip['status'] === "Pending" ?
                        t("assignment.pending")
                        : null}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap gap-1">
            <InputCustom
              labelText={t("assignment.operator")}
              type="select"
              size="lg-min"
              onBlur={() => setExplicitField("idOperator", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idOperator: e.target.value,
                }));
              }}
              value={state.$data.idOperator ? state.$data.idOperator : ""}
              options={
                operatorsList && operatorsList.length > 0
                  ? operatorsList.map((item) => ({
                    value: item.id,
                    text: `${item.user?.name} ${item.user?.secondLastName} (${item.user?.email})`,
                  }))
                  : []
              }
            />
            <InputCustom
              labelText={t("assignment.unit")}
              type="select"
              onBlur={() => setExplicitField("idTruck", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idTruck: e.target.value,
                }));
              }}
              value={state.$data.idTruck ? state.$data.idTruck : ""}
              size="lg-min"
              options={
                unit
                  ? unit.map((item) => ({
                    value: item.id,
                    text: item.name,
                  }))
                  : []
              }
            />
            <div className={`flex flex-col  w-[90%]`}>
              <label className="text-black font-[Gravity] text-[16px] font-[350] ">
                {t("assignment.observation")}
              </label>
              <textarea
                className="w-full rounded-[10px] bg-[#DEDEDE] outline-none py-1 px-1"
                rows={3}
                value={state.$data.comments ? state.$data.comments : ""}
                onBlur={() => setExplicitField("comments", true)}
                onChange={(e) => {
                  e.persist();
                  setData((old) => ({
                    ...old,
                    comments: e.target.value,
                  }));
                }}
              ></textarea>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default PlanningAssiggnment;
