import * as React from "react";
import {
  getMonitorinTrips,
  getOperatorWithTrip,
  getTripWithRoute,
} from "actions/Monitoring";
import * as signalR from "@microsoft/signalr";
import { useSelector } from "react-redux";
import Grid from "components/Grid";
import InputCustom from "components/InputCustom";
import { addDays, format, isValid, parseISO } from "date-fns";
import { SelectColumnFilter } from "components/SelectFilter/SelectFilter";
import ModalDetailTrip from "components/ModalDetailTrip";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { notify } from "utils";

const Monitoring = ({ view }) => {
  const today = new Date();
  const [trips, setTrips] = React.useState();
  const { currentProject } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = React.useState({});
  const [frmValues, setFrmValues] = React.useState({
    startDate: format(addDays(today, -1), "yyyy-MM-dd"),
    endDate: format(addDays(today, 1), "yyyy-MM-dd"),
  });
  const [operatorList, setOperatorList] = React.useState([]);
  const { PUBLIC_URL, REACT_APP_URL_WEB_SOCKET } = process.env;
  const [connection, setConnection] = React.useState();
  const [t] = useTranslation("monitoring");
  const { permissions } = view;

  React.useEffect(() => {
    if (connection != null && currentProject && currentProject?.name) {
      connection
        .invoke("JoinGroupAsync", currentProject.name)
        .then(() => {
          console.log("Conectado a", currentProject.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [connection, currentProject]);

  const closeModal = () => {
    setOpenModal(!openModal);
  };

  React.useEffect(() => {
    let connectionConfig = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_URL_WEB_SOCKET, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    connectionConfig
      .start()
      .then(() => {
        setConnection(connectionConfig);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      if (connectionConfig) {
        connectionConfig.stop();
      }
    };
  }, [REACT_APP_URL_WEB_SOCKET]);

  React.useEffect(() => {
    if (currentProject?.id) {
      getOperatorWithTrip(currentProject?.id).then((response) => {
        setOperatorList(response);
      });
    }
  }, [currentProject]);

  React.useEffect(() => {
    if (connection) {
      connection.on("notifyEventTrip", (messageData) => {
        try {
          const {
            data: { trip, status },
            dateTimeEvent,
          } = messageData;

          toast.success(
            `El viaje con id ${
              trip.id
            } cambio de estatus a ${status} \n ${format(
              parseISO(dateTimeEvent),
              "yyyy-MM-dd hh:mm"
            )}`,
            {
              duration: 1000,
              position: "top-right",
            }
          );
        } catch (error) {}
      });
    }
  }, [connection]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      getMonitorinTrips(
        currentProject.id,
        frmValues.startDate,
        frmValues.endDate,
        frmValues.idOperator
      ).then((response) => {
        setTrips(response.tracks);
      });
    }, 3000);
    return () => clearTimeout(timer);
  }, [currentProject, frmValues]);

  const handleSearch = () => {

    if(frmValues.endDate && frmValues.startDate>frmValues.endDate){
      notify("warning", t("monitoringTrips.alert_date"), 1500);
      return null;
    }
    getMonitorinTrips(
      currentProject.id,
      frmValues.startDate,
      frmValues.endDate,
      frmValues.idOperator
    ).then((response) => {
      setTrips(response.tracks);
      if(response.tracks.length===0){
        notify("warning", t("monitoringTrips.alert_information"), 1500);
        return null;
      }
    });
  };

  return (
    <div class="mt-8">
      <Grid
        title={t("monitoringTrips.title")}
        columns={[
          {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: ({ value }) => {
              let className =
                value === "Pending"
                  ? "bg-red-500"
                  : value === "InProcess"
                  ? "bg-green-500"
                  : value === "Accepted"
                  ? "bg-gray-500"
                  : "bg-blue-500";
              return (
                <div
                  className={`w-full  flex justify-center ${className} rounded`}
                >
                  <span className="text-white font-bold">{
                  value==='Pending'?t("monitoringTrips.pending"):
                  value==='Refused'?t("monitoringTrips.refused"):
                  value==='Complete'?t("monitoringTrips.complete"):
                  value==='InProcess'?t("monitoringTrips.in_process"):
                  value==='Accepted'?t("monitoringTrips.accept"):
                  null}</span>
                </div>
              );
            },
          },
          {
            Header: "ID",
            accessor: "detailTrip[0].id",
            filter: "text",
          },
          {
            Header: t("monitoringTrips.operator"),
            accessor: "operator.user.name",
            filter: "text",
          },
          {
            Header: t("monitoringTrips.unit_type"),
            accessor: "truck.name",
            filter: "text",
          },
          {
            Header: t("monitoringTrips.unit_number"),
            accessor: "detailTrip[0].loadId",
            filter: "text",
          },
          {
            Header: t("monitoringTrips.origin"),
            accessor: "detailTrip[0].origin.shPt",
            filter: "text",
          },
          {
            Header: t("monitoringTrips.destination"),
            accessor: "detailTrip[0].destination.shPt",
            filter: "text",
          },
          {
            Header: t("monitoringTrips.start_date"),
            accessor: "detailTrip[0].originPointAppointmentDate",
            filter: "text",
            Cell: ({ value }) => {
              if (!value) return "";
              if (!isValid(new Date(value))) {
                return "";
              }
              return format(parseISO(value), "dd/MM/yyyy HH:mm");
            },
          },
          {
            Header: t("monitoringTrips.start_date_real"),
            accessor: "startTrip",
            filter: "text",
            Cell: ({ value }) => {
              if (!value) return "";
              if (!isValid(new Date(value))) {
                return "";
              }
              return format(parseISO(value), "dd/MM/yyyy HH:mm");
            },
          },
          
          {
            Header: t("monitoringTrips.end_date"),
            accessor: "detailTrip[0].destinationPointAppointmentDate",
            filter: "text",
            Cell: ({ value }) => {
              if (!value) return "";
              if (!isValid(new Date(value))) {
                return "";
              }
              return format(parseISO(value), "dd/MM/yyyy HH:mm");
            },
          },
          {
            Header: t("monitoringTrips.end_date_real"),
            accessor: "endTrip",
            filter: "text",
            Cell: ({ value }) => {
              if (!value) return "";
              if (!isValid(new Date(value))) {
                return "";
              }
              return format(parseISO(value), "dd/MM/yyyy HH:mm");
            },
          },
        ]}
        write={permissions.write}
        data={trips || []}
        hiddenDefaultOptions
        hiddenHandleAdd
        AdditionalOptionsRows={({ row, setOpenAction }) => {
          return (
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-gray-200 hover:text-gray-900"
              onClick={async () => {
                try {
                  let data = await getTripWithRoute(row.original.id);
                  setData(data);
                  setData({...data,
                    startTrip:row.original.startTrip,
                    endTrip:row.original.endTrip
                  });
                  setOpenAction(null);
                  setOpenModal(true);
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              {t("monitoringTrips.watch_detail")}
            </button>
          );
        }}
        controlersHeaderComponents={() => (
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col items-start p-3">
              <p className="text-white text-[12px] font-[Gravity] ml-1">
                {t("monitoring.operator")}:
              </p>
              <InputCustom
                type={"select"}
                onChange={({ target: { value } }) => {
                  setFrmValues({ ...frmValues, idOperator: value });
                }}
                options={
                  operatorList
                    ? operatorList.map((item) => ({
                        value: item.id,
                        text: `${item.user["name"]} ${item.user["lastName"]}`,
                      }))
                    : []
                }
              />
            </div>
            <div className="flex flex-col items-start p-3">
              <p className="text-white text-[12px] font-[Gravity] ml-1">
                {t("monitoring.start_date")}:
              </p>
              <InputCustom
                type={"date"}
                size={"m-sm"}
                onChange={({ target: { value } }) => {
                  setFrmValues({ ...frmValues, startDate: value });
                }}
                value={frmValues.startDate}
              />
            </div>
            <div className="flex flex-col items-start p-3">
              <p className="text-white text-[12px] font-[Gravity] ml-1">
                {t("monitoring.end_date")}:
              </p>
              <InputCustom
                type={"date"}
                size={"m-sm"}
                onChange={({ target: { value } }) => {
                  setFrmValues({ ...frmValues, endDate: value });
                }}
                value={frmValues.endDate}
              />
            </div>
            <button
              className="bg-[#d3d3d3] rounded-full h-[30px] w-[30px]  hover:bg-gray-200 p-2 flex items-center justify-center"
              onClick={handleSearch}
              title="Buscar viajes"
            >
              <img
                src={`${PUBLIC_URL}/icons/btns/search.png`}
                className={"w-[100%] h-[25px] object-cover"}
                alt="#"
              />
            </button>
          </div>
        )}
        exportData={true}
      />
      <ModalDetailTrip
        data={data}
        openModal={openModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default Monitoring;
