import React, { useState } from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Joi from "joi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useValidator } from "react-joi";
import { useDispatch } from "react-redux";
import {
  addOrUpdateProfile,
  deleteProfile,
  getProfilesList,
} from "actions/Catalogs";
import { getPermissionsList } from "actions/Config";
import InputCustom from "components/InputCustom";
import { useSelector } from "react-redux";
import { notify } from "utils";
import { parseISO, format } from "date-fns/esm";
import { filterDate } from "components/DateCatalogs/filterDate";

const Profile = ({ view }) => {
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.catalog);
  const [permissionLista, setPermissionsList] = React.useState([]);
  const [t] = useTranslation("config");
  const [tg] = useTranslation("global");
  const [openModal, setOpenModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [id, setId] = React.useState();
  const [toggleState, setToggleState] = useState(0);
  const [isLoad, setIsLoad] = React.useState(false);
  const buttonRef = React.useRef();

  const { permissions } = view;

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleChangePermision = (
    assigned,
    indexFather,
    indexView,
    indexAction
  ) => {
    let newState = [...permissionLista];
    if (!newState[indexFather].children) {
      return;
    }
    let currentActions = newState[indexFather].children[indexView].actions;
    if (!currentActions) {
      return;
    }
    let currentAction = currentActions[indexAction];
    currentAction.assigned = assigned;
    currentActions[indexAction] = currentAction;
    if (currentAction.id === 2 && assigned) {
      let indexRead = currentActions.findIndex((item) => item.id === 1);
      if (indexRead > -1) {
        currentActions[indexRead].assigned = true;
      }
    }

    if (currentAction.id === 1 && !assigned) {
      for (let index = 0; index < currentActions.length; index++) {
        if (currentActions[index].id !== currentAction.id) {
          currentActions[index].assigned = false;
        }
      }
    }

    newState[indexFather].children[indexView].actions = currentActions;

    setPermissionsList(newState);
  };

  const handleChangePermisionView = (assigned, indexFather, indexAction) => {
    let newState = [...permissionLista];
    if (!newState[indexFather].actions) return;
    let currentActions = newState[indexFather].actions;
    if (!currentActions) return;
    let currentAction = currentActions[indexAction];
    if (!currentAction) return;
    currentAction.assigned = assigned;
    currentActions[indexAction] = currentAction;

    if (currentAction.id === 2 && assigned) {
      let indexRead = currentActions.findIndex((item) => item.id === 1);
      if (indexRead > -1) {
        currentActions[indexRead].assigned = true;
      }
    }

    if (currentAction.id === 1 && !assigned) {
      for (let index = 0; index < currentActions.length; index++) {
        if (currentActions[index].id !== currentAction.id) {
          currentActions[index].assigned = false;
        }
      }
    }
    newState[indexFather].actions = currentActions;

    setPermissionsList(newState);
  };

  const { state, setData, setExplicitField, validate } = useValidator({
    initialData: {
      name: null,
    },
    schema: Joi.object({
      name: Joi.string()
        .min(2)
        .max(100)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.type", { type: 'string' }),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '100' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      name: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  React.useEffect(() => {
    dispatch(getProfilesList());
  }, [dispatch]);

  const validacionPermisos = () => {
    let validacion = false;
    if (permissionLista && permissionLista.length > 0) {
      permissionLista.forEach((row) => {
        if (row.children.length === 0) {
          row.actions.forEach((actions) => {
            if (actions.assigned === true) {
              validacion = true;
            }
          })
        }
        if (row.children.length > 0) {
          row.children.forEach((actions) => {
            actions.actions.forEach((method) => {
              if (method.assigned === true) {
                validacion = true;
              }
            })
          })
        }
      })
    }
    return validacion;
  }

  const handleOnSubmit = async () => {
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      setShowError(true);
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    if (!validacionPermisos()) {
      notify("error", t("error.permission"), 1500);
      return;
    };

    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    let arraySend = [];
    for (const father of permissionLista) {
      if (father.children.length > 0) {
        for (const children of father.children) {
          for (const action of children.actions) {
            if (action.assigned) {
              arraySend.push({
                idView: children.id,
                idAction: action.id,
                idFather: father.id,
              });
            }
          }
        }
      } else {
        for (const action of father.actions) {
          if (action.assigned) {
            arraySend.push({
              idView: father.id,
              idAction: action.id,
              idFather: null,
            });
          }
        }
      }
    }
    setShowError(false);
    let isSuccess = await dispatch(
      addOrUpdateProfile({
        name: state.$data.name,
        id,
        viewsActions: arraySend,
      })
    );
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);
    if (isSuccess) {
      setData((old) => ({
        ...old,
        name: null,
      }));
      setId(null);
      setOpenModal(false);
    }
    setPermissionsList([]);
  };

  React.useEffect(() => {
    getPermissionsList(id ? id : 0).then((response) => {
      setPermissionsList(response ? response : []);
    });
  }, [id]);

  const handleDelete = async (idRol) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteProfile(idRol));
      }
    });
  };

  return (
    <>
      <Grid
        title={t("profile.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("profile.name"),
            accessor: "name",
            filter: "text",
            width: '40%'
          },
          {
            Header: t("profile.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '40%'
          },
        ]}
        data={profiles || []}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const { id, name } = row;
          setId(id);
          setData((old) => ({
            ...old,
            name,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setData((old) => ({
              ...old,
              name: null,
            }));
            setId(null);
            setShowError(false);
            setOpenModal(false);
            setPermissionsList([]);
          }}
          titleModal={t("profile.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          size={"lg"}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("profile.info_modal")}
          </h4>
          <div className="flex flex-wrap inline">
            <InputCustom
              type={"text"}
              labelText={t("profile.name")}
              errorMessage={
                showError && state.$errors.name.length > 0 ? state.$errors.name[0].$message : null
              }
              onBlur={() => setExplicitField("name", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name}
            />
            <div className="flex flex-col w-full min-h-[400px]">
              <div className="mt-4">
                <h2 className="font-[Gravity] py-2">
                  {t("profile.label_views_permissions")}
                </h2>
                <hr />
              </div>
              <div className="flex mt-4">
                {permissionLista.map((permission, index) => (
                  <button
                    className={
                      toggleState === index
                        ? "px-1 py-1 border border-b-0 border-solid rounded-t-lg cursor-pointer bg-white text-[#000]"
                        : "px-4 py-1 text-center cursor-pointer rounded-t-lg bg-[#222222] text-[#fff]"
                    }
                    onClick={() => toggleTab(index)}
                  >
                    {permission.name}
                  </button>
                ))}
              </div>
              {permissionLista.map((permission, indexFather) => (
                <div
                  className={
                    toggleState === indexFather &&
                      permissionLista[indexFather].children.length === 0
                      ? "flex  relative w-8/12 overflow-x-auto sm:rounded-lg"
                      : toggleState === indexFather &&
                        permissionLista[indexFather].children.length > 0
                        ? "flex w-full justify-center relative  overflow-x-auto sm:rounded-lg"
                        : "bg-white p-5 hidden h-full w-full relative overflow-x-auto shadow-md sm:rounded-lg"
                  }
                >
                  {permissionLista[indexFather].children.length > 0 ? (
                    <table className="w-full text-left border-collapse border border-t-0 border-slate-400">
                      <thead>
                        <tr>
                          <th className="border border-t-0 border-slate-300 px-6">
                            {t("profile.views")}
                          </th>
                          <th className="border border-t-0 border-slate-300 px-6">
                            {t("profile.actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {permission.children.map((children, indexView) => (
                          <tr>
                            <td className="border border-slate-300 py-1 px-6">
                              {children.name}
                            </td>
                            <td className="border border-slate-300 py-1 px-6">
                              {children.actions.map((actions, indexAction) => (
                                <div className="inline px-2">
                                  <input
                                    onChange={(event) =>
                                      handleChangePermision(
                                        event.target.checked,
                                        indexFather,
                                        indexView,
                                        indexAction
                                      )
                                    }
                                    checked={actions.assigned}
                                    id={indexView}
                                    type="checkbox"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    for="default-checkbox"
                                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 capitalize"
                                  >
                                    {actions.name}
                                  </label>
                                </div>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    permission.actions.map((actions, indexAction) => (
                      <div className="pt-6 px-2 ">
                        <input
                          onChange={(event) =>
                            handleChangePermisionView(
                              event.target.checked,
                              indexFather,
                              indexAction
                            )
                          }
                          checked={actions.assigned}
                          id={indexAction}
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="default-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 capitalize"
                        >
                          {actions.name}
                        </label>
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Profile;
