const IconUser = ({ width, height, bg, fill }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill={bg} />
      <rect width="150" height="150" fill={bg} />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-3532 -769)"
          fill={bg}
        />
        <g id="icono_usuarios">
          <g id="CeCScG.tif">
            <path
              id="Vector"
              d="M0 102.901C0.520515 100.785 1.19432 98.7093 2.01607 96.6912C3.49943 93.037 5.99461 89.8819 9.20862 87.5964C12.4226 85.3108 16.222 83.9898 20.1607 83.7883C24.4347 83.7883 28.7894 83.7883 33.1441 83.7883V84.9173C32.4506 88.1323 31.9924 91.3935 31.7732 94.6751C31.7732 108.788 31.7732 122.9 31.7732 137.013C31.8459 138.744 32.0345 140.468 32.3377 142.174H26.9347C22.6606 142.174 18.3059 142.174 14.0318 142.174C10.7262 142.37 7.46716 141.319 4.89891 139.229C2.33065 137.138 0.640066 134.16 0.161285 130.884C0.161285 130.884 0.161285 130.884 0.161285 130.884L0 102.901Z"
              fill={fill}
            />
            <path
              id="Vector_2"
              d="M74.9972 142.173H45.2401C41.7725 142.173 40.1596 140.641 40.1596 137.174C40.1596 122.9 40.1596 108.626 40.1596 94.433C40.0485 89.4251 41.6868 84.5352 44.7926 80.6051C47.8984 76.675 52.2772 73.9508 57.1752 72.9014C58.7431 72.6039 60.3375 72.4688 61.9331 72.4982H88.142C93.0915 72.4279 97.9119 74.0779 101.78 77.1666C105.648 80.2552 108.324 84.5909 109.351 89.4332C109.738 91.0986 109.927 92.8039 109.916 94.5137V137.012C109.916 140.399 108.303 142.012 104.835 142.012L74.9972 142.173Z"
              fill={fill}
            />
            <path
              id="Vector_3"
              d="M75.1586 15.0001C80.3102 15.0321 85.3366 16.591 89.6022 19.4797C93.8678 22.3683 97.1811 26.457 99.123 31.2287C101.065 36.0003 101.548 41.2407 100.512 46.2871C99.4756 51.3334 96.9661 55.9592 93.3008 59.5793C89.6355 63.1995 84.9791 65.6515 79.9203 66.6253C74.8615 67.599 69.6275 67.0508 64.8802 65.05C60.133 63.0491 56.0857 59.6855 53.25 55.3844C50.4144 51.0833 48.9179 46.038 48.9497 40.8864C49.0136 33.9848 51.8056 27.3888 56.7161 22.5387C61.6266 17.6887 68.2567 14.9785 75.1586 15.0001V15.0001Z"
              fill={fill}
            />
            <path
              id="Vector_4"
              d="M118.301 142.093C118.301 138.787 118.301 135.561 118.301 132.416V96.6911C118.457 92.5966 117.911 88.5055 116.689 84.5947C116.643 84.3821 116.643 84.1622 116.689 83.9496H117.818C121.85 83.9496 125.882 83.9496 129.833 83.9496C135.18 84.288 140.208 86.6074 143.936 90.4547C147.664 94.302 149.824 99.4009 149.994 104.755C149.994 112.82 149.994 120.884 149.994 128.948C150.085 132.192 148.92 135.345 146.741 137.75C144.562 140.154 141.538 141.624 138.301 141.851C131.608 142.416 124.834 142.093 118.301 142.093Z"
              fill={fill}
            />
            <path
              id="Vector_5"
              d="M9.83789 58.7892C9.83792 54.9488 10.9758 51.1945 13.1077 48.0002C15.2397 44.8059 18.2703 42.3149 21.8169 40.8416C25.3634 39.3682 29.267 38.9787 33.0348 39.7221C36.8026 40.4655 40.2656 42.3085 42.9868 45.0184C45.708 47.7284 47.5654 51.1838 48.3243 54.9485C49.0833 58.7131 48.7099 62.6183 47.2513 66.1709C45.7927 69.7235 43.3142 72.7644 40.1288 74.9096C36.9434 77.0548 33.1938 78.2082 29.3534 78.2241C24.1915 78.2241 19.2399 76.1791 15.5823 72.5367C11.9247 68.8942 9.85922 63.9511 9.83789 58.7892V58.7892Z"
              fill={fill}
            />
            <path
              id="Vector_6"
              d="M140.237 58.9502C140.204 62.8234 139.019 66.599 136.833 69.7965C134.647 72.994 131.56 75.4688 127.963 76.9058C124.366 78.3427 120.423 78.6769 116.635 77.8657C112.848 77.0546 109.388 75.1347 106.695 72.3507C104.002 69.5666 102.199 66.0442 101.514 62.2319C100.83 58.4196 101.295 54.4899 102.851 50.9429C104.407 47.3959 106.983 44.3922 110.252 42.3141C113.521 40.236 117.334 39.1776 121.206 39.2734C126.318 39.4207 131.171 41.5612 134.727 45.2377C138.283 48.9143 140.261 53.8354 140.237 58.9502Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconUser;
