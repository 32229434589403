import React from "react";
function Tooltip({ children, tooltipText, placement }) {
  const tipRef = React.createRef(null);
  function handleMouseEnter() {
    tipRef.current.style.opacity = 1;
  }
  function handleMouseLeave() {
    tipRef.current.style.opacity = 0;
  }

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`absolute top-[0px] left-[-30px] opacity-0 whitespace-no-wrap bg-[#222222] text-white text-[10px] font-[Gravity] px-1.5 py-1.5 rounded flex items-center transition-all duration-150 z-10 `}
        ref={tipRef}
      >
        {tooltipText}
      </div>
      {children}
    </div>
  );
}

export default Tooltip;
