import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import InputCustom from "components/InputCustom";
import Joi from "joi";
import Swal from "sweetalert2";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import {
  getEtapas,
  addOrUpdateEtapas,
  deleteEtapas,
  getClientList,
} from "actions/Catalogs";
import { useTranslation } from "react-i18next";
import { notify } from "utils";


const Stages = ({view}) => {
  const [tg]= useTranslation("global");
  const dispatch = useDispatch();
  const { etapasList, clients } = useSelector((state) => state.catalog);
  const [openModal, setOpenModal] = React.useState(false);
  const [id, setId] = React.useState();
  const {permissions} = view;
  const { state, setData, setExplicitField } = useValidator({
    initialData: {
      name: null,
      description: null,
      projectId: null,
      order: null,
      workFlow: false,
    },
    schema: Joi.object({
      name: Joi.string().required(),
      description: Joi.string().required(),
      projectId: Joi.number().required(),
      order: Joi.string().required(),
      workFlow: Joi.boolean().required(),
    }),
    explicitCheck: {
      name: false,
      description: false,
      projectId: false,
      order: false,
      workFlow: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  React.useEffect(() => {
    dispatch(getEtapas());
    dispatch(getClientList());
  }, [dispatch]);

 

  const handleOnSubmit = async () => {
    const { name, description, projectId, order, workFlow } = state.$data;

    if (state.$all_errors.length > 0) {
      notify("error", "Ingresa los campos faltantes", 800);
      return;
    }

    let dto = {
      name,
      description,
      projectId,
      order,
      workFlow,
      id,
    };
    let isSuccess = await dispatch(addOrUpdateEtapas(dto));
    if (isSuccess) {
      setData((old) => ({
        ...old,
        name: null,
        description: null,
        projectId: null,
        order: null,
        workFlow: null,
      }));
      setId(null);
      setOpenModal(false);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: tg("handleDelete.title"),
      text: tg("handleDelete.text"),
      icon: "error",
      confirmButtonText: tg("handleDelete.confirmButtonText"),
      cancelButtonText: tg("handleDelete.cancelButtonText"),
      cancelButtonColor: "#FF3447",
      confirmButtonColor: "#000000",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteEtapas(id));
      }
    });
  };

  return (
    <>
      <Grid
        title={"Etapas"}
        columns={[
          {
            Header: "Nombre",
            accessor: "name",
            filter: "text",
          },
          {
            Header: "Descripción",
            accessor: "description",
            filter: false,
          },
          {
            Header: "Orden",
            accessor: "order",
            filter: false,
          },
          {
            Header: "Requerido",
            accessor: "required",
            disableFilters: true,
            Cell: (props) => {
              if (props.value) return "Si";
              return "No";
            },
          },
        ]}
        data={
          etapasList || [
            {
              id: "int",
              name: "string",
              description: "string",
              stageId: "int",
              order: 1,
              required: true,
              active: "bool",
            },
          ]
        }
        handleAdd={() => {
          if(permissions.write){
            setOpenModal(true);
          }   
        }}
        handleEdit={(row) => {
          const { id, description, name, projectId, order, workFlow } = row;
          setId(id);
          setData((old) => ({
            ...old,
            name,
            description,
            projectId,
            order,
            workFlow,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />

      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
          titleModal="Etapas"
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            Info Etapas
          </h4>
          <div className="flex flex-wrap ">
            <InputCustom
              type={"text"}
              labelText="Nombre:"
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name ? state.$data.name : ""}
              onBlur={() => setExplicitField("name", true)}
              key="name"
            />
            <InputCustom
              type={"text"}
              labelText="Descripción:"
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  description: e.target.value,
                }));
              }}
              value={state.$data.description ? state.$data.description : ""}
              onBlur={() => setExplicitField("description", true)}
              key="description"
            />
            <InputCustom
              type={"select"}
              labelText="Proyecto"
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  projectId: e.target.value,
                }));
              }}
              options={
                clients &&
                clients.map((item) => ({
                  value: item.id,
                  text: item.name,
                }))
              }
              value={state.$data.projectId ? state.$data.projectId : ""}
              onBlur={() => setExplicitField("projectId", true)}
              key="projectId"
            />

            <InputCustom
              type={"number"}
              labelText="Orden:"
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  order: e.target.value,
                }));
              }}
              value={state.$data.order ? state.$data.order : ""}
              onBlur={() => setExplicitField("order", true)}
              key="order"
            />
            <InputCustom
              type={"checkbox"}
              labelText="Work flow:"
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  workFlow: e.target.checked,
                }));
              }}
              value={state.$data.workFlow ? state.$data.workFlow : ""}
              onBlur={() => setExplicitField("workFlow", true)}
              key="workFlow"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Stages;
