import * as React from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import * as excel from "exceljs";
import { getCollNameExcel } from "utils";

const exportExcel = async ({
  nameExcel,
  columns = [],
  data = [],
  headerBg,
}) => {
  if (data?.length === 0) return;
  let workbook = new excel.Workbook();
  let sheet = workbook.addWorksheet(nameExcel || "Hoja 1");

  sheet.columns = columns.map((item) => {
    return {
      key: item.accessor,
      header: item.Header,
      width: item?.maxWidth
        ? item.maxWidth.replace("px", "").replace("%", "")
        : 20,
      height: 30,
    };
  });

  if (!data) return null;
  for (let row of data) {
    sheet.addRow(row);
  }

  sheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.border = {
        top: { style: "thin", color: "red" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    row.commit();
  });

  columns.map((item, index) => {
    const letter = `${getCollNameExcel(index).toUpperCase()}1`;
    sheet.getCell(letter).fill = {
      type: "pattern",
      pattern: "lightGray",
      fgColor: { argb: "00000000" },
      bgColor: {
        argb: headerBg ? headerBg.replace("#", "") : "red",
      },
    };
    sheet.getCell(letter).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell(letter).font = {
      name: "Arial",
      size: 12,
      color: { argb: "FFFFFF" },
    };
    return {};
  });

  const buf = await workbook.xlsx.writeBuffer();
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(
    new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  a.href = url;
  a.download = `${nameExcel || "Rerpote"}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
  if (a && a.parentElement) {
    a.parentElement.removeChild(a);
  }
};

function DefaultColumnFilter(config) {
  const {
    column: { filterValue, setFilter, Header },
  } = config;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={Header || "Buscar"}
      className="w-full m-1 p-1 outline-0	"
    />
  );
}

const Grid = ({
  title,
  columns,
  data,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSelect,
  controlsHeaderHidden,
  hiddenDefaultOptions,
  controlersHeaderComponents,
  AdditionalOptionsRows,
  write,
  exportData,
  hiddenHandleAdd
}) => {
  const { PUBLIC_URL } = process.env;
  const [openAction, setOpenAction] = React.useState();
  const [t] = useTranslation("global");

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const getDefaultOptions = (row, index) => {
    if (!hiddenDefaultOptions && write) {
      return (
        <td
          className="w-[50px] text-wrap h-auto grid place-items-center pt-2"
          key={`td_${index}`}
        >
          <Menu
            animate={{
              mount: { y: 0 },
              unmount: { y: 25 },
            }}
          >
            <MenuHandler className="grid place-items-center">
              <img
                src={`${PUBLIC_URL}/icons/btns/btn_options.svg`}
                alt="#"
                width={4}
                height={4}
              />
            </MenuHandler>
            <MenuList className="left-start !min-w-[100px] !max-w-[140px]">
              <MenuItem
                onClick={() => {
                  const { original } = row;
                  handleEdit(original);
                  setOpenAction(null);
                }}
                className={"font-[Gravity]"}
              >
                {t("grid.label_edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const { original } = row;
                  handleDelete(original);
                  setOpenAction(null);
                }}
                className={"font-[Gravity]"}
              >
                {t("grid.label_delete")}
              </MenuItem>
              {AdditionalOptionsRows ? (
                <AdditionalOptionsRows
                  row={row}
                  setOpenAction={setOpenAction}
                />
              ) : null}
            </MenuList>
          </Menu>
        </td>
      );
    }

    if (AdditionalOptionsRows && write) {
      return (
        <td className="w-10" key={`td_${index}`}>
          <span
            className="text-blueGray-500 block py-1 px-3 hover:bg-gray-200 hover:text-gray-900 flex justify-center items-center"
            onClick={(e) => {
              e.preventDefault();
              setOpenAction(index === openAction ? null : index);
            }}
          >
            <img
              src={`${PUBLIC_URL}/icons/btns/btn_options.svg`}
              alt="#"
              width={4}
              height={4}
            />
          </span>
          <div
            className={`bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48 ${
              index === openAction ? "flex" : "hidden"
            }`}
            id="table-dark-1-dropdown"
          >
            <AdditionalOptionsRows row={row} setOpenAction={setOpenAction} />
          </div>
        </td>
      );
    }
  };
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useResizeColumns,
    useBlockLayout
  );

  const getControlsUi = () => {
    return (
      <>
        {controlersHeaderComponents && controlersHeaderComponents()}

        {!controlsHeaderHidden ? (
          <>
            {write && !hiddenHandleAdd? (
              <button
                onClick={() => {
                  setOpenAction(null);
                  handleAdd && handleAdd();
                }}
              >
                <img
                  src={`${PUBLIC_URL}/icons/btns/btn_add.svg`}
                  className="w-[30px] h-[30px]"
                  alt="#"
                />
              </button>
            ) : null}
            {write && handleSelect ? (
              <button
                onClick={() => {
                  setOpenAction(null);
                  handleSelect && handleSelect();
                }}
              >
                <img
                  src={`${PUBLIC_URL}/icons/btns/btn_add.svg`}
                  className="w-[30px] h-[30px]"
                  alt="#"
                />
              </button>
            ) : null}
            {exportData ? (
              <button
                onClick={() => {
                  let dataExport = [];
                  for (const item of rows) {
                    let dtoLocal = {};
                    const originalRow = item.original;
                    if (originalRow) {
                      for (const header of columns) {
                        /* eslint no-eval: 0 */
                        dtoLocal[header.accessor] = eval(
                          `originalRow.${header.accessor}`
                        );
                      }
                      dataExport.push(dtoLocal);
                    }
                  }
                  exportExcel({
                    nameExcel: t("file"),
                    columns,
                    data: dataExport,
                  });
                }}
              >
                <img
                  src={`${PUBLIC_URL}/icons/btns/btn_download.svg`}
                  className="w-[30px] h-[30px]"
                  alt="#"
                />
              </button>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  return (
    <div
      className="flex flex-col w-full"
      onMouseLeave={() => {
        setOpenAction(null);
      }}
    >
      <div className="bg-[#000000] rounded-t-lg h-[59px] w-full flex items-center justify-between px-4">
        <h3 className="text-[#ffffff] text-[16px] font-[Gravity] font-bold">
          {title}
        </h3>
        <div className="flex flex-row gap-1.5	">{getControlsUi()}</div>
      </div>
      <div className={`flex flex-col items-start w-full h-[420px] overflow-x-scroll overflow-y-scroll shadow-scroll-x`}>
        <table {...getTableProps()} className="w-full ">
          <thead className="bg-white border-b-[.5px] border-[#5c5c5c] top-0 ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps({style:{
                    width: '100%',
                  }})} >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style:{
                         width: column.width,
                      }
                    })}
                    className="text-[13px] font-[Gravity] text-gray-400"
                  >
                    {column.canFilter
                      ? column.render("Filter")
                      : column.render("Header")}
                  </th>
                ))}
                {!hiddenDefaultOptions ? <th className="h-[28px]"></th> : null}
              </tr>
            ))}
          </thead>

          <tbody
            {...getTableBodyProps()}
            className="max-h-[300px] overflow-y-auto"
          >
            {page.map((row) => {
              const { index } = row;
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps({style:{
                    width: '100%',
                    
                  }})}
                  className="border-b-[.5px] border-[#BCBEC1] h-[auto] pb-[3px] px-2 text-wrap"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style:{
                            width: cell.column.width,
                            
                          }
                        })}
                        className="flex pl-2 text-[12px] font-[Gravity] text-wrap"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {getDefaultOptions(row, index)}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-row items-center justify-center mt-2 xs:mt-0 mt-2 xs:mt-0">
            <button
              className="w-[20px] h-[20px]"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              <img
                src={`${PUBLIC_URL}/icons/btns/btn_arrow_prev.svg`}
                width={50}
                alt="#"
              />
            </button>
            {canPreviousPage ? (
              <button
                className=" m-2  px-2 py-1  cursor-pointer"
                onClick={() => gotoPage(pageIndex - 1)}
              >
                <span className="font-bold font[Gravity] text-[12px]">
                  {pageIndex}
                </span>
              </button>
            ) : null}

            <button
              className="bg-[#BCBEC1] m-2 rounded px-2 py-1  cursor-pointer"
              onClick={() => gotoPage(pageIndex)}
            >
              <span className="font-bold font[Gravity] text-[12px]">
                {pageIndex + 1}
              </span>
            </button>
            {canNextPage ? (
              <button
                className=" m-2  px-2 py-1 cursor-pointer"
                onClick={() => gotoPage(pageIndex + 1)}
              >
                <span className="font-bold font[Gravity] text-[12px]">
                  {pageIndex + 2}
                </span>
              </button>
            ) : null}
            <button
              className="w-[20px] h-[20px]"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              <img
                src={`${PUBLIC_URL}/icons/btns/btn_arrow_next.svg`}
                width={50}
                alt="#"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grid;
