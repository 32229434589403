import { format, parseISO } from "date-fns";
export function filterDate(rows, columnIds, filterValue) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
        String(format(parseISO(row.original.user['updatedAt']), "dd/MM/yyyy HH:mm")).includes(filterValue),
      );


  }

export function filterDateC(rows, columnIds, filterValue) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
        String(format(parseISO(row.original['updatedAt']), "dd/MM/yyyy HH:mm")).includes(filterValue),
      );
}

export function fDate(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) =>
      String(format(parseISO(row.original['updatedAt']), "dd/MM/yyyy HH:mm")).includes(filterValue),
    );
}

