import React from "react";
import { format } from "date-fns";
import { parseISO } from "date-fns/esm";
import { useTranslation } from "react-i18next";
const CardAlert = ({
  type,
  setOpenIndex = () => { },
  index,
  openIndex,
  dataTrip,
  timeEvent,
  getBodyAlert = () => { },
  status
}) => {
  const { loadId, origin, destination } = dataTrip;
  const [t] = useTranslation("monitoring");

  const [bodyAlert, setBodyAlert] = React.useState();

  const getBg = () => {
    if (index === openIndex) {
      return "bg-[#5C5C5C]";
    }
    return "bg-[#FFBB35]";
  };
  const getAddress = () => {
    let composeAddress = "";
    if (origin && origin.address) {
      composeAddress += `${origin.address.state}`;
    }

    if (destination && destination.address) {
      composeAddress += ` - ${destination.address.state}`;
    }
    return composeAddress;
  };

  React.useEffect(() => {
    if (index === openIndex && dataTrip) {
      getBodyAlert(dataTrip.id).then((response) => {
        if (response) setBodyAlert(response);
      });
    }
  }, [index, openIndex, dataTrip, getBodyAlert]);


  const className = `${getBg()} m-2`;
  return (
    <div
      key={index}
      aria-hidden="true"
      className={`w-12/12 min-h-[120px] ${className}  p-2 rounded-[15px]`}
      onClick={() => { }}
    >
      <div className="flex justify-between items-center">
        <span className="font-bold font-[Gravity] text-[14px]">
        {t("alerts.label_trip")}: {loadId}
        </span>
        

        <button className="bg-transparent ">
          <svg
            width="30"
            height0="30"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="75" cy="75" r="75" fill="#DEDEDE" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M64.4468 41.1429H79.5922C78.3979 38.131 75.4566 36 72.0193 36C68.5815 36 65.6413 38.1303 64.4468 41.1429ZM85.8428 41.1429H99.8764C101.533 41.1429 102.876 42.486 102.876 44.1429V111C102.876 118.811 96.5444 125.143 88.7335 125.143H55.305C47.4941 125.143 41.1621 118.811 41.1621 111V44.1429C41.1621 42.486 42.5053 41.1429 44.1621 41.1429H58.1952C59.571 34.773 65.2378 30 72.0193 30C78.8011 30 84.4661 34.7719 85.8428 41.1429ZM47.1621 47.1429V111C47.1621 115.497 50.8078 119.143 55.305 119.143H88.7335C93.2307 119.143 96.8764 115.497 96.8764 111V47.1429H47.1621Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30 44.1438C30 42.4869 31.3431 41.1438 33 41.1438H111C112.657 41.1438 114 42.4869 114 44.1438C114 45.8007 112.657 47.1438 111 47.1438H33C31.3431 47.1438 30 45.8007 30 44.1438Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M60.8477 57.856C62.5045 57.856 63.8477 59.1991 63.8477 60.856V105.427C63.8477 107.084 62.5045 108.427 60.8477 108.427C59.1908 108.427 57.8477 107.084 57.8477 105.427V60.856C57.8477 59.1991 59.1908 57.856 60.8477 57.856Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M83.1562 57.856C84.8131 57.856 86.1562 59.1991 86.1562 60.856V105.427C86.1562 107.084 84.8131 108.427 83.1562 108.427C81.4994 108.427 80.1562 107.084 80.1562 105.427V60.856C80.1562 59.1991 81.4994 57.856 83.1562 57.856Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-2 gap-1 m-1">
        <span className="font-[Gravity] text-[10px]">{getAddress()}</span>
        <span className="font-[Gravity] text-[10px]">
          {format(parseISO(timeEvent), "yyyy-MM-dd hh:mm")}
        </span>
      </div>
      <div className="grid grid-cols-1 gap-1 m-1">
      <span className="font-[Gravity] text-sm">
          {t("alerts.laber_alert")}: {type ==="POSSIBLE_ROUTE_DANGER"?t("alerts.route_danger"):type ==="INCIDENCE"? t("alerts.incidence"):null}
        </span>
        <p className="font-[Gravity] m-1">
          {t("alerts.label_status")}: {
            status ?
              status === 'Pending' ? t("statusAlert.pending") :
                status === 'Accepted' ? t("statusAlert.accepted") :
                  status === 'Refused' ? t("statusAlert.refused") :
                    status === 'Inprocess' ? t("statusAlert.inprocess") :
                      status === 'Complete' ? t("statusAlert.complete") :
                        status === 'NotStarted' ? t("statusAlert.notStarted") :
                          status === 'InOrigin' ? t("statusAlert.inOrigin") :
                            status === 'Arrive' ? t("statusAlert.arrive") :
                              status === 'Unloading' ? t("statusAlert.unloading") :
                                status === 'Finished' ? t("statusAlert.finished") :
                                  status === 'Closing' ? t("statusAlert.closing") : "" : ""}
        </p>
        
        {index === openIndex ? (
          <>
            <p className="font-[Gravity] m-1">
              {t("alerts.label_carrier")}: {bodyAlert?.carrier.name}
            </p>
            <p className="font-[Gravity] m-1">
              {t("alerts.label_operator")}: {bodyAlert?.operator?.user?.fullName}
            </p>
            <p className="font-[Gravity] m-1">
              {t("alerts.label_unit")}: {bodyAlert?.truck?.truckType?.name}
            </p>
            <p className="font-[Gravity] m-1">
              {t("alerts.label_plate")}: {bodyAlert?.truck?.plateNumber}
            </p>
          </>
        ) : null}
      </div>
      <div className="flex justify-center items-end border-t-2 border-[rgba(0, 0, 0, 0.25)] py-1">
        <button
          onClick={() => {
            setOpenIndex(index === openIndex ? null : index);
          }}
        >
          {index !== openIndex ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M147.017 50.3487L82.2017 115.023C78.2243 118.992 71.7757 118.992 67.7983 115.023L2.98305 50.3487C-0.994339 46.38 -0.994339 39.9453 2.98305 35.9766C6.96043 32.0078 13.4091 32.0078 17.3864 35.9766L75 93.4652L132.614 35.9766C136.591 32.0078 143.04 32.0078 147.017 35.9766C150.994 39.9453 150.994 46.38 147.017 50.3487Z"
                fill="#222222"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.98304 99.6513L67.7983 34.9766C71.7757 31.0078 78.2243 31.0078 82.2017 34.9766L147.017 99.6513C150.994 103.62 150.994 110.055 147.017 114.023C143.04 117.992 136.591 117.992 132.614 114.023L75 56.5348L17.3864 114.023C13.409 117.992 6.96043 117.992 2.98304 114.023C-0.994347 110.055 -0.994346 103.62 2.98304 99.6513Z"
                fill="#fff"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default CardAlert;
