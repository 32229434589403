import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { truncateText } from "utils";

import SideBar from "components/SideBar";
import IconNotificationOff from "assets/icons/IconNotificationOff";
import IconHome from "assets/icons/IconHome";
import IconCatalogos from "assets/icons/IconCatalogos";
import IconConfiguracion from "assets/icons/IconConfiguracion";
import IconPlaneacion from "assets/icons/IconPlaneacion";
import IconUser from "assets/icons/IconUser";
import IconMonitor from "assets/icons/IconMonitor";
import { setProject } from "redux/auth.slice";

import { motion } from "framer-motion";
import { GetInformationUserByTokenView } from "actions/Auth";
import ErrorBoundary from "error/ErrorBoundary";

export default function Layout({ children, iconName, textRoute }) {
  const [t] = useTranslation("global");
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { user, currentProject, myProjectsList, role } = useSelector(
    (state) => state.auth
  );
  const [openProjects, setOpenProjects] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(null);

  useEffect(() => {
    dispatch(GetInformationUserByTokenView(token))
  }, [i18n.language, dispatch, token])




  const getIcon = () => {
    switch (iconName) {
      case "home": {
        return <IconHome height={35} width={35} bg="none" fill={"#000000"} />;
      }
      case "catalog": {
        return (
          <IconCatalogos height={35} width={35} bg="none" fill={"#000000"} />
        );
      }
      case "config": {
        return (
          <IconConfiguracion height={35} width={35} bg="none" fill={"#000000"} />
        );
      }
      case "monitoring": {
        return (
          <IconMonitor height={35} width={35} bg="none" fill={"#000000"} />
        );
      }
      case "planning": {
        return (
          <IconPlaneacion height={35} width={35} bg="none" fill={"#000000"} />
        );
      }
      case "user": {
        return <IconUser height={35} width={35} bg="none" fill={"#000000"} />;
      }
      case "default": {
        return "";
      }
      default: {
        return "Not found";
      }
    }
  };

  const getFullName = (userObject) => {
    if (!userObject) return "";
    let fullName =
      `${userObject?.name}  ${userObject?.secondLastName}  ${userObject?.lastName}`.toUpperCase();
    return truncateText(fullName, 20);
  };

  const renderIconProjects = () => {
    return (
      <button
        className={`w-[30px]  h-[30px] bg-[#DEDEDE] rounded-full flex justify-center  items-center cursor-pointer ${openProjects ? "-rotate-90" : ""
          }`}
        onClick={() => {
          setOpenProjects(!openProjects);
        }}
      >
        <svg
          width="150"
          height="150"
          viewBox="0 0 150 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="75" cy="75" r="75" fill="#DEDEDE" />
          <path d="M75 108L37.5 42.75L112.5 42.75L75 108Z" fill="#222222" />
        </svg>
      </button>
    );
  };

  const renderIconNotification = () => {
    return (
      <button className="w-[30px]  h-[28px] bg-[#DEDEDE] rounded-full flex justify-center  items-center cursor-pointer">
        <IconNotificationOff
          width={18}
          height={18}
          color="#000000"
          fill={"none"}
        />
      </button>
    );
  };

  const renderProjects = () => {
    return (
      <motion.div
        className="bg-[#ffffff] rounded absolute z-[20px]  right-[110px] top-[80px] px-2 py-1 flex flex-col shadow-lg border-[#c3c3c3] border-[1px]"
        animate={openProjects ? "open" : "closed"}
        variants={{
          open: { opacity: 1, width: 140, height: "auto", display: "flex" },
          closed: { opacity: 0, height: 0, width: 0, display: "none" },
        }}
        onMouseLeave={() => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          if (openProjects) {
            setTimeoutId(setTimeout(() => setOpenProjects(false), 3000));
          }
        }}
        onMouseOver={() => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        }}
      >
        <div className="flex flex-row  justify-end border-b-[2px] border-b-[#c3c3c3] pb-1">
          <button
            className={`rounded border-[1px] border-black m-1 w-[30px] text-center px-1 py-1 text-sm ${i18n.language === "es"
              ? " bg-[#222222] text-white"
              : " text-black"
              }`}
            onClick={() => {
              localStorage.setItem("language", "es");
              i18n.changeLanguage("es");
            }}
          >
            ES
          </button>
          <button
            className={`rounded border-[1px] border-black m-1 w-[30px] text-center px-1 py-1 text-sm ${i18n.language === "en"
              ? " bg-[#222222] text-white"
              : " text-black"
              }`}
            onClick={() => {
              localStorage.setItem("language", "en");
              i18n.changeLanguage("en");
            }}
          >
            EN
          </button>
        </div>
        <div className="w-full">
          <h5 className="font-[Gravity] py-2 text-sm font-bold">
            {t("project_menu.label_projects")}
          </h5>
          {myProjectsList.map((item, index) => {
            let isLast = myProjectsList.length - 1 === index;
            let classAditional = isLast
              ? ""
              : "border-b-[1px] border-[#222222]";
            return (
              <motion.button
                className={`p-1 text-left font-[Gravity] text-[#222222] hover:bg-[#222222] hover:text-[#ffffff] ${classAditional} w-full text-sm`}
                onClick={() => {
                  dispatch(setProject(item));
                  setOpenProjects(false);
                }}
                animate={openProjects ? "open" : "closed"}
                variants={{
                  open: { opacity: 1 },
                  closed: { opacity: 0 },
                }}
              >
                {item.name}
              </motion.button>
            );
          })}
        </div>
      </motion.div>
    );
  };

  return (
    <div className="flex flex-col h-screen w-screen	 bg-[#f8f8f8]">
      <header className="bg-[#f8f8f8] flex justify-between pl-[80px]">
        <div className="flex flex-row items-end">
          {getIcon()}
          <h6 className="mx-2 font-[Gravity] text-[16px]">{textRoute}</h6>
        </div>

        <div className="rounded-md shadow-lg border-[#c3c3c3] border-[1px] min-w-[150px] h-[60px] justify-between  items-center flex flex-row px-2 mr-[10px] mt-2">
          {renderIconProjects()}
          <div className="flex flex-col justify-start items-start w-[140px] mx-2 pl-5">
            <span className="font-[Gravity] text-[13px] text-[#5C5C5C] font-bold">
              {getFullName(user)}
            </span>
            <span className="font-[Gravity] text-[10px] text-[#5C5C5C]">
              {role ? role?.name : ""} &nbsp;/&nbsp;
              <span className="font-[Gravity] text-[10px] text-[#5C5C5C]">
                {currentProject ? currentProject?.name : ""}
              </span>
            </span>
          </div>
          {renderIconNotification()}
        </div>
      </header>

      <div className="pl-[80px] h-full pr-5">{children}</div>
      {renderProjects()}
      <ErrorBoundary sidebar={true}>
        <SideBar />
      </ErrorBoundary>
    </div>
  );
}
