import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  planningEarring: {},
  idIntervalId: null,
};

const plannigSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    setPlannigEarring(state, action) {
      return { ...state, planningEarring: action.payload };
    },
    setIdInterval(state, action) {
      return { ...state, idIntervalId: action.payload };
    },
    clearIntervalId(state, action) {
      clearInterval(state.idIntervalId);
      return { ...state, idIntervalId: action.payload };
    },
  },
});

export const { setPlannigEarring, setIdInterval, clearIntervalId } =
  plannigSlice.actions;
export default plannigSlice.reducer;
