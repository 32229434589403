import { Tooltip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

const Modal = ({
  openModal,
  closeModal,
  titleModal,
  children,
  onSubmit = () => {},
  disableAdd,
  size,
  hiddenButtonDefault,
  iconEdit = false,
  processFetch = false,
  buttonRef = null,
}) => {
  const { PUBLIC_URL } = process.env;
  const [t] = useTranslation("global");
  const getClasses = () => {
    if (disableAdd) return "";
    if (processFetch) return "";
    return "dark:hover:bg-gray-600 dark:hover:text-white hover:bg-gray-200 hover:text-gray-900";
  };
  return (
    <div
      id="defaultModal"
      aria-hidden="true"
      className={`${
        openModal ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full flex justify-center  bg-modal`}
    >
      <div
        className={`relative p-4 w-full  h-full md:h-auto ${
          size === "lg"
            ? "max-w-4xl"
            : size === "xlg"
            ? "max-w-6xl"
            : "max-w-2xl"
        }`}
      >
        <div className="relative rounded-[10px] shadow bg-transparent">
          <div className="flex justify-between items-center py-2 px-4 rounded-t-[10px] border-b bg-[#222222]">
            <h3 className="text-white font-[Gravity] text-[20px] font-bold">
              {titleModal}
            </h3>
            {!hiddenButtonDefault ? (
              <Tooltip
                content={iconEdit ? t("modal.edit") : t("modal.save")}
                className="z-[9999]"
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0, y: 25 },
                }}
                style={{ zIndex: 9999, fontFamily: "Gravity" }}
              >
                <button
                  className={`text-gray-400 bg-transparent  rounded-lg text-sm p-1.5 ml-auto flex justify-center items-center ${getClasses()}`}
                  data-modal-toggle="defaultModal"
                  onClick={() => onSubmit()}
                  ref={buttonRef}
                >
                  {processFetch ? (
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : iconEdit ? (
                    <img
                      src={`${PUBLIC_URL}/icons/btns/btn_edit.svg`}
                      width={35}
                      height={35}
                      alt="#"
                    />
                  ) : (
                    <img
                      src={`${PUBLIC_URL}/icons/btns/btn_save.svg`}
                      width={35}
                      height={35}
                      alt="#"
                    />
                  )}
                </button>
              </Tooltip>
            ) : null}
            <button
              className="w-[20px] h-[20px] p-4 font-bold text-[26px] rounded flex items-center justify-center  text-[#ffffff]  hover:bg-gray-200 hover:text-gray-900"
              onClick={() => closeModal()}
              disabled={processFetch}
              title="Close"
            >
              X
            </button>
          </div>
          <div className="px-6 py-2 space-y-1 min-h-[300px] bg-white">
            {children}
          </div>
          <div className="flex p-2 space-x-1 rounded-b-[10px] bg-[#222222]" />
        </div>
      </div>
    </div>
  );
};
export default Modal;
