import { Routes, Route, Navigate } from "react-router-dom";
import PlanningUpload from "./Modules/PlanningUpload";
import PlanningLoad from "./Modules/PlanningLoad";
import PlanningDetail from "./Modules/PlanningDetail";
import PlanningAssiggnment from "./Modules/PlanningAssiggnment";
import RestrictionRoute from "routes/RestrictionRoute";
const Routers = ({views}) => {
  return (
    <Routes>
      <Route path="upload" element={<RestrictionRoute views={views}><PlanningUpload /></RestrictionRoute>} />
      <Route path="detail" element={<RestrictionRoute views={views}><PlanningDetail /></RestrictionRoute>} />
      <Route path="history" element={<RestrictionRoute views={views}><PlanningLoad /></RestrictionRoute>} />
      <Route path="load-plan-assignment" element={<RestrictionRoute views={views}><PlanningAssiggnment /></RestrictionRoute>} />
      <Route path="*" element={<Navigate to="../upload" replace />} />
    </Routes>
  );
};

export default Routers;
