import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "translations/es/global.json";
import global_en from "translations/en/global.json";
import catalog_es from "translations/es/catalog.json";
import catalog_en from "translations/en/catalog.json";
import planning_es from "translations/es/planning.json";
import planning_en from "translations/en/planning.json";
import monitoring_es from "translations/es/monitoring.json";
import monitoring_en from "translations/en/monitoring.json";
import config_es from "translations/es/config.json";
import config_en from "translations/en/config.json";
import user_es from "translations/es/user.json";
import user_en from "translations/en/user.json";
import dashboard_es from "translations/es/dashboard.json";
import dashboard_en from "translations/en/dashboard.json";
import recover_es from "translations/es/recover.json";
import recover_en from "translations/en/recover.json";
import default_en from "translations/en/default.json";
import default_es from "translations/es/default.json";

const currentLan  =  localStorage.getItem('language')
let lng = navigator.language;
const support_lng = ["es", "en"];
if (lng) {
  lng = lng.split("-")[0];
  if (!support_lng.includes(lng)) {
    lng = "en";
  }
} else {
  lng = "es";
}
if(currentLan) {
  lng = currentLan
}

i18next.init({
  interpolation: { escapeValue: false },
  lng,
  resources: {
    es: {
      global: global_es,
      catalog:catalog_es,
      planning:planning_es,
      monitoring:monitoring_es,
      config:config_es,
      user:user_es,
      dashboard:dashboard_es,
      recover: recover_es,
      default:default_es
    },
    en: {
      global: global_en,
      catalog:catalog_en,
      planning:planning_en,
      monitoring:monitoring_en,
      config:config_en,
      user:user_en,
      dashboard:dashboard_en,
      recover:recover_en,
      default:default_en
    },
  },
});

export default function TranslationProvider({ children }) {
  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
}
