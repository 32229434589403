import * as React from "react";
import Layout from "layout";
import BarVertical from "components/BarVertical";
import Routers from "./Routers";
import MenuItemsModule from "components/MenuItemsModule";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "error/ErrorBoundary";
const Planning = ({ childrenMenu = [] }) => {
  const [t] = useTranslation("global");
  return (
    <Layout iconName={"planning"} textRoute={t("modules.title_planning")}>
      <div className="mt-10 grid grid-cols-20/80 gap-x-2 min-h-[450px] pr-2">
        <BarVertical title={t("modules.title_planning")}>
          <ErrorBoundary>
            <MenuItemsModule items={childrenMenu} />
          </ErrorBoundary>
        </BarVertical>
        <div className="overflow-x-hidden">
          <ErrorBoundary>
            <Routers views={childrenMenu} />
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  );
};

export default Planning;
