import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  permissionsList: [],
};

const configSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setListPermissions(state, action) {
      return { ...state, permissionsList: action.payload };
    }
  },
});

export const {
    setListPermissions,
} = configSlice.actions;
export default configSlice.reducer;
