import React from "react";
import { getAnswers, getStage } from "actions/Monitoring";
import { getCarrier } from "actions/Monitoring";
import { format, parseISO } from "date-fns/esm";
import { getExtencionImg } from "utils";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import TripMap from "components/TripMap";

const ModalDetailTrip = ({ openModal, data, closeModal }) => {
  const [answers, setAnswers] = React.useState([]);
  const [carrier, setCarrier] = React.useState("");
  const [tabActive, setTabActive] = React.useState(0);
  const [status, setStatus] = React.useState();
  const [stage, setStage] = React.useState({});
  const [click, setClick] = React.useState(false);
  const [t] = useTranslation("monitoring");

  const { REACT_APP_BUCKET_BASE_URL, PUBLIC_URL } = process.env;

  const handleQuestions = (idResponseStage) => {
    setClick(false);
    getAnswers(idResponseStage).then((response) => {
      setAnswers(response);
    });
  };

  const handleOpenAll = (e) => {
    setClick(!click);
    e.currentTarget.classList.toggle("exp");
    e.currentTarget.classList.toggle("col");

    const details = document.querySelectorAll("details");

    Array.from(details).forEach(function (obj, idx) {
      if (e.currentTarget.classList.contains("exp")) {
        obj.open = true;
      } else {
        obj.removeAttribute("open");
      }
    });
  };

  React.useEffect(() => {
    if (data) {
      const { operator, id, detailTrip } = data;
      if (operator) {
        getCarrier(operator.idCarrier).then((response) => {
          setCarrier(response.name);
        });
      }
      if (id) {
        getStage(id).then((response) => {
          setStage(response.stage);
        });
      }

      if (detailTrip) {
        let className = "w-full  flex justify-center rounded text-[white] ";
        if (detailTrip[0].status === "Finished") {
          setStatus(className + "bg-green-500");
        } else if (detailTrip[0].status === "InProcess") {
          setStatus(className + "bg-yellow-500");
        } else if (detailTrip[0].status === "Pending") {
          setStatus(className + "bg-red-500");
        } else if (detailTrip[0].status === "NotStarted") {
          setStatus(className + "bg-gray-500");
        } else {
          setStatus(className + "bg-blue-500");
        }
      }
    }
  }, [data]);

  const getActiveClassTab = (indexTab) => {
    if (indexTab === tabActive)
      return `inline-flex p-4 text-[#222222] rounded-t-lg border-b-2 border-[#222222]  group font-[Gravity]`;
    return `inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 group font-[Gravity] `;
  };

  const getLastPosition = () => {
    if (!data?.route) return {};
    if (data?.route.length === 0) return {};
    const { latitude, longitude } = data?.route[data?.route.length - 1];
    return { lat: latitude, lng: longitude };
  };

  const getLastDate = () => {
    if (!data?.route) return "";
    if (!data?.route[0]) return "";
    const { date } = data?.route[data?.route.length - 1];
    if (!date) return "";
    return format(parseISO(date), "dd/MM/yyyy HH:mm");
  };

  const getStartDate = () => {
    if (!data?.route) return "";
    if (!data?.route[0]) return "";
    const { date } = data?.route[0];
    if (!date) return "";
    return format(parseISO(date), "dd/MM/yyyy HH:mm");
  };

  const getPaths = () => {
    if (!data?.route) return [];
    if (!data?.route && data.route.length === 0) return [];
    return data.route.map((item) => {
      const { latitude, longitude } = item;
      return { lat: latitude, lng: longitude };
    });
  };

  return openModal ? (
    <Modal
      openModal={openModal}
      closeModal={() => {
        closeModal();
        setAnswers([]);
        setStage({});
        setClick(false);
      }}
      titleModal="Detalle Viaje"
      hiddenButtonDefault
      size={"xlg"}
    >
      <div className=" pb-4">
        <div className="grid grid-cols-3  border border-slate-200 border-t-0 border-l-0 border-r-0 my-2 pb-1">
          <div className="flex">
            <p className="font-[Gravity] font-bold mr-1">{t("modalDetailTrip.unit_number")}:</p>
            <p className="font-[Gravity]">{data.detailTrip[0].loadId}</p>
          </div>
          <div className="hidden">
            <p className="font-[Gravity] font-bold mr-1">Delivery: </p>
            <p className="font-[Gravity]"> {data.detailTrip[0].delivery}</p>
          </div>
          <div className="flex">
            <p className="font-[Gravity] font-bold mr-1">
              {t("modalDetailTrip.start_trip")}
            </p>
            <p className="font-[Gravity]">{getStartDate()}</p>
          </div>
          <div className="flex">
            <p className="font-[Gravity] font-bold mr-1">
              {t("modalDetailTrip.status")}{" "}
            </p>
            <p className={`font-[Gravity] ${status}`}>
              {data?.status === "Pending"
                ? t("monitoringTrips.pending")
                : data?.status === "Refused"
                  ? t("monitoringTrips.refused")
                  : data?.status === "Complete"
                    ? t("monitoringTrips.complete")
                    : data?.status === "InProcess"
                      ? t("monitoringTrips.in_process")
                      : data?.status === "Accepted"
                        ? t("monitoringTrips.accept")
                        : ""}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-1 border border-slate-200 border-t-0 border-l-0 border-r-0 my-2 pb-1">
          <div className="flex flex-col">
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("monitoringTrips.start_date")}
              </p>
              <p className="font-[Gravity]">
                {format(
                  parseISO(data.detailTrip[0].originPointAppointmentDate),
                  "dd/MM/yyyy HH:mm"
                )}
              </p>
            </div>
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("monitoringTrips.end_date")}
              </p>
              <p className="font-[Gravity]">
                {format(
                  parseISO(data.detailTrip[0].destinationPointAppointmentDate),
                  "dd/MM/yyyy HH:mm"
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("monitoringTrips.start_date_real")}
              </p>
              <p className="font-[Gravity]">
                {data.startTrip && format(
                  parseISO(data?.startTrip),
                  "dd/MM/yyyy HH:mm"
                )}
              </p>
            </div>
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("monitoringTrips.end_date_real")}
              </p>
              <p className="font-[Gravity]">
                {data.endTrip && format(
                  parseISO(data?.endTrip),
                  "dd/MM/yyyy HH:mm"
                ) !== "01/01/0001 00:00" && format(
                  parseISO(data?.endTrip),
                  "dd/MM/yyyy HH:mm"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-1 border border-slate-200 border-t-0 border-l-0 border-r-0 my-2 pb-1">
          <div className="flex flex-col">
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("modalDetailTrip.carrier")}:{" "}
              </p>
              <p className="font-[Gravity]">{carrier}</p>
            </div>
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("modalDetailTrip.last_actualization")}:{" "}
              </p>
              <p className="font-[Gravity]">{getLastDate()}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <p className="font-[Gravity] font-bold mr-1">
                {t("modalDetailTrip.operator")}
              </p>
              <p className="font-[Gravity]">
                {data?.operator?.user?.name} {data?.operator?.user?.lastName}{" "}
                {data?.operator?.user?.secondLastName}
              </p>
            </div>
            <div className="flex flex-row justify-start items-center gap-2">
              <div className="bg-[#FFBB35] rounded-full p-1">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 150 150"
                  fill="#FFBB35"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Iconografia">
                    <g id="icono_alerta">
                      <g id="Group 39">
                        <path
                          id="Vector (Stroke)"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M75.0003 48.0952C76.1838 48.0952 77.1431 49.0546 77.1431 50.2381V91.9047C77.1431 93.0882 76.1838 94.0476 75.0003 94.0476C73.8168 94.0476 72.8574 93.0882 72.8574 91.9047V50.2381C72.8574 49.0546 73.8168 48.0952 75.0003 48.0952Z"
                          fill="black"
                        />
                        <path
                          id="Vector_2"
                          d="M74.9993 125.238C79.6017 125.238 83.3327 121.507 83.3327 116.905C83.3327 112.302 79.6017 108.571 74.9993 108.571C70.397 108.571 66.666 112.302 66.666 116.905C66.666 121.507 70.397 125.238 74.9993 125.238Z"
                          fill="black"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="flex">
                <p className="font-[Gravity] font-bold mr-1">
                  {data.noIncidents} Incidencias reportadas
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="mr-2">
            <a
              href="#black"
              className={getActiveClassTab(0)}
              onClick={(event) => {
                event.preventDefault();
                setTabActive(0);
              }}
            >
              <img
                alt="#"
                src={`${PUBLIC_URL}/icons/encuesta.png`}
                className="objet-cover h-[20px]"
              />
              &nbsp; {t("modalDetailTrip.sondeos")}
            </a>
          </li>
          <li class="mr-2">
            <a
              href="#black"
              className={getActiveClassTab(1)}
              aria-current="page"
              onClick={(event) => {
                event.preventDefault();
                setTabActive(1);
              }}
            >
              <img
                alt="#"
                src={`${PUBLIC_URL}/icons/delivery.png`}
                className="objet-cover  h-[20px]"
              />
              &nbsp; {t("modalDetailTrip.map")}
            </a>
          </li>
        </ul>
      </div>

      {tabActive === 0 ? (
        <div className="grid grid-cols-40/60">
          <div className="flex flex-col border border-r-slate-200 border-t-0 border-b-0 border-l-0 overflow-y-auto max-h-[380px] scrollbarBlack">
            {stage !== null && stage.length > 0
              ? stage.map((response, index) => {
                const { complete, schedule } = response;
                let className = complete
                  ? "#01C851"
                  : schedule !== ""
                    ? "#33B5E7"
                    : "#5C5C5C";

                return (
                  <div className="flex items-center" key={index}>
                    <div className="px-4">
                      <div
                        className={`border rounded-full  w-[20px] h-[20px]`}
                        style={{ backgroundColor: className }}
                      ></div>
                    </div>
                    <div
                      className="shadow shadow-black rounded-lg my-2 w-[300px] h-auto cursor-pointer"
                      onClick={() => handleQuestions(response.idReponseStage)}
                    >
                      <div className="px-2 ">
                        <span className="text-xs font-[Gravity]">
                          {response.schedule}
                        </span>
                      </div>
                      <div className="px-2 grid grid-cols-2">
                        <span className="text-md font-[Gravity] font-bold">
                          #{data.detailTrip[0].delivery}
                        </span>
                        <span className="text-md font-[Gravity] font-bold">
                          {response.name}
                        </span>
                      </div>
                      <div className="px-4">
                        <span className="text-xs font-[Gravity]">
                          {data.detailTrip[0].origin.shPt}-
                          {data.detailTrip[0].destination.shPt}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
          <div className="flex h-[360px] pl-10 overflow-y-auto scrollbarBlack ">
            <ul className="list-decimal w-full">
              <div className="w-full grid justify-items-end">
                {answers !== null && answers.length > 0 ? (
                  <button
                    id="expAll"
                    className="col left rounded-lg bg-[black] font-[Gravity] w-[150px] text-[white] my-1 mx-1"
                    onClick={(event) => handleOpenAll(event)}
                  >
                    {!click
                      ? t("monitoringTrips.see")
                      : t("monitoringTrips.hidden")}
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
              {answers !== null && answers.length > 0 ? (
                answers.map((item) =>
                  item.response ? (
                    <details className=" open:bg-amber-200 duration-300 border border-gray-300">
                      <summary className="bg-inherit px-5 py-3  cursor-pointer font-[Gravity]">
                        {item.question}  {item.isIncident ?  <span className="text-[#222] opacity-[.7]">Incidencia</span> : ''}
                      </summary>
                      <div className="bg-white px-5 py-3 border border-gray-300 text-md font-light">
                        {item.response &&
                          ["jpg", "png", "jpeg"].includes(
                            getExtencionImg(item.response)
                          ) ? (
                          <div className="flex justify-between">
                            <img
                              src={`${REACT_APP_BUCKET_BASE_URL}${item.response}`}
                              className="border rounded-lg h-[80px] w-[100px]"
                              alt=""
                              onClick={() => {
                                const wnd = window.open(
                                  "Evidencia:blank",
                                  "",
                                  "_blank"
                                );
                                let htmlString = `<html><head><title>Evidencia de G-Track ${item.id}</title></head><body style="display: grid; place-items: center;"><img src="${REACT_APP_BUCKET_BASE_URL}${item.response}" /></body> </html>`;
                                wnd.document.write(htmlString);
                              }}
                            />
                            <span>{format(
                              parseISO(item.responsedAt),
                              "yy/MM/dd HH:mm"
                            )}</span>
                          </div>
                        ) : item.response ? (
                          <div className="flex justify-between">
                            <span>{item.response}</span>
                            <span>{
                              format(
                                parseISO(item.responsedAt),
                                "yy/MM/dd HH:mm"
                              )
                            }</span>
                          </div>
                        ) : null}
                      </div>
                    </details>
                  ) : (
                    <div className=" px-5 py-3 border border-gray-300 font-light">
                      <p className="font-[Gravity] text-md">{item.question}</p>
                    </div>
                  )
                )
              ) : (
                <p className="font-[Gravity] text-md mt-10 ml-4">
                  {t("modalDetailTrip.answers")}
                </p>
              )}
            </ul>
          </div>
        </div>
      ) : null}

      {tabActive === 1 ? (
        <div className="h-[360px] w-full bg-red-400 ">
          <TripMap lastLocation={getLastPosition()} paths={getPaths()} />
        </div>
      ) : null}
    </Modal>
  ) : null;
};

export default ModalDetailTrip;
