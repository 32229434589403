import React from "react";

import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import Tooltip from "components/Tooltip";
import { getPlanLoads } from "actions/Planeacion";
import { useSelector } from "react-redux";

const PlanningLoad = ({ view }) => {
  const navigate = useNavigate();
  const [loadPlan, setLoadPlan] = React.useState();
  const { currentProject } = useSelector((state) => state.auth);

  const [t] = useTranslation("planning");
  const { permissions } = view;

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      getPlanLoads(currentProject.id).then((response) => {
        setLoadPlan(response);
      });
    }
  }, [currentProject]);


  return (
    <Grid
      title={t("plan_load.title")}
      columns={[
        {
          Header: t("plan_load.register"),
          accessor: "rows",
          filter: "text",
          width: '15%'
        },
        {
          Header: t("plan_load.status"),
          accessor: "shipmentPlanHeaderStatus.description",
          filter: "text",
          width: '25%'
        },
        {
          Header: t("plan_load.uploaded_by"),
          accessor: "user.name",
          filter: "text",
          width: '25%'
        },
        {
          Header: t("plan_load.project"),
          accessor: "project.name",
          filter: "text",
          width: '25%'
        },
        {
          Header: t("plan_load.date_added"),
          accessor: "createdAt",
          filter: "text",
          Cell: ({ value }) => {
            return format(parseISO(value), "dd/MM/yyyy HH:mm");
          },
          width: '25%'
        },
        {
          Header: "",
          accessor: "none",
          disableFilters: true,
          Cell: ({ row }) => {
            const {
              original: { id,shipmentPlanHeaderStatus },
            } = row;


            return (
              <Tooltip tooltipText={t("plan_load.watch")} placement='top'>
                <button
                  className="font-[Gravite] font-bold bg-[#222222] rounded-full h-[25px] w-[25px] flex items-center justify-center"
                  onClick={() => {
                    let idB64 = Buffer.from(id.toString()).toString("base64");
                    navigate(`../detail`, {
                      state: { idHeader: idB64,statusDetail:shipmentPlanHeaderStatus['description'] },           
                    });
                  }}
                >
                  <svg
                    width="20"
                    height="10"
                    viewBox="0 0 150 91"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M108.965 1.80551L148.195 41.0354C150.602 43.4427 150.602 47.3458 148.195 49.7532L108.965 88.9831C106.557 91.3904 102.654 91.3904 100.247 88.9831C97.8395 86.5757 97.8395 82.6726 100.247 80.2653L128.953 51.5587H0V39.2299H128.953L100.247 10.5233C97.8395 8.11592 97.8395 4.21285 100.247 1.80551C102.654 -0.601836 106.557 -0.601836 108.965 1.80551Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </Tooltip>
            );
          },
          width: '10%'
        },
      ]}
      data={loadPlan ? loadPlan.sort((a, b) => b.id - a.id) : []}
      controlsHeaderHidden={true}
      hiddenDefaultOptions={true}
      write={permissions.write}
    />
  );
};

export default PlanningLoad;
