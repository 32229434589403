import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useValidator } from "react-joi";
import {
  addOrUpdateUser,
  deleteUser,
  getListUserAndRolByProject,
} from "actions/User";
import { getProfilesList } from "actions/Catalogs";
import Grid from "components/Grid";
import InputCustom from "components/InputCustom";
import Joi from "joi";
import Modal from "components/Modal";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { notify } from "utils";
import { parseISO, format } from "date-fns/esm";
import { multiFilter, SelectColumnFilterStatus } from "components/SelectFilterStatus/SelectFilterStatus";

const User = ({ view = {} }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("user");
  const [tc] = useTranslation("catalog");
  const [t2] = useTranslation("global");
  const { userList } = useSelector((state) => state.user);
  const { currentProject, myProjectsList } = useSelector((state) => state.auth);
  const { profiles } = useSelector((state) => state.catalog);
  const { permissions } = view;

  //auxiliares para abrir modales
  const [openModal, setOpenModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [projectsIds, setProjectsIds] = React.useState([]);
  const [isLoad, setIsLoad] = React.useState(false);

  const [id, setId] = React.useState();
  const buttonRef = React.useRef();
  const { state, setData, setExplicitField, validate } = useValidator({
    initialData: {
      email: null,
      name: null,
      lastName: null,
      secondLastName: null,
      idRole: null,
    },
    schema: Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .min(2)
        .max(60)
        .required()
        .messages({
          'string.base': tc("error.type", { type: 'string' }),
          'string.empty': tc("error.empty"),
          'string.email': tc("error.email"),
          'string.min': tc("error.min"),
          'string.max': tc("error.max", { quantity: '60' }),
          'any.required': tc("error.required")
        }),
      name: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': tc("error.empty"),
          'string.base': tc("error.type", { type: 'string' }),
          'string.min': tc("error.min"),
          'string.max': tc("error.max", { quantity: '50' }),
          'any.required': tc("error.required")
        }),
      lastName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': tc("error.empty"),
          'string.base': tc("error.type", { type: 'string' }),
          'string.min': tc("error.min"),
          'string.max': tc("error.max", { quantity: '50' }),
          'any.required': tc("error.required")
        }),
      secondLastName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': tc("error.empty"),
          'string.base': tc("error.type", { type: 'string' }),
          'string.min': tc("error.min"),
          'string.max': tc("error.max", { quantity: '50' }),
          'any.required': tc("error.required")
        }),
      idRole: Joi.number().required(),
    }),
    explicitCheck: {
      email: false,
      name: false,
      lastName: false,
      secondLastName: false,
      idRole: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  //Insert or Edit
  const handleOnSubmit = async () => {
    const { email, name, lastName, secondLastName, idRole } = state.$data;
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      setShowError(true);
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }

    if (projectsIds && projectsIds.length === 0) {
      notify("error", t("error.project"), 1200);
      return;
    }
    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    setShowError(false);
    let isSuccess = await dispatch(
      addOrUpdateUser({
        email,
        name,
        lastName,
        secondLastName,
        id,
        idRole,
        idProject: currentProject.id,
        projects: projectsIds,
      })
    );
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);
    if (isSuccess) {
      setData((old) => ({
        ...old,
        email: null,
        name: null,
        lastName: null,
        secondLastName: null,
        idRole: null,
      }));
      setId(null);
      setProjectsIds([]);
      setOpenModal(false);
    }
  };

  //deleteUser
  const handleDelete = async (idUser) => {
    Swal.fire({
      title: t2("button.title"),
      text: t("delete"),
      icon: "error",
      confirmButtonText: t2("button.confirm"),
      cancelButtonText: t2("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && idUser) {
        dispatch(deleteUser(idUser, currentProject.id));
      }
    });
  };

  const handleActive = async (data) => {
    let title = t("active.title_enable");
    let description = t("active.enable");

    if (data.user["active"]) {
      title = t("active.title_disable");
      description = t("active.disable");
    }

    Swal.fire({
      title: title,
      text: description,
      icon: "warning",
      confirmButtonText: t("active.btn_continue"),
      cancelButtonText: t("active.btn_cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && data) {
        let active = true;
        const { user } = data;
        const { id, name, email, lastName, secondLastName } = user;
        if (data.user["active"]) {
          active = false;
        }
        await dispatch(
          addOrUpdateUser({
            id,
            email,
            name,
            lastName,
            active,
            secondLastName,
            idProject: currentProject.id,
          })
        );
      }
    });
  };

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      dispatch(getListUserAndRolByProject(currentProject.id));
    }
    dispatch(getProfilesList());
  }, [dispatch, currentProject]);


  function filterDate(rows, columnIds, filterValue) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
        String(format(parseISO(row.original.user['updatedAt']), "dd/MM/yyyy HH:mm")).includes(filterValue),
      );
  }

  return (
    <>
      <Grid
        title={t("users.title")}
        columns={[
          {
            Header: "Id",
            accessor: "user.id",
            filter: "text",
            width: '12%'
          },
          {
            Header: t("users.email"),
            accessor: "user.email",
            filter: "text",
            width: '12%'
          },
          {
            Header: t("users.name"),
            accessor: "user.name",
            filter: "text",
            width: '12%'
          },
          {
            Header: t("users.last_name"),
            accessor: "user.lastName",
            filter: "text",
            width: '12%'
          },
          {
            Header: t("users.second_last_name"),
            accessor: "user.secondLastName",
            filter: "text",
            width: '12%'
          },

          {
            Header: "Rol",
            accessor: "role.name",
            filter: "text",
            width: '12%'
          },
          {
            Header: "Status",
            accessor: "user.active",
            Filter:SelectColumnFilterStatus,
            filter: multiFilter,
            Cell: ({ value }) => {
              return value ? (
                <div className="flex items-center justify-center">
                  <div className="h-2 w-2 bg-green-500 rounded-full"></div>
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <div className="h-2 w-2 bg-red-500 rounded-full"></div>
                </div>
              );
            },
            width: '12%'
          },
          {
            Header: t("users.updated_at"),
            accessor: "user.updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '10%'
          },
        ]}
        data={userList ? userList.filter((item) => item.idUser !== 1) : []}
        handleAdd={() => {
          setOpenModal(true);
          setProjectsIds([currentProject.id]);
        }}
        handleEdit={(row) => {
          const {
            user: { id, email, name, lastName, secondLastName },
            idRole,
            projects,
          } = row;
          setId(id);
          setData((old) => ({
            ...old,
            email,
            name,
            lastName,
            secondLastName,
            idRole,
          }));
          setProjectsIds(projects);
          setOpenModal(true);
        }}
        handleDelete={({ user: { id } }) => handleDelete(id)}
        write={permissions.write}
        AdditionalOptionsRows={({ row }) => {
          const { original } = row;
          return permissions?.write ? (
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-gray-200 hover:text-gray-900"
              onClick={() => {
                handleActive(original);
              }}
            >
              {original.user.active ? t("disable") : t("enable")}
            </button>
          ) : null;
        }}
      />

      {/*Main Modal */}
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setData((old) => ({
              ...old,
              email: null,
              name: null,
              lastName: null,
              secondLastName: null,
              idRole: null,
            }));
            setShowError(false);
            setOpenModal(false);
            setId(null);
            setProjectsIds([]);
          }}
          titleModal={t("users.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("title_info")}
          </h4>
          <div className="flex flex-wrap ">
            <InputCustom
              type={"text"}
              labelText={t("users.email")}
              errorMessage={
                showError && state.$errors.email.length > 0
                  ? state.$errors.email[0].$message
                  : null
              }
              onBlur={() => setExplicitField("email", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  email: e.target.value,
                }));
              }}
              value={state.$data.email}
              key="userEmail"
              disabled={id ? true : false}
            />
            <InputCustom
              type={"text"}
              labelText={t("users.name")}
              errorMessage={
                showError && state.$errors.name.length > 0
                  ? state.$errors.name[0].$message
                  : null
              }
              onBlur={() => setExplicitField("name", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name}
              key="userName"
            />
            <InputCustom
              type={"text"}
              labelText={t("users.last_name")}
              errorMessage={
                showError && state.$errors.lastName.length > 0
                  ? state.$errors.lastName[0].$message
                  : null
              }
              onBlur={() => setExplicitField("lastName", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  lastName: e.target.value,
                }));
              }}
              value={state.$data.lastName}
              key="userLastName"
            />
            <InputCustom
              type={"text"}
              labelText={t("users.second_last_name")}
              errorMessage={
                showError && state.$errors.secondLastName.length > 0
                  ? state.$errors.secondLastName[0].$message
                  : null
              }
              onBlur={() => setExplicitField("secondLastName", true)}
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  secondLastName: e.target.value,
                }));
              }}
              value={state.$data.secondLastName}
              key="userSecondLastName"
            />
            <InputCustom
              type={"select"}
              labelText={t("users.profiles")}
              errorMessage={
                showError && state.$errors.idRole.length > 0
                  ? t("error.general")
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idRole: e.target.value,
                }));
              }}
              options={
                profiles &&
                profiles
                  .filter((item) => item.id !== 2)
                  .map((item) => ({
                    value: item.id,
                    text: item.name,
                  }))
              }
              value={state.$data.idRole}
              onBlur={() => setExplicitField("idRole", true)}
              key="profile"
              disabled={id ? true : false}
            />
          </div>
          <h4 className="font-[Gravity] font-bold">{t("title_projects")}</h4>
          <div className="grid grid-cols-4 gap-1 m-1">
            {myProjectsList.map((item, index) => {
              return (
                <div key={`div_${index}`}>
                  <label htmlFor={`check_${item.id}`}>
                    <input
                      type={"checkbox"}
                      id={`check_${item.id}`}
                      title={item.name}
                      onChange={({ target: { checked } }) => {
                        setProjectsIds((oldValue) => {
                          if (checked) {
                            return [...oldValue, item.id];
                          } else {
                            return oldValue.filter(
                              (value) => value !== item.id
                            );
                          }
                        });
                      }}
                      checked={
                        projectsIds ? projectsIds.includes(item.id) : false
                      }
                    />
                    &nbsp;
                    {item.shortName}
                  </label>
                </div>
              );
            })}
          </div>
        </Modal>
      ) : null}
      {/*End Main Modal */}
    </>
  );
};

export default User;
