import React from "react";
import Grid from "components/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import toast from "react-hot-toast";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { notify } from "utils";

import { getPlanDetailByIdHeaderLoad, verifyPlan } from "actions/Planeacion";
import Modal from "components/Modal";


const PlanningDetail = ({ view }) => {
  const [t] = useTranslation("planning");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loadPlan, setLoadPlan] = React.useState();
  const [foundError, setFoundError] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [erros, setErrors] = React.useState("");
  const { permissions } = view;
  const buttonRef = React.useRef();
  const [isLoad, setIsLoad] = React.useState(false);



  const { PUBLIC_URL } = process.env;
  React.useState(() => {
    if (state && state.idHeader) {
      const { idHeader } = state;

      let buff = Buffer.from(idHeader, "base64");
      let idClear = buff.toString("utf-8");
      getPlanDetailByIdHeaderLoad(idClear).then((response) => {
        let countError = response.detailFile.filter((item) => item.errorsFound);
        if (countError && countError.length) {
          setFoundError(true);
        }
        setLoadPlan(response.detailFile);
      });
    }
  }, [state]);


  const handleConfirmPlan = async () => {
    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    if (permissions.write) {
      const { idHeader } = state;
      let buff = Buffer.from(idHeader, "base64");
      let idClear = buff.toString("utf-8");
      notify("success", t("planningDetail.authorized"), 1200);
      await verifyPlan(idClear).then((response) => {
        if (response) {
          setStatus(true);
          toast(
            (e) => (
              <span className="font-[Gravity] text-[#222222]">
                {t("planningDetail.finish")}
                <button
                  onClick={() => {
                    toast.dismiss(e.id);
                    navigate(`../load-plan-assignment`, {
                      state: { idHeader: buff },
                    });
                  }}
                  className={
                    "font-[Gravity] border-1 border-[#222222] text-blue-700 ml-2"
                  }
                >
                  {t("planningDetail.assign")}
                </button>
              </span>
            ),
            {
              duration: 10000,
              position: "top-right",
              icon: "✅",
            }
          );
        }
      });

    }
    setIsLoad(false);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;

  };



  return (
    <>
      <Grid
        title={t("planningDetail.title")}
        columns={[
          {
            Header: t("planningDetail.validate"),
            accessor: "outcome",
            filter: "text",
            disableFilters: true,
            Cell: ({ row, value }) => {
              const {
                original: { errorsFound },
              } = row;
              if (!errorsFound) return <div className="h-4 w-4 bg-green-500 rounded-full"></div>;
              return (
                <button
                  onClick={() => {
                    setErrors(value);
                    setOpenModal(true);
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 150 150"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="75" cy="75" r="75" fill={"#AE0000"} />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M110.763 103.847V62.1158L80.9554 32.3081H51.1477C44.5628 32.3081 39.2246 37.6462 39.2246 44.2312V103.847C39.2246 110.432 44.5628 115.77 51.1477 115.77H98.84C105.425 115.77 110.763 110.432 110.763 103.847Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.9141 44.2308C36.9141 36.3713 43.2854 30 51.1448 30H80.9525C81.5646 30 82.1515 30.2431 82.5843 30.6759L112.392 60.4836C112.825 60.9164 113.068 61.5033 113.068 62.1154V103.846C113.068 111.706 106.697 118.077 98.8371 118.077H51.1448C43.2854 118.077 36.9141 111.706 36.9141 103.846V44.2308ZM51.1448 34.6154C45.8344 34.6154 41.5294 38.9203 41.5294 44.2308V103.846C41.5294 109.157 45.8344 113.462 51.1448 113.462H98.8371C104.148 113.462 108.453 109.157 108.453 103.846V63.0713L79.9966 34.6154H51.1448Z"
                      fill="#222222"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M48.8418 74.0389C48.8418 72.7644 49.875 71.7312 51.1495 71.7312H80.9572C82.2317 71.7312 83.2649 72.7644 83.2649 74.0389C83.2649 75.3134 82.2317 76.3466 80.9572 76.3466H51.1495C49.875 76.3466 48.8418 75.3134 48.8418 74.0389Z"
                      fill="#222222"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M48.8418 85.9617C48.8418 84.6872 49.875 83.6541 51.1495 83.6541H92.8803C94.1548 83.6541 95.188 84.6872 95.188 85.9617C95.188 87.2362 94.1548 88.2694 92.8803 88.2694H51.1495C49.875 88.2694 48.8418 87.2362 48.8418 85.9617Z"
                      fill="#222222"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M48.8418 97.8846C48.8418 96.6101 49.875 95.5769 51.1495 95.5769H69.0341C70.3086 95.5769 71.3418 96.6101 71.3418 97.8846C71.3418 99.1591 70.3086 100.192 69.0341 100.192H51.1495C49.875 100.192 48.8418 99.1591 48.8418 97.8846Z"
                      fill="#222222"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M80.9512 32.3081V50.1927C80.9512 56.7777 86.2893 62.1158 92.8742 62.1158H110.759"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M80.9483 30C82.2228 30 83.256 31.0332 83.256 32.3077V50.1923C83.256 55.5027 87.561 59.8077 92.8714 59.8077H110.756C112.031 59.8077 113.064 60.8409 113.064 62.1154C113.064 63.3899 112.031 64.4231 110.756 64.4231H92.8714C85.012 64.4231 78.6406 58.0517 78.6406 50.1923V32.3077C78.6406 31.0332 79.6738 30 80.9483 30Z"
                      fill="#222222"
                    />
                  </svg>
                </button>
              );
            },
          },
          {
            Header: t("planningDetail.carrier"),
            accessor: "carrierString",
            filter: "text",
          },
          {
            Header: t("planningDetail.id_load"),
            accessor: "loadId",
            filter: "text",
          },
          {
            Header: t("planningDetail.delivery"),
            accessor: "delivery",
            filter: "text",
          },
          {
            Header: "Shpt",
            accessor: "shPt",
            filter: "text",
          },
          {
            Header: "ShipTo",
            accessor: "shipTo",
            filter: "text",
          },
          {
            Header: "Dv",
            accessor: "dv",
            filter: "text",
          },
          {
            Header: t("planningDetail.pu_appointment_day"),
            accessor: "pU_AppointmentDay",
            filter: "text",
            Cell: ({ value }) => {
              return format(parseISO(value), "dd/MM/yyyy");
            },
          },
          {
            Header: t("planningDetail.pu_appointment_time"),
            accessor: "pU_AppointmentTime",
            filter: "text",
            Cell: ({ value }) => {
              return value;
            },
          },
          {
            Header: t("planningDetail.appointment_day"),
            accessor: "d_AppointmentDay",
            filter: "text",
            Cell: ({ value }) => {
              return format(parseISO(value), "dd/MM/yyyy");
            },
          },
          {
            Header: t("planningDetail.appointment_time"),
            accessor: "d_AppointmentTime",
            filter: "text",
            Cell: ({ value }) => {
              return value;
            },
          },
        ]}
        data={loadPlan || []}
        controlsHeaderHidden={true}
        hiddenDefaultOptions={true}
        controlersHeaderComponents={() => {
          if (foundError) return null
          if (state.statusDetail === ('Authorized' || 'ErrorsFound')) return null
          if (status) return null
          return (
            <button
              type="button"
              className={`text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ${foundError
                ? ""
                : " dark:hover:bg-gray-600 dark:hover:text-white hover:bg-gray-200 hover:text-gray-900 "
                } `}
              data-modal-toggle="defaultModal"
              onClick={() => handleConfirmPlan()}
              disabled={foundError}
              ref={buttonRef}
            >
              {isLoad ? (
                <svg
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) :
                (<img
                  src={`${PUBLIC_URL}/icons/btns/btn_save.svg`}
                  width={35}
                  height={35}
                  alt="#"
                />)}
            </button>
          );
        }}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={true}
          hiddenButtonDefault={true}
          closeModal={() => {
            setErrors("");
            setOpenModal(false);
          }}
        >
          <div className="flex flex-col">
            {erros.split(",").map((item) => (
              <span className="font-[Gravity]">{item}</span>
            ))}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default PlanningDetail;
