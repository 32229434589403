const IconNotificationOff = ({ width, height, color, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill={fill} />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-2232 -769)"
          fill={fill}
        />
        <g id="icono_notificacion_off">
          <g id="Group 15">
            <g id="GTAP_icono_bell_Black">
              <path
                id="Vector"
                d="M115.442 114.286H28.7362C21.0856 114.286 14.2852 108.132 14.2852 99.3407C14.2852 96.7033 15.1352 94.0659 15.9853 92.3077C24.4859 78.2418 28.7362 61.5385 28.7362 44.8352V35.1648C28.7362 15.8242 44.0372 0 62.7385 0H79.7397C98.441 0 113.742 15.8242 113.742 35.1648V44.8352C113.742 61.5385 117.992 78.2418 126.493 92.3077C130.743 99.3407 128.193 108.132 121.393 112.527C120.543 113.407 117.992 114.286 115.442 114.286Z"
                fill={color}
              />
              <path
                id="Vector (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.5596 125.837C42.0699 124.568 44.3227 124.764 45.5913 126.274C53.0232 135.122 61.215 139.286 71.4281 139.286C81.6412 139.286 89.833 135.122 97.2649 126.274C98.5335 124.764 100.786 124.568 102.297 125.837C103.807 127.105 104.003 129.358 102.734 130.869C94.166 141.069 84.0721 146.429 71.4281 146.429C58.7841 146.429 48.6902 141.069 40.122 130.869C38.8533 129.358 39.0492 127.105 40.5596 125.837Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconNotificationOff;
