import api from "api";
import { ResponseTransform } from "utils";

export const getTripsActive = async (idProject, queryParameters) => {
  let endPoint = `/api/project/${idProject}/trips-active-routes`;
  if (queryParameters) {
    endPoint += `?${queryParameters}`;
  }
  const response = await api.get(endPoint).then(ResponseTransform);
  if (response && response.length > 0) {
    let tripsActives = response.map((item) => {
      const { trip, tripRoutes } = item;
      let lastPosition = tripRoutes[tripRoutes.length - 1];

      return {
        trip,
        tripRoutes,
        currentLocation: {
          lat: lastPosition.latitude,
          lng: lastPosition.longitude,
        },
      };
    });

    return tripsActives;
  }
  return null;
};
