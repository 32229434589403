import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import InputCustom from "components/InputCustom";
import MapGeocercas from "components/MapGeocercas";
import Modal from "components/Modal";
import { notify, truncateText } from "utils";
import { getGeofences, deleteGeofences, addOrUpdate } from "actions/Geofences";
import Swal from "sweetalert2";
import { parseISO, format } from "date-fns/esm";
import { filterDate } from "components/DateCatalogs/filterDate";
const Geocercas = ({ view }) => {
  const [t] = useTranslation("config");
  const [tg] = useTranslation("global");
  const { currentProject } = useSelector((state) => state.auth);
  const [frmValues, setFrmValues] = React.useState({ radio: 1 });
  const [openModal, setOpenModal] = React.useState(false);
  const { permissions } = view;
  const [center, setCenter] = React.useState({
    lat: 18.1932342,
    lng: -101.9061163,
  });
  const [defaultPosition, setDefaultPosition] = React.useState({
    lat: 18.1932342,
    lng: -101.9061163,
  });
  const [zoom] = React.useState(6);
  const [geofences, setGeofences] = React.useState([]);
  const [isLoad, setIsLoad] = React.useState(false);


  const buttonRef = React.useRef();

  React.useEffect(() => {
    if (currentProject && currentProject?.id) {
      getGeofences(currentProject.id).then((response) => {
        setGeofences(response);
      });
    }
  }, [currentProject]);

  React.useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      }
    };

    getLocation();
  }, []);

  const showPosition = (position) => {
    let a = position.coords.latitude;
    let b = position.coords.longitude;
    setCenter({ lat: a, lng: b });
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed && id) {
        deleteGeofences(currentProject.id, id).then(() => {
          getGeofences(currentProject.id).then((response) => {
            setGeofences(response);
          });
        });
      }
    });
  };

  const handleAdd = async () => {
    if (permissions.write) {
      if (!frmValues.radio) {
        notify("error", "Ingrese un radio valido ");
        return;
      }

      if (!frmValues.name) {
        notify("error", t("geofences.empty"));
        return;
      }

      if (frmValues.name.length > 20) {
        notify("error", t("geofences.max"));
        return;
      }

      if (!frmValues.lat) {
        notify("error", "Aun no existe una latitud valida. Configura el mapa");
        return;
      }
      if (!frmValues.lng) {
        notify("error", "Aun no existe una longitud valida. Configura el mapa");
        return;
      }

      setIsLoad(true);
      if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
      let dto = {
        name: frmValues.name,
        latitude: frmValues.lat,
        longitude: frmValues.lng,
        radio: frmValues.radio * 1000,
        idProject: currentProject.id,
        id: frmValues.id,
      };

      let response = await addOrUpdate(dto);
      if (buttonRef && buttonRef?.current) buttonRef.current.disabled = false;

      if (response) {
        getGeofences(currentProject.id).then((response) => {
          setGeofences(response);
        });
        setFrmValues({ radio: 1 });
        setOpenModal(false);
      }
      setIsLoad(false);
    }
  };

  return (
    <div>
      <Grid
        title={t("geofences.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("geofences.geofence_name"),
            accessor: "name",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("geofences.latitude"),
            accessor: "latitude",
            filter: "text",
            Cell: ({ value }) => {
              if (!value) return ""
              return truncateText(value.toString(), 8);
            },
            width: '15%'
          },

          {
            Header: t("geofences.longitude"),
            accessor: "longitude",
            filter: "text",
            Cell: ({ value }) => {
              if (!value) return ""
              return truncateText(value.toString(), 8);
            },
            width: '15%'
          },
          {
            Header: t("geofences.radius"),
            accessor: "radio",
            filter: "text",
            Cell: ({ value }) => {
              return `${value} m.`;
            },
            width: '15%'
          },
          {
            Header: t("geofences.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '15%'
          },
        ]}
        data={
          geofences && typeof geofences === "object" && geofences.length > 0 ? geofences : []
        }
        handleEdit={(row) => {
          const { latitude, longitude, radio, ...rest } = row;
          setDefaultPosition({ lat: latitude, lng: longitude });
          setFrmValues({
            lat: latitude,
            radio: parseInt(radio) / 1000,
            lng: longitude,
            ...rest,
          });
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={openModal}
          titleModal={t("geofences.title")}
          closeModal={() => {
            setFrmValues({});
            setOpenModal(false);
            setFrmValues({ radio: 1 });
          }}
          size={"lg"}
          onSubmit={handleAdd}
          iconEdit={frmValues.id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <div className="grid grid-cols-1 gap-2">
            <InputCustom
              labelText={t("geofences.geofence_name")}
              type={"text"}
              onChange={({ target: { value } }) => {
                setFrmValues({ ...frmValues, name: value });
              }}
              value={frmValues.name}
            />

            <div className="flex flex-row w-full gap-x-4">
              <button
                onClick={() => {
                  setFrmValues({ ...frmValues, radio: 1 });
                }}
                className={"bg-[#222222] text-[#ffffff] py-2 px-2 rounded"}
              >
                {t("geofences.btn")}
              </button>
              <div className="flex flex-col w-full">
                <input
                  type={"range"}
                  min={1}
                  max={20}
                  onChange={({ target: { value } }) => {
                    setFrmValues({ ...frmValues, radio: parseInt(value) });
                  }}
                  value={frmValues.radio}
                  color={"#222222"}
                />
                <label className="font-[Gravity] font-bold">
                  {t("geofences.radius")} {frmValues.radio} Km &nbsp;
                </label>
              </div>
            </div>
            <div className="w-full h-[350px]">
              <MapGeocercas
                zoom={zoom}
                center={center}
                radius={frmValues.radio * 1000}
                defaultPosition={defaultPosition}
                setPosition={setFrmValues}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Geocercas;
