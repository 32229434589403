import { Routes, Route, Navigate } from "react-router-dom";
import CategoriesProduct from "./Modules/CategoriesProduct";
import Carrier from "./Modules/Carrier";
import CedisDestination from "./Modules/CedisDestination";
import CedisOrigin from "./Modules/CedisOrigin";
import Geocercas from "./Modules/Geocercas";
import Operator from "./Modules/Operator";
import Sondeos from "./Modules/Sondeo";
import Stages from "./Modules/Stages";
import User from "./Modules/User";
import Stage from "./Modules/Stage";
import RestrictionRoute from "routes/RestrictionRoute";
import Question from "./Modules/Question";



const Routers = ({ views }) => {
  return (
    <Routes>
      <Route
        path="polls"
        element={
          <RestrictionRoute views={views}>
            <Sondeos />
          </RestrictionRoute>
        }
      />
      <Route
        path="stages"
        element={
          <RestrictionRoute views={views}>
            <Stages />
          </RestrictionRoute>
        }
      />
      <Route
        path="geocercas"
        element={
          <RestrictionRoute views={views}>
            <Geocercas />
          </RestrictionRoute>
        }
      />
      <Route
        path="users"
        element={
          <RestrictionRoute views={views}>
            <User />
          </RestrictionRoute>
        }
      />
      <Route
        path="/categories-products"
        element={
          <RestrictionRoute views={views}>
            <CategoriesProduct />
          </RestrictionRoute>
        }
      />
      <Route
        path="/cedis-destination"
        element={
          <RestrictionRoute views={views}>
            <CedisDestination />
          </RestrictionRoute>
        }
      />
      <Route
        path="/cedis-origin"
        element={
          <RestrictionRoute views={views}>
            <CedisOrigin />
          </RestrictionRoute>
        }
      />
      <Route
        path="/operator"
        element={
          <RestrictionRoute views={views}>
            <Operator />
          </RestrictionRoute>
        }
      />
      <Route
        path="/carrier"
        element={
          <RestrictionRoute views={views}>
            <Carrier />
          </RestrictionRoute>
        }
      />

      <Route
        path="/stage"
        element={
          <RestrictionRoute views={views}>
            <Stage />
          </RestrictionRoute>
        }
      />
      <Route
        path="/poll"
        element={
          <RestrictionRoute views={views}>
            <Sondeos />
          </RestrictionRoute>
        }
      />
      <Route
        path="/question"
        element={
          <RestrictionRoute views={views}>
            <Question />
          </RestrictionRoute>
        }
      />
      <Route path="*" element={<Navigate to="../users" replace />} />
    </Routes>
  );
};

export default Routers;
