import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function RestrictionRoute({ children, views }) {
 
  const location = useLocation();

  const clearPath = (path) => {
    path = path.replace(path.charAt(0), "");
    return path;
  };

  const currentView = React.useMemo(() => {
    const { pathname } = location;

    //limipiamos el path para quitar el primer "/"
    let currentPath = clearPath(pathname);  
    if (!views) return null;
    //buscamos la vista actual con el path
    let view = views.find((item) => item.url.includes(currentPath));
    if (view) {
      //obtenemos actions y lo quitamos del objeto restante para poder ser manipulado
      const { actions, ...rest } = view;
      let actionsObj = {};
      if (actions) {
        //convetumos el array de actions en objeto de keys para mejor interpretación
        //{read: true, write: true}
        actionsObj = actions.reduce((a, b) => ({ ...a, [b.name]: true }), {});
      }
      //retornamos el objeto armado
      return { ...rest, permissions: actionsObj };
    }
    return null;
  }, [location, views]);
  return currentView ? (
    React.cloneElement(children, { view: currentView })
  ) : (
    <Navigate to="/" />
  );
}
