const IconMonitor = ({ width, height, bg, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 150"
      fill={bg}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill={bg} />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-3012 -769)"
          fill={bg}
        />
        <g id="icono_monitor">
          <g id="Group">
            <path
              id="Vector"
              d="M0 11.8802C0.90065 9.17822 3.6026 9.17822 6.30455 9.17822C23.4169 9.17822 39.6286 9.17822 56.7409 9.17822C57.6416 9.17822 57.6416 9.17822 58.5422 9.17822C51.3371 21.7873 52.2377 35.2971 62.1448 47.0055C72.052 58.714 85.5617 61.4159 100.873 57.8133C101.773 62.3166 105.376 65.0185 108.979 68.6211C115.283 74.9257 122.488 81.2302 129.694 88.4354C132.396 91.1374 135.998 92.9387 139.601 93.8393C92.7669 93.8393 46.8338 93.8393 0 93.8393C0 65.9192 0 38.8997 0 11.8802Z"
              fill={fill}
            />
            <path
              id="Vector_2"
              d="M0 101.045C47.7344 101.045 94.5682 101.045 142.303 101.045C142.303 101.945 142.303 101.945 142.303 102.846C142.303 106.449 142.303 110.051 142.303 113.654C142.303 117.256 140.501 119.058 136.899 119.058C94.5682 119.058 52.2377 119.058 9.90715 119.058C9.0065 119.058 7.2052 119.058 6.30455 119.058C3.6026 119.058 1.8013 118.157 0 116.356C0 110.952 0 106.449 0 101.045Z"
              fill={fill}
            />
            <path
              id="Vector_3"
              d="M39.6295 144.276C38.7289 144.276 38.7289 143.375 39.6295 144.276C36.9276 142.475 36.0269 140.673 36.9276 138.872C37.8282 137.071 38.7289 135.269 41.4308 135.269C45.0334 135.269 49.5367 135.269 53.1393 135.269H54.0399C54.0399 132.567 54.0399 129.865 54.0399 126.263C65.7484 126.263 77.4568 126.263 89.1653 126.263C89.1653 128.965 89.1653 131.667 89.1653 135.269C92.7679 135.269 97.2711 135.269 100.874 135.269C101.774 135.269 101.774 135.269 102.675 135.269C104.476 135.269 106.278 137.071 106.278 138.872C106.278 140.673 105.377 142.475 104.476 143.375C104.476 143.375 103.576 143.375 103.576 144.276C81.9601 144.276 61.2451 144.276 39.6295 144.276Z"
              fill={fill}
            />
            <path
              id="Vector_4"
              d="M110.78 51.5089C108.979 49.7076 107.177 48.8069 105.376 47.0056C104.475 46.105 104.475 47.0056 103.575 47.0056C93.6677 52.4095 84.6612 52.4095 74.754 47.0056C65.7475 41.6017 60.3436 30.7939 63.0456 19.9861C65.7475 10.079 74.754 1.97315 85.5618 0.171845C102.674 -1.62945 116.184 10.9796 115.283 26.2907C115.283 30.7939 113.482 34.3965 110.78 37.9991C109.879 38.8998 109.879 39.8004 110.78 40.7011C112.581 42.5024 114.383 44.3037 116.184 45.2043C119.787 43.403 120.687 43.403 123.389 46.105C131.495 53.3102 139.601 61.416 146.806 68.6212C150.409 72.2238 151.309 76.7271 147.707 80.3297C145.005 83.9323 139.601 84.8329 135.998 82.131C135.098 82.131 135.098 81.2303 134.197 80.3297C126.091 73.1245 117.985 65.0186 109.879 57.8134C108.979 56.0121 108.979 55.1115 110.78 51.5089Z"
              fill={fill}
            />
            <path
              id="Vector_5"
              d="M142.303 52.4094C138.701 48.8068 135.999 46.1049 132.396 43.4029C129.694 40.701 126.992 37.999 122.489 37.0984C126.092 27.1912 125.191 18.1847 119.787 9.17822C121.588 9.17822 123.39 9.17822 125.191 9.17822C129.694 9.17822 133.297 9.17822 137.8 9.17822C140.502 9.17822 142.303 10.9795 142.303 13.6815C142.303 25.3899 142.303 38.8997 142.303 52.4094C142.303 51.5088 142.303 51.5088 142.303 52.4094Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconMonitor;
