import toast from "react-hot-toast";
import { Buffer } from "buffer";

export const ResponseTransform = (response) => {
  const { data, ok, status, config } = response;
  const { method } = config;
  let message = null;

  if (status === 401 && showDefaultMessage(config.url)) {
    window.location = "../not-authorized";
    return;
  }
  if (ok) {
    if (showDefaultMessage(config.url)) {
      message = getMessage(status, method);
      if (typeof message === "string") {
        notify("success", message, 3000);
      }
    }
    return data ? data : true;
  }

  if (typeof data === "string") {
    message = data;
  } else {
    message = getMessage(status);
  }
  notify("error", message || getMessage(null), 3000);
  return null;
};

const showDefaultMessage = (url) => {
  const not_show_messages = [
    "api/shipmentplan/upload",
    "authorized-file",
    "api/user/change-password/",
    "api/user/recovery-password/",
    "api/auth/access-token",
    "api/shipmentplan/assign-trip/",
  ];
  const isfind = not_show_messages.find((item) => {
    return url.indexOf(item) > -1;
  });
  return isfind ? false : true;
};

const getMessage = (CODE_STATUS, METHOD) => {
  const language = localStorage.getItem("language") || "es";
  const isSpanish = language === "es";
  switch (CODE_STATUS) {
    case 201: {
      return isSpanish
        ? "El recurso se creo correctamente"
        : "The resource was created correctly";
    }
    case 204: {
      if (METHOD === "delete") {
        return isSpanish
          ? "El recurso se elimino correctamente"
          : "The resource was correctly deleted";
      } else {
        return isSpanish
          ? "El recurso se modifico correctamente"
          : "The resource was correctly modified";
      }
    }
    case 404: {
      return isSpanish
        ? "El recurso no se encontró"
        : "The resource was not found";
    }
    case 200: {
      return null;
    }
    default: {
      return isSpanish ? "Error desconocido" : "Unknown error";
    }
  }
};

export const notify = (type, message, duration) => {
  switch (type) {
    case "success": {
      toast.success(message, {
        duration,
        position: "top-right",
      });
      return;
    }
    case "error": {
      toast.error(message, {
        duration,
        position: "top-right",
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "white",
          backgroundColor: "#FF3447",
        },
      });
      return;
    }
    case "warning": {
      toast.success(message, {
        duration,
        position: "top-right",
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "white",
          backgroundColor: "#F7A514",
          opacity: 0.8,
        },
        icon: "⚠",
      });
      return;
    }
    default: {
    }
  }
};

export const truncateText = (text, length) => {
  if (text.length > length) return text.substring(0, length) + "...";
  return text;
};

export const getObjectAddressGeocoder = async ({ lat, lng }) => {
  const { REACT_APP_API_KEY_MAPS } = process.env;
  let addressDTO = {};

  let url_api = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${REACT_APP_API_KEY_MAPS}`;
  let response = await fetch(url_api)
    .then((res) => res.json())
    .catch((error) => {
      console.log(error);
      return null;
    });
  if (response) {
    let address = response.results[0].address_components;
    
    for (const iterator of address) {
      if (iterator.types.includes("country")) {
        addressDTO.country = iterator.long_name;
      }
      if (iterator.types.includes("administrative_area_level_1")) {
        addressDTO.state = iterator.long_name;
      }
      if (iterator.types.includes("locality")) {
        addressDTO.city = iterator.long_name;
      }
      if (iterator.types.includes("sublocality")) {
        addressDTO.cologne = iterator.long_name;
      }else if(!iterator.types.includes("sublocality") && (!addressDTO.cologne || addressDTO.cologne.length === 0)){
        addressDTO.cologne = "";
      }
      if (iterator.types.includes("route")) {
        addressDTO.street = iterator.long_name;
      }else if(!iterator.types.includes("route") && (!addressDTO.street || addressDTO.street.length === 0)){
        addressDTO.street = "";
      }
      if (iterator.types.includes("postal_code")) {      
        addressDTO.postalCode = iterator.long_name;
      }else if(!iterator.types.includes("postal_code") && (!addressDTO.postalCode || addressDTO.postalCode.length === 0)){
        addressDTO.postalCode = "";
      }
      if (iterator.types.includes("street_number")) {
        addressDTO.exteriorNumber = iterator.long_name;
      }else if(!iterator.types.includes("street_number") && (!addressDTO.exteriorNumber || addressDTO.exteriorNumber.length === 0)){
        addressDTO.exteriorNumber = "";
      }
    }
    return addressDTO;
  }

  return {};
};

export const getCoordinates = async (address) => {
  const { REACT_APP_API_KEY_MAPS } = process.env;

  let endPoint =
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    address +
    "&key=" +
    REACT_APP_API_KEY_MAPS;
  let response = await fetch(endPoint)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.results[0]) {
        const latitude = data.results[0].geometry.location.lat;
        const longitude = data.results[0].geometry.location.lng;
        return {
          lat: latitude,
          lng: longitude,
        };
      }
      return null;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  return response;
};

export const isExpiredToken = (token) => {
  const base64Clear = token.split(".")[1];
  const jsonText = Buffer.from(base64Clear, "base64").toString();
  const { exp } = JSON.parse(jsonText);
  const expired = exp < Date.now() / 1000;
  return expired;
};

export const getExtencionImg = (path) => {
  const ext = path.toLowerCase().split(".").pop();
  if (!ext) return "";
  return ext;
};

export const getCollNameExcel = (n) => {
  let ordA = "a".charCodeAt(0);
  let ordZ = "z".charCodeAt(0);
  let len = ordZ - ordA + 1;

  let s = "";
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s;
};
