const IconAlerta = ({ width, height,  fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill="none" />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-932 -769)"
          fill="none"
        />
        <g id="icono_alerta">
          <g id="Group 39">
            <path
              id="Vector"
              d="M75 8.57141L150 141.905H0L75 8.57141Z"
              fill={fill}
            />
            <path
              id="Vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M75.0003 48.0952C76.1838 48.0952 77.1431 49.0546 77.1431 50.2381V91.9047C77.1431 93.0882 76.1838 94.0476 75.0003 94.0476C73.8168 94.0476 72.8574 93.0882 72.8574 91.9047V50.2381C72.8574 49.0546 73.8168 48.0952 75.0003 48.0952Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M74.9993 125.238C79.6017 125.238 83.3327 121.507 83.3327 116.905C83.3327 112.302 79.6017 108.571 74.9993 108.571C70.397 108.571 66.666 112.302 66.666 116.905C66.666 121.507 70.397 125.238 74.9993 125.238Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconAlerta;
