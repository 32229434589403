import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import InputCustom from "components/InputCustom";
import Joi from "joi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnitList,
  addOrUpdateTruck,
  deleteUnit,
  getUnitTypeList,
  getBrands,
} from "actions/Catalogs";
import { format, parseISO } from "date-fns";
import { notify } from "utils";
import { filterDate } from "components/DateCatalogs/filterDate";

const Units = ({ view, data }) => {
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");

  const dispatch = useDispatch();
  const { unitType, brandsList } = useSelector((state) => state.catalog);
  const { myProjectsList } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = React.useState(false);
  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);
  const { permissions } = view;
  const { state, setData, setExplicitField, validate, reset } = useValidator({
    initialData: {
      serialNumber: null,
      plateNumber: null,
      idTruckType: null,
      year: null,
      mark: null,
      name: null,
      policyNumber: null,
      insuranceExpiration: null,
      engineNumber: null,
      idProject: null,
      economicNumber: null,
    },
    schema: Joi.object({
      serialNumber: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      plateNumber: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      idTruckType: Joi.number().required(),
      year: Joi.number().required(),
      mark: Joi.string().required(),
      name: Joi.string()
        .min(2)
        .max(250)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.base': t("error.alphanumeric"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '250' }),
          'any.required': t("error.required")
        }),
      policyNumber: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.base': t("error.alphanumeric"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      insuranceExpiration: Joi.string().required(),
      engineNumber: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.base': t("error.alphanumeric"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
      idProject: Joi.number().required(),
      economicNumber: Joi.string()
        .min(2)
        .max(10)
        .required()
        .messages({
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.base': t("error.alphanumeric"),
          'string.max': t("error.max", { quantity: '10' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      serialNumber: false,
      plateNumber: false,
      idTruckType: false,
      year: false,
      mark: false,
      name: false,
      policyNumber: false,
      insuranceExpiration: false,
      engineNumber: false,
      idProject: false,
      economicNumber: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  const buttonRef = React.useRef();

  React.useEffect(() => {
    dispatch(getUnitTypeList());
    dispatch(getUnitList());
    dispatch(getBrands());

  }, [dispatch]);

  const handleOnSubmit = async () => {
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }
    const {
      serialNumber,
      plateNumber,
      idTruckType,
      year,
      mark,
      name,
      policyNumber,
      insuranceExpiration,
      engineNumber,
      idProject,
      economicNumber
    } = state.$data;

    if (buttonRef && buttonRef?.current) buttonRef.current.disabled = true;
    let isSuccess = await dispatch(
      addOrUpdateTruck({
        serialNumber,
        plateNumber,
        idTruckType,
        year,
        mark,
        name,
        policyNumber,
        insuranceExpiration,
        engineNumber,
        idProject,
        economicNumber,
        id,
      })
    );
    if (buttonRef && buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);
    if (isSuccess) {
      reset();
      setId(null);
      setOpenModal(false);
    }
  };

  const handleDelete = async (idUnit) => {
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteUnit(idUnit));
      }
    });
  };

  return (
    <>
      <Grid
        title={t("units.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.name"),
            accessor: "name",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.mark"),
            accessor: "mark",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.license_plate_number"),
            accessor: "plateNumber",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.policy_number"),
            accessor: "policyNumber",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.economicNumber"),
            accessor: "economicNumber",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.type_truck"),
            accessor: "typeTruck.name",
            filter: "text",
            width: '15%'
          },
          {
            Header: t("units.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '15%'
          }

        ]}
        data={data || []}
        handleAdd={() => {
          if (permissions.write) {
            setOpenModal(true);
          }
        }}
        handleEdit={(row) => {
          const {
            id,
            serialNumber,
            plateNumber,
            idTruckType,
            year,
            mark,
            name,
            policyNumber,
            insuranceExpiration,
            engineNumber,
            idProject,
            economicNumber
          } = row;
          setId(id);

          setData((old) => ({
            ...old,
            serialNumber,
            plateNumber,
            idTruckType,
            year,
            mark,
            name,
            economicNumber,
            policyNumber,
            insuranceExpiration: format(
              parseISO(insuranceExpiration),
              "yyyy-MM-dd"
            ),
            engineNumber,
            idProject,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />

      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
            reset();
            setId(null);
          }}
          titleModal={t("units.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
          size={"lg"}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-1">
            {t("units.info_modal")}
          </h4>
          <div className="grid grid-cols-3 ">
            <InputCustom
              type={"select"}
              labelText={t("units.project")}
              errorMessage={
                state.$errors.idProject.length > 0 ? t("error.general") : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idProject: e.target.value,
                }));
              }}
              value={state.$data.idProject ? state.$data.idProject : ""}
              onBlur={() => setExplicitField("idProject", true)}
              key="idProject"
              options={
                myProjectsList
                  ? myProjectsList.map((item) => ({
                    value: item.id,
                    text: item.name,
                  }))
                  : []
              }
            />
            <InputCustom
              type={"text"}
              labelText={t("units.serial_number")}
              errorMessage={
                state.$errors.serialNumber.length > 0
                  ? state.$errors.serialNumber[0].$message
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  serialNumber: e.target.value,
                }));
              }}
              value={state.$data.serialNumber ? state.$data.serialNumber : ""}
              onBlur={() => setExplicitField("serialNumber", true)}
              key="serial-number"
              disabled={id ? true : false}
            />
            <InputCustom
              type={"text"}
              labelText={t("units.license_plate_number")}
              errorMessage={
                state.$errors.plateNumber.length > 0 ? state.$errors.plateNumber[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  plateNumber: e.target.value,
                }));
              }}
              value={state.$data.plateNumber ? state.$data.plateNumber : ""}
              onBlur={() => setExplicitField("plateNumber", true)}
              key="plate-number"
            />
            <InputCustom
              type={"number"}
              labelText={t("units.year")}
              errorMessage={
                state.$errors.year.length > 0 ? t("error.general") : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  year: e.target.value,
                }));
              }}
              value={state.$data.year ? state.$data.year : ""}
              onBlur={() => setExplicitField("year", true)}
              key="year"
            />
            <InputCustom
              type={"select"}
              labelText={t("units.unit_type")}
              errorMessage={
                state.$errors.idTruckType.length > 0 ? t("error.general") : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  idTruckType: e.target.value,
                }));
              }}
              value={state.$data.idTruckType ? state.$data.idTruckType : ""}
              onBlur={() => setExplicitField("idTruckType", true)}
              key="idTruckType"
              options={
                unitType
                  ? unitType.map((item) => ({
                    value: item.id,
                    text: item.name,
                  }))
                  : []
              }
            />
            <InputCustom
              type={"select"}
              labelText={t("units.mark")}
              errorMessage={
                state.$errors.mark.length > 0 ? t("error.general") : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  mark: e.target.value,
                }));
              }}
              value={state.$data.mark ? state.$data.mark : ""}
              onBlur={() => setExplicitField("mark", true)}
              options={
                brandsList
                  ? brandsList.map((item) => ({
                    value: item.name,
                    text: item.name,
                  }))
                  : []
              }
              key="mark"
            />
            <InputCustom
              type={"text"}
              labelText={t("units.name")}
              errorMessage={
                state.$errors.name.length > 0 ? state.$errors.name[0].$message : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  name: e.target.value,
                }));
              }}
              value={state.$data.name ? state.$data.name : ""}
              onBlur={() => setExplicitField("name", true)}
              key="name"
            />
            <InputCustom
              type={"text"}
              labelText={t("units.policy_number")}
              errorMessage={
                state.$errors.policyNumber.length > 0
                  ? state.$errors.policyNumber[0].$message
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  policyNumber: e.target.value,
                }));
              }}
              value={state.$data.policyNumber ? state.$data.policyNumber : ""}
              onBlur={() => setExplicitField("policyNumber", true)}
              key="policyNumber"
            />
            <InputCustom
              type={"date"}
              labelText={t("units.expiration_date")}
              errorMessage={
                state.$errors.insuranceExpiration.length > 0
                  ? t("error.general")
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  insuranceExpiration: e.target.value,
                }));
              }}
              value={
                state.$data.insuranceExpiration
                  ? state.$data.insuranceExpiration
                  : ""
              }
              onBlur={() => setExplicitField("insuranceExpiration", true)}
              key="insuranceExpiration"
            />
            <InputCustom
              type={"text"}
              labelText={t("units.engine_number")}
              errorMessage={
                state.$errors.engineNumber.length > 0
                  ? state.$errors.engineNumber[0].$message
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  engineNumber: e.target.value,
                }));
              }}
              value={state.$data.engineNumber ? state.$data.engineNumber : ""}
              onBlur={() => setExplicitField("engineNumber", true)}
              key="engineNumber"
            />
            <InputCustom
              type={"text"}
              labelText={t("units.economicNumber")}
              errorMessage={
                state.$errors.economicNumber.length > 0
                  ? state.$errors.economicNumber[0].$message
                  : null
              }
              onChange={(e) => {
                e.persist();
                setData((old) => ({
                  ...old,
                  economicNumber: e.target.value,
                }));
              }}
              value={state.$data.economicNumber ? state.$data.economicNumber : ""}
              onBlur={() => setExplicitField("economicNumber", true)}
              key="economicNumber"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Units;
