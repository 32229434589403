const IconHome = ({ width, height, bg, fill }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill={bg} />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-4052 -769)"
          fill={bg || "none"}
        />
        <g id="icono_home">
          <g id="Group">
            <path
              id="Vector"
              d="M0 79.2858L75 4.28577L150 79.2858"
              fill={fill || "#222222"}
            />
            <path
              id="Vector_2"
              d="M20.834 70.9514V137.298C20.834 140.182 23.6118 145.951 29.1673 145.951H51.3895C54.1673 145.951 59.7229 143.067 59.7229 137.298V102.682C59.7229 99.7976 62.5006 94.0283 68.0562 94.0283H81.9451C84.7229 94.0283 90.2784 96.9129 90.2784 102.682V137.298C90.2784 140.182 93.0562 145.951 98.6118 145.951H120.834C123.612 145.951 129.167 143.067 129.167 137.298V70.9514"
              fill={fill || "#222222"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconHome;
