const Steps = ({ steps = [], activeIndex = 0 }) => {
  return (
    <div className="flex flex-row w-full items-center justify-center py-2">
      {steps.map((item, index) => {
        if (item.type) {
          return (
            <svg
              width="30"
              height="91"
              viewBox="0 0 150 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.965 1.80551L148.195 41.0354C150.602 43.4427 150.602 47.3458 148.195 49.7532L108.965 88.9831C106.557 91.3904 102.654 91.3904 100.247 88.9831C97.8395 86.5757 97.8395 82.6726 100.247 80.2653L128.953 51.5587H0V39.2299H128.953L100.247 10.5233C97.8395 8.11592 97.8395 4.21285 100.247 1.80551C102.654 -0.601836 106.557 -0.601836 108.965 1.80551Z"
                fill="black"
              />
            </svg>
          );
        }
        return (
          <div
            key={`stps_${index}`}
            className={`rounded-full w-[70px] h-[70px]  bg-[#222222] my-2 mx-2 cursor-pointer flex justify-center items-center p-1 border-2 border-solid ${item.index === activeIndex ? 'border-[#01C851]' : 'border-[#222222]'}`}
          >
            <span key={index} className="text-[#ffffff] mx-1 text-[10px] text-center">
              {item.text}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
