import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const NotAuthorized = () => {
  const [t] = useTranslation("default");
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: "LOGOUT" });
  }, [dispatch]);

  return (
    <main class="h-screen w-full flex flex-col justify-center items-center bg-[#c3c3c3]">
      <h1 class="text-9xl font-extrabold  tracking-widest">401</h1>
      <div class="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">
        {t("default.title")}
      </div>
      <button class="mt-5">
        <a
          class="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring"
          href="#blanck"
        >
          <span class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"></span>
          <span class="relative block px-8 py-3 bg-[#1A2238] border border-current">
            {t("default.description")}
          </span>
        </a>
      </button>
    </main>
  );
};

export default NotAuthorized;
