import React from "react";
import * as signalR from "@microsoft/signalr";
import { getGeofences } from "actions/Geofences";
import { useSelector } from "react-redux";
import CardAlert from "components/CardAlert";
import IsoTipo from "assets/logos/IsoTipo";
import MapMonitor from "components/MapMonitor";
import { getBodyAlert, getTripWithRoute } from "actions/Monitoring";
import toast from "react-hot-toast";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import FiltersMonitoring from "components/FiltersMonitoring";
import { motion } from "framer-motion";

const Monitoring = () => {
  const { REACT_APP_URL_WEB_SOCKET, PUBLIC_URL } = process.env;
  const [openDetails, setOpenDetails] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isConnect, setIsConnect] = React.useState(false);
  const [filters, setFilters] = React.useState({});

  const [t] = useTranslation("global");
  const [t2] = useTranslation("monitoring");

  const [alerts, setAlerts] = React.useState([]);

  const { currentProject, prevProject } = useSelector((state) => state.auth);

  const [connection, setConnection] = React.useState();
  const [geofences, setGeofences] = React.useState([]);

  React.useEffect(() => {
    let connectionConfig = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_URL_WEB_SOCKET, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connectionConfig
      .start()
      .then(() => {
        setConnection(connectionConfig);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      if (connectionConfig) {
        connectionConfig.stop();
      }
    };
  }, [REACT_APP_URL_WEB_SOCKET]);

  React.useEffect(() => {
    if (connection != null && currentProject && currentProject?.name) {
      connection
        .invoke("JoinGroupAsync", currentProject.name)
        .then(() => {
          setIsConnect(true);
          console.log("Conectado a", currentProject.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [connection, currentProject]);

  React.useEffect(() => {
    if (currentProject !== null && currentProject?.id) {
      getGeofences(currentProject.id).then((response) => {
        setGeofences(response);
      });
    }
  }, [currentProject]);

  const getDataAlert = ({ data, type }) => {
    switch (type) {
      case "REFUSE_TRIP":
        return data;
      case "INCIDENCE":
        return data.trip;
      case "POSSIBLE_ROUTE_DANGER":
        return data;
      default:
        return {};
    }
  };

  const getTextAlertCase = ({ data, type }) => {
    switch (type) {
      case "REFUSE_TRIP":
        return `El viaje -> ${data?.loadId} ha sido rechazado`;
      case "INCIDENCE":
        return `Se registro una incidencia del viaje -> ${data?.trip ? data?.trip?.loadId : ""
          }`;
      case "POSSIBLE_ROUTE_DANGER":
        return `Se detecto un viaje en posible zona peligrosa  -> ${data?.loadId}`
      default:
        return "";
    }
  };
  React.useEffect(() => {
    if (connection && isConnect) {
      connection.on("notifyEventTrip", (messageData) => {
        const { typeAlert, data, dateTimeEvent, statusDetail } = messageData;
        const responseData = getDataAlert({ data, type: typeAlert });
        const textAlert = getTextAlertCase({ data, type: typeAlert });
        toast(
          (e) => (
            <div
              className={`${t.visible ? "animate-enter" : "animate-leave"
                } max-w-md w-full bg-white  rounded-lg`}
            >
              <div className="grid grid-cols-toast">
                <div>
                  <span className="font-bold text-[12px]">{textAlert}</span>{" "}
                  <br />
                  <span className="font-bold text-[10px] text-gray-500">
                    {format(parseISO(dateTimeEvent), "yyyy-MM-dd hh:mm")}
                  </span>
                </div>
                <div className="grid place-items-center">
                  <button
                    onClick={() => toast.dismiss(e.id)}
                    className="text-white rouned-full w-[20px] w-[20px] bg-[#222222]"
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          ),
          {
            duration: 8000,
            position: "bottom-right",
            zIndex: 99999,
            icon: "🚚",
          }
        );
        setAlerts((oldValue) => {
          return [
            { typeAlert, data: responseData, dateTimeEvent, statusDetail },
            ...oldValue,
          ];
        });
      });
    }
  }, [connection, isConnect]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (prevProject && prevProject.id && connection) {
      connection
        .invoke("LeaveGroupAsync", prevProject.name)
        .then(() => {
          console.log("Desconectando de", prevProject.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [prevProject, connection]);

  const renderFilters = () => {
    return <FiltersMonitoring handleSearch={(values) => setFilters(values)} />;
  };

  const renderButtonAlert = () => {
    return (
      <button
        className={`bg-[#d3d3d3] rounded-full h-[30px] w-[30px]  hover:bg-gray-200 p-2 mx-2 flex items-center justify-center ${openAlert && "-rotate-90"
          }`}
        onClick={() => setOpenAlert(!openAlert)}
        title={t2("alerts.title")}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 150 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={alerts.length > 0 ? "animate-bounce" : ""}
        >
          <rect width="150" height="150" fill="none" />
          <g id="Iconografia">
            <rect
              width="5470"
              height="2342"
              transform="translate(-932 -769)"
              fill="none"
            />
            <g id="icono_alerta">
              <g id="Group 39">
                <path
                  id="Vector"
                  d="M75 8.57141L150 141.905H0L75 8.57141Z"
                  fill="#222222"
                />
                <path
                  id="Vector (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M75.0003 48.0952C76.1838 48.0952 77.1431 49.0546 77.1431 50.2381V91.9047C77.1431 93.0882 76.1838 94.0476 75.0003 94.0476C73.8168 94.0476 72.8574 93.0882 72.8574 91.9047V50.2381C72.8574 49.0546 73.8168 48.0952 75.0003 48.0952Z"
                  fill="white"
                />
                <path
                  id="Vector_2"
                  d="M74.9993 125.238C79.6017 125.238 83.3327 121.507 83.3327 116.905C83.3327 112.302 79.6017 108.571 74.9993 108.571C70.397 108.571 66.666 112.302 66.666 116.905C66.666 121.507 70.397 125.238 74.9993 125.238Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </svg>
      </button>
    );
  };

  const renderBarAlert = () => {
    return openAlert ? (
      <div className="absolute top-[20.5%] right-[15px] w-[280px] grid grid-rows-bar-alert">
        <div className="bg-[#222222] flex justify-between items-center rounded-t-[10px] px-3 py-2">
          <IsoTipo width={30} height={30} />
          <h3 className="text-[#ffffff] text-[20px] font-[Gravity] font-bold px-1">
            {t2("alerts.title")}
          </h3>
          <div className="rounded-full bg-white flex justify-center aling-center p-1">
            <svg
              width="20"
              height="20"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="150" height="150" fill="none" />
              <g id="Iconografia">
                <rect
                  width="5470"
                  height="2342"
                  transform="translate(-932 -769)"
                  fill="none"
                />
                <g id="icono_alerta">
                  <g id="Group 39">
                    <path
                      id="Vector"
                      d="M75 8.57141L150 141.905H0L75 8.57141Z"
                      fill="#222222"
                    />
                    <path
                      id="Vector (Stroke)"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M75.0003 48.0952C76.1838 48.0952 77.1431 49.0546 77.1431 50.2381V91.9047C77.1431 93.0882 76.1838 94.0476 75.0003 94.0476C73.8168 94.0476 72.8574 93.0882 72.8574 91.9047V50.2381C72.8574 49.0546 73.8168 48.0952 75.0003 48.0952Z"
                      fill="white"
                    />
                    <path
                      id="Vector_2"
                      d="M74.9993 125.238C79.6017 125.238 83.3327 121.507 83.3327 116.905C83.3327 112.302 79.6017 108.571 74.9993 108.571C70.397 108.571 66.666 112.302 66.666 116.905C66.666 121.507 70.397 125.238 74.9993 125.238Z"
                      fill="white"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="bg-white rounded-b-lg overflow-y-auto scrollbarBlack box-shadow-white pb-2 min-h-[500px]  max-h-[550px]">
          {alerts.map((item, index) => (
            <CardAlert
              dataTrip={item.data}
              setOpenIndex={(number) => {
                setOpenDetails(number);
              }}
              index={index}
              status={item.statusDetail}
              timeEvent={item.dateTimeEvent}
              type={item.typeAlert}
              openIndex={openDetails}
              getBodyAlert={getBodyAlert}
            />
          ))}
          {alerts.length === 0 ? (
            <div className="flex flex-col justy-center items-center gap-2">
              <p className="font-[Gravity] text-bold text-[#222222] text-center mt-5 ">
                {t2("alert")}
              </p>
              <motion.img
                src={`${PUBLIC_URL}/logos/logo_full.png`}
                className={"h-[100px] mt-5"}
                alt="#"
                animate={{
                  opacity: 1,
                }}
                initial={{
                  opacity: 0,
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="grid grid-rows-bar-map pb-1 h-full pt-8">
      <div className="bg-[#222222] grid grid-cols-20/80 rounded-t-lg pl-2">
        <div className="flex flex-row justify-start items-center gap-x-2">
          <IsoTipo width={40} height={40} />
          <span className="text-white text-[20px] font-[700]">
            {t("modules.title_monitoring")}
          </span>
        </div>
        <div className="flex justify-end">
          <div className="flex flex-row items-center justify-center">
            {renderFilters()}
          </div>
          <div className=" grid place-items-center">{renderButtonAlert()}</div>
        </div>
      </div>
      <div className="bg-[#21282e] w-full">
        <MapMonitor
          geofences={geofences}
          getDataTripWithRoute={getTripWithRoute}
          conectionSocket={connection}
          isConnect={isConnect}
          filters={filters}
          projectId={currentProject?.id}
        />

        {renderBarAlert()}
      </div>
    </div>
  );
};

export default Monitoring;
