import api from "api";
import { ResponseTransform } from "utils";

export const uploadPlanShiment = async (fileUpload) => {
  const response = await api
    .post("api/shipmentplan/upload", fileUpload)
    .then(ResponseTransform);
  return response;
};

export const checkPlanVerifyStatus = async (idCheck) => {
  let endPoint = `/api/shipmentplan/status-file/${idCheck}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const verifyPlan = async (idHeader) => {
  let endPoint = `api/shipmentplan/authorized-file/${idHeader}`;
  const response = await api.put(endPoint).then(ResponseTransform);
  return response;
};

export const getPlanLoads = async (idProject) => {
  let endPoint = `api/shipmentplan/get-list-files-by-proyect/${idProject}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const getPlanDetailByIdHeaderLoad = async (idHeadrLoad) => {
  let endPoint = `api/shipmentplan/detail-file/${idHeadrLoad}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const getPlanningForAssiggment = async ({
  idCarrier,
  startDate,
  endDate,
}) => {
  let endPoint = `api/shipmentplan/get-shipments-plan-by-carrier/${idCarrier}?dateInit=${startDate}&dateEnd=${endDate}`;
  const response = await api.get(endPoint).then(ResponseTransform);
  return response;
};

export const assignTrip = async (idDetailShipmentPlan, dto) => {
  let endPoint = `api/shipmentplan/assign-trip/${idDetailShipmentPlan}`;
  const response = await api.put(endPoint, dto).then(ResponseTransform);
  return response;
};
