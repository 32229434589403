import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import store from "redux/store";
import { persistStore } from "redux-persist";

import FontStyles from "hooks/FontStyles";
import Routers from "./Routers";
import "./index.css";


const persistor = persistStore(store);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FontStyles />
        <Routers />
        <Toaster />
      </PersistGate>
    </Provider>
  );
};

export default App;
