import React from "react";
import {
  GoogleMap,
  Circle,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

const MapGeocercas = ({
  zoom,
  center,
  radius,
  setPosition,
  defaultPosition,
}) => {
  const [positionMarker, setPositionMarker] = React.useState({});
  const [positionCircle, setPositionCircle] = React.useState({});

  const { REACT_APP_API_KEY_MAPS } = process.env;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_API_KEY_MAPS,
  });

  const refCircle = React.useRef();

  React.useEffect(() => {
    if (positionMarker.lat && positionMarker.lng) {
      setPosition((old) => ({
        ...old,
        lat: positionMarker.lat,
        lng: positionMarker.lng,
      }));
    }
  }, [positionMarker, setPosition]);

  React.useEffect(() => {
    if (defaultPosition) {
      setPositionMarker(defaultPosition);
      setPositionCircle(defaultPosition);
    }
  }, [defaultPosition]);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="h-full w-full"
      zoom={zoom}
      center={center}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
      }}
      onClick={(ev) => {
        let coords = {
          lat: ev.latLng.lat(),
          lng: ev.latLng.lng(),
        };
        setPositionMarker(coords);
        setPositionCircle(coords);
      }}
    >
      <Marker
        position={positionMarker}
        draggable={true}
        onDragEnd={(event) => {
          let coords = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          setPositionMarker(coords);
          setPositionCircle(coords);
        }}
      />
      <Circle
        ref={refCircle}
        radius={radius}
        center={positionCircle}
        options={{ fillColor: "#D44D5C", strokeColor: "#EE80AE66" }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapGeocercas;
