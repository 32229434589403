import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ children }) {
  const { user, views } = useSelector((state) => state.auth);

  const location = useLocation();

  const clearPath = (path) => {
    path = path.replace("..", "");
    path = path.replace(path.charAt(0), "");
    path = path.split("/")[0];
    return path;
  };

  const childrenViews = React.useMemo(() => {
    if (!views) return null;
    let { pathname } = location;
    let pathnCurrent = clearPath(pathname);
    let resultViews = views.find((item) => item.url.includes(pathnCurrent));
    if (!resultViews) return {};
    const { children, actions } = resultViews;
    if (!actions) return { children, permissions: {} };
    let permissions = actions.reduce((a, b) => ({ ...a, [b.name]: true }), {});
    return { children, permissions };
  }, [location, views]);

  return Object.keys(user).length > 0 ? (
    React.cloneElement(children, {
      childrenMenu: childrenViews?.children,
      permissions: childrenViews?.permissions,
    })
  ) : (
    <Navigate to="/" />
  );
}
