import * as React from "react";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Joi from "joi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useValidator } from "react-joi";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrUpdateClient,
  deleteClient,
  getClientList,
} from "actions/Catalogs";
import { notify } from "utils";
import { parseISO, format } from "date-fns/esm";
import { filterDate } from "components/DateCatalogs/filterDate";

const Client = ({ view, data }) => {
  const [t] = useTranslation("catalog");
  const [tg] = useTranslation("global");
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state) => state.auth);

  const { permissions } = view;

  const [openModal, setOpenModal] = React.useState(false);
  const [id, setId] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);

  const { state, setData, setExplicitField, validate, reset } = useValidator({
    initialData: {
      name: null,
      shortName: null,
    },
    schema: Joi.object({
      name: Joi.string()
        .min(2)
        .max(100)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '100' }),
          'any.required': t("error.required")
        }),
      shortName: Joi.string()
        .min(2)
        .max(50)
        .required()
        .messages({
          'string.base': t("error.type", { type: 'string' }),
          'string.empty': t("error.empty"),
          'string.min': t("error.min"),
          'string.max': t("error.max", { quantity: '50' }),
          'any.required': t("error.required")
        }),
    }),
    explicitCheck: {
      name: false,
      shortName: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  });

  const buttonRef = React.useRef();

  React.useEffect(() => {
    dispatch(getClientList());
  }, [dispatch]);

  const handleOnSubmit = async () => {
    if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
      validate();
      notify("error", t("error.general_toast"), 1200);
      return;
    }
    setIsLoad(true);
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;
    let isSuccess = await dispatch(
      addOrUpdateClient({
        name: state.$data.name,
        shortName: state.$data.shortName,
        id: id > 0 ? id : null,
      })
    );
    if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
    setIsLoad(false);
    if (isSuccess) {
      reset()
      setId(null);
      setOpenModal(false);
    }
  };

  const handleDelete = async (idProject) => {
    if (idProject === currentProject?.id) {
      notify(
        "warning",
        "No puedes eliminar el proyecto en el que estas trabajando"
      );
      return false;
    }
    Swal.fire({
      title: tg("button.title"),
      text: tg("button.text"),
      icon: "error",
      confirmButtonText: tg("button.confirm"),
      cancelButtonText: tg("button.cancel"),
      cancelButtonColor: "#000000",
      confirmButtonColor: "#FF3447",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteClient(idProject));
      }
    });
  };

  return (
    <>
      <Grid
        title={t("clients.title")}
        columns={[
          {
            Header: "Id",
            accessor: "id",
            filter: "text",
            width: '10%'
          },
          {
            Header: t("clients.name"),
            accessor: "name",
            filter: "text",
            width: '30%'
          },
          {
            Header: t("clients.short_name"),
            accessor: "shortName",
            filter: false,
            width: '30%'
          },
          {
            Header: t("clients.updated_at"),
            accessor: "updatedAt",
            filter: filterDate,
            Cell: ({ value }) => {
              if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
              return value;
            },
            width: '20%',

          },
        ]}
        data={data || []}
        handleAdd={() => {
          setOpenModal(true);
        }}
        handleEdit={(row) => {
          const { id, shortName, name } = row;
          setId(id);
          setData((old) => ({
            ...old,
            name: name,
            shortName: shortName,
          }));
          setOpenModal(true);
        }}
        handleDelete={({ id }) => handleDelete(id)}
        write={permissions.write}
      />
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            reset()
            setOpenModal(false);
            setId(null);
          }}
          titleModal={t("clients.title")}
          onSubmit={handleOnSubmit}
          disableAdd={state.$invalid}
          iconEdit={id > 0 ? true : false}
          buttonRef={buttonRef}
          processFetch={isLoad}
        >
          <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
            {t("clients.info_modal")}
          </h4>
          <div className="flex flex-wrap ">
            <div className="flex flex-col min-w-[250px] my-2 mx-2">
              <label className="text-black font-[Gravity] text-[16px] font-[350] ">
                {t("clients.name")}&nbsp;
                {state.$errors.name.length > 0 ? (
                  <span className="font-[Gravity] text-[#FF3447] text-[12px] ">
                    {state.$errors.name[0].$message}
                  </span>
                ) : null}
              </label>
              <input
                type="text"
                className="rounded-[10px] bg-[#DEDEDE] outline-none py-2 px-2"
                onChange={(e) => {
                  e.persist();
                  setData((old) => ({
                    ...old,
                    name: e.target.value,
                  }));
                }}
                value={state?.$data?.name}
                onBlur={() => setExplicitField("name", true)}
              />
            </div>
            <div className="flex flex-col min-w-[250px] my-2 mx-2">
              <label className="text-black font-[Gravity] text-[16px] font-[350] ">
                {t("clients.short_name")}&nbsp;
                {state.$errors.shortName.length > 0 ? (
                  <span className="font-[Gravity] text-[#FF3447] text-[12px] ">
                    {state.$errors.shortName[0].$message}
                  </span>
                ) : null}
              </label>
              <input
                type="text"
                className="rounded-[10px] bg-[#DEDEDE] outline-none py-2 px-2"
                onChange={(e) => {
                  e.persist();
                  setData((old) => ({
                    ...old,
                    shortName: e.target.value,
                  }));
                }}
                value={state.$data.shortName ? state.$data.shortName : ""}
                onBlur={() => setExplicitField("shortName", true)}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default Client;
