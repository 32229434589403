import { Routes, Route, Navigate } from "react-router-dom";
import Client from "./Modules/Client";
import Units from "./Modules/Units";
import UnitType from "./Modules/UnitType";
import Brands from "./Modules/Brands";
import Profile from "./Modules/Profile";
import RestrictionRoute from "routes/RestrictionRoute";
import Components from "./Modules/Components";
import GroupOption from "./Modules/GroupOption";
import GroupOptionById from "./Modules/GroupOptionById";

const RoutersConfig = ({ clients, units, views }) => {
  return (
    <Routes>
      <Route
        path="/client"
        element={
          <RestrictionRoute views={views}>
            <Client data={clients} />
          </RestrictionRoute>
        }
      />

      <Route
        path="/units"
        element={
          <RestrictionRoute views={views}>
            <Units data={units} />
          </RestrictionRoute>
        }
      />
      <Route
        path="/brands"
        element={
          <RestrictionRoute views={views}>
            <Brands />
          </RestrictionRoute>
        }
      />
      <Route
        path="/unit-type"
        element={
          <RestrictionRoute views={views}>
            <UnitType />
          </RestrictionRoute>
        }
      />
      <Route
        path="profile"
        element={
          <RestrictionRoute views={views}>
            <Profile />
          </RestrictionRoute>
        }
      />
      <Route
        path="components"
        element={
          <Components />
        }
      />
      <Route
        path="/group-option"
        element={
          <RestrictionRoute views={views}>
            <GroupOption />
          </RestrictionRoute>
        }
      />
      <Route
        path="/group-option-id"
        element={
          <RestrictionRoute views={views}>
            <GroupOptionById />
          </RestrictionRoute>
        }
      />

      <Route path="*" element={<Navigate to="../client" replace />} />
    </Routes>
  );
};

export default RoutersConfig;
