import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthPage from "./pages/Auth";
import RecoverPassword from "./pages/RecoverPassword";
import ChangePassword from "pages/ChangePassword";
import Admin from "./pages/Admin";
import Catalog from "pages/Catalog";
import Config from "pages/Config"
import Monitoring from "pages/Monitoring";
import Planning from "pages/Planning";

import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import Default from "pages/Default/Default";
import NotAuthorized from "pages/NotAuthorized";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <AuthPage />
            </PublicRoute>
          }
        />
        <Route
          path="/recover-password"
          element={
            <PublicRoute>
              <RecoverPassword />
            </PublicRoute>
          }
        />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="catalog/*"
          element={
            <PrivateRoute>
              <Catalog />
            </PrivateRoute>
          }
        />
        <Route
          path="config/*"
          element={
            <PrivateRoute>
              <Config />
            </PrivateRoute>
          }
        />
        <Route
          path="monitoring/*"
          element={
            <PrivateRoute>
              <Monitoring />
            </PrivateRoute>
          }
        />
        <Route
          path="planning/*"
          element={
            <PrivateRoute>
              <Planning />
            </PrivateRoute>
          }
        />

        <Route
          path="default"
          element={
            <PrivateRoute>
              <Default />
            </PrivateRoute>
          }
        />
        <Route path="not-authorized" element={<NotAuthorized />} />
        <Route
          path="*"
          element={
            <div>
              <h2>Not found</h2>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
