import { InputComponent } from "./styled.components";
export default function InpuLogin({ label, type, value, onChange, className, autocomplete }) {
  return (
    <div className={`flex flex-col ${className}`}>
      <label className="font-['Gravity']">{label}</label>
      <InputComponent
        type={type || "text"}
        defaultValue={value}
        onChange={onChange}
        autocomplete={autocomplete}
      />
    </div>
  );
}
