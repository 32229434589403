import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


import { getGroupOptionsById } from 'actions/Catalogs';

import Grid from 'components/Grid';


const GroupOptionById = ({ view }) => {
    const location = useLocation();
    const [t] = useTranslation("catalog");
    const dispatch = useDispatch();
    const { groupOptionByIdList } = useSelector((state) => state.catalog);
    const { options } = groupOptionByIdList;
    const { permissions } = view;


    React.useEffect(() => {
        if (location.state && location?.state["id"]) {
            dispatch(getGroupOptionsById(location.state["id"]));
        }
    }, [dispatch, location.state]);

    return (
        <>
            <Grid

                title={"Options"}
                columns={[
                    {
                        Header: "Id",
                        accessor: "id",
                        filter: "text",
                        width: '20%'
                    },
                    {
                        Header: t("groupOption.description"),
                        accessor: "description",
                        filter: "text",
                        width: '75%',
                    },
                ]}
                data={
                    options || []
                }
                controlsHeaderHidden
                hiddenDefaultOptions
                write={permissions.write}

            />
        </>
    )
}

export default GroupOptionById