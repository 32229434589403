import React from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Swal from 'sweetalert2';
import Joi from 'joi';
import useValidator from 'react-joi';

import { addOrUpdateComponents, deleteComponents, getComponents } from 'actions/Catalogs';

import Modal from 'components/Modal';
import Grid from 'components/Grid';
import InputCustom from 'components/InputCustom';
import { filterDate } from 'components/DateCatalogs/filterDate';
import { notify } from 'utils';

const Components = () => {
    const [t] = useTranslation("catalog");
    const [tg] = useTranslation("global");
    const dispatch = useDispatch();
    const { componentsList } = useSelector((state) => state.catalog);
    const [openModal, setOpenModal] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [id, setId] = React.useState();
    const [isLoad, setIsLoad] = React.useState(false);



    const { state, setData, setExplicitField, validate } = useValidator({
        initialData: {
            name: null,
            haveGroupResponse: false,
        },
        schema: Joi.object({
            name: Joi.string()
                .min(2)
                .max(200)
                .required()
                .messages({
                    'string.empty': t("error.empty"),
                    'string.base': t("error.type", { type: 'string' }),
                    'string.min': t("error.min"),
                    'string.max': t("error.max", { quantity: '200' }),
                    'any.required': t("error.required")
                }),
            haveGroupResponse: Joi.boolean().required(),
        }),
        explicitCheck: {
            name: false,
            haveGroupResponse: false,
        },
        validationOptions: {
            abortEarly: true,
        },
    });

    const buttonRef = React.useRef();

    React.useEffect(() => {
        dispatch(getComponents());
    }, [dispatch]);


    const handleOnSubmit = async () => {
        const { haveGroupResponse, name } = state.$data;
        if (state.$all_errors.length > 0 || state.$all_source_errors.length > 0) {
            setShowError(true);
            validate();
            notify("error", t("error.general_toast"), 1200);
            return;
        }

        setIsLoad(true);
        if (buttonRef & buttonRef?.current) buttonRef.current.disabled = true;

        let isSuccess = await dispatch(
            addOrUpdateComponents({
                name,
                haveGroupResponse,
                id,
            })
        );
        if (buttonRef & buttonRef?.current) buttonRef.current.disabled = false;
        setIsLoad(false);
        setShowError(false);
        if (isSuccess) {
            setData((old) => ({
                ...old,
                name: null,
                haveGroupResponse: false,
            }));
            setId(null);

            setOpenModal(false);
        }


    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: tg("button.title"),
            text: tg("button.text"),
            icon: "error",
            confirmButtonText: tg("button.confirm"),
            cancelButtonText: tg("button.cancel"),
            cancelButtonColor: "#000000",
            confirmButtonColor: "#FF3447",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed && id) {
                dispatch(deleteComponents(id));
            }
        });
    };
    return (
        <>
            <Grid

                title={t("components.title")}
                columns={[
                    {
                        Header: "Id",
                        accessor: "id",
                        filter: "text",
                        width: '10%'
                    },
                    {
                        Header: t("components.name"),
                        accessor: "name",
                        filter: "text",
                        width: '30%',
                    },
                    {
                        Header: t("components.haveGroupResponse"),
                        accessor: "haveGroupResponse",
                        width: '30%',
                        filter: "text",
                        Cell: ({ value }) => {
                            return value ? (
                                <div className="flex items-center ">
                                    <p>{t("components.haveGroupResponseTrue")}</p>
                                </div>
                            ) : (
                                <div className="flex items-center ">
                                    <p>{t("components.haveGroupResponseFalse")}</p>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t("components.updated_at"),
                        accessor: "updatedAt",
                        filter: filterDate,
                        Cell: ({ value }) => {
                            if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm");
                            return value;
                        },
                        width: '20%',
                    },
                ]}
                data={
                    componentsList || []
                }
                handleAdd={() => {
                    if (true) {
                        setOpenModal(true);
                    }
                }}
                handleEdit={(row) => {
                    const { name, haveGroupResponse, id } = row;
                    setId(id);
                    setData((old) => ({
                        ...old,
                        name,
                        haveGroupResponse,
                    }));
                    setOpenModal(true);
                }}
                handleDelete={({ id }) => handleDelete(id)}
                write={true}
            />
            {openModal ? (
                <Modal
                    openModal={openModal}
                    closeModal={() => {
                        setData((old) => ({
                            ...old,
                            name: null,
                            haveGroupResponse: false,
                        }));
                        setShowError(false);
                        setOpenModal(false);
                        setId(null);
                    }}
                    titleModal={t("components.title")}
                    onSubmit={handleOnSubmit}
                    disableAdd={state.$invalid}
                    iconEdit={id > 0 ? true : false}
                    buttonRef={buttonRef}
                    processFetch={isLoad}
                >
                    <h4 className="font-[Gravity] font-bold tex-[18px] my-2">
                        {t("components.info_modal")}
                    </h4>
                    <div className="flex w-full flex-wrap ">
                        <InputCustom
                            type={"text"}
                            labelText={t("components.name")}
                            onBlur={() => setExplicitField("name", true)}
                            errorMessage={
                                showError && state.$errors.name.length > 0 ? state.$errors.name[0].$message : null
                            }
                            onChange={(e) => {
                                e.persist();
                                setData((old) => ({
                                    ...old,
                                    name: e.target.value,
                                }));
                            }}
                            value={state.$data.name ? state.$data.name : ""}
                        />
                        <div className="flex flex-col items-center justify-center  min-w-[250px] my-[.5px] mx-1">
                            <label
                                for="default-checkbox"
                                class="text-black font-[Gravity] text-[14px] font-[350]"
                            >
                                {t("components.haveGroupResponse")}
                            </label>
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    e.persist();
                                    setData((old) => ({
                                        ...old,
                                        haveGroupResponse: e.target.checked,
                                    }));
                                }
                                }
                                checked={state.$data.haveGroupResponse}
                                value={state.$data.haveGroupResponse ? state.$data.haveGroupResponse : ""}
                                onBlur={() => setExplicitField("haveGroupResponse", true)}
                                key="haveGroupResponse"
                            />
                        </div>
                    </div>
                </Modal>
            ) : null}
        </>
    )
}

export default Components