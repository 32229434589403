const BarVertical = ({ title, children }) => {
  return (
    <div className="bg-[#ffffff] min-h-[480px] rounded box-shadow-white w-full">
      <div className="bg-[#000000] rounded-t-lg h-[59px] w-full flex items-center px-4 box-shadow-black">
        <h3 className="text-[#ffffff] text-[16px] font-[Gravity] font-bold">
          {title}
        </h3>
      </div>
      <div className="flex flex-col items-start px-4 w-full">{children}</div>
    </div>
  );
};

export default BarVertical;
