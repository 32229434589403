const IconCatalogos = ({ width, height, bg, fill }) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="150" fill={bg} />
      <g id="Iconografia">
        <rect
          width="5470"
          height="2342"
          transform="translate(-4572 -769)"
          fill={bg}
        />
        <g id="icono_catalogo">
          <g id="_x30_NjrIV.tif">
            <g id="Group">
              <path
                id="Vector"
                d="M22.9421 15C26.4715 15 30.0009 15.8824 33.5303 15.8824C45.0009 17.6471 56.4715 21.1765 64.4127 30C67.0597 32.6471 67.9421 34.4118 69.7068 37.0588C70.5891 37.9412 70.5891 39.7059 70.5891 40.5882C70.5891 68.8235 70.5891 97.0588 70.5891 124.412C70.5891 124.412 70.5891 124.412 70.5891 125.294C69.7068 125.294 69.7068 124.412 69.7068 124.412C60.8833 116.471 50.295 111.176 38.8244 109.412C33.5303 108.529 28.2362 108.529 22.0597 107.647C18.5303 107.647 16.7656 105 16.7656 101.471C16.7656 75 16.7656 47.6471 16.7656 21.1765C16.7656 17.6471 17.648 16.7647 22.0597 15C22.9421 15 22.9421 15 22.9421 15Z"
                fill={fill}
              />
              <path
                id="Vector_2"
                d="M127.059 15C131.471 16.7647 132.353 17.6471 132.353 22.0588C132.353 48.5294 132.353 74.1176 132.353 100.588C132.353 104.118 129.706 107.647 126.177 107.647C115.589 107.647 105 109.412 95.2945 113.824C90.0003 116.471 84.7062 120 81.1768 123.529C81.1768 123.529 80.2945 124.412 80.2945 125.294C80.2945 125.294 80.2945 125.294 79.4121 125.294C79.4121 124.412 79.4121 124.412 79.4121 123.529C79.4121 97.0588 79.4121 69.7059 79.4121 43.2353C79.4121 38.8235 80.2945 36.1765 82.9415 33.5294C90.0003 24.7059 99.7062 19.4118 111.177 16.7647C115.589 15.8824 120 15.8824 124.412 15C125.294 15 125.294 15 126.177 15C127.059 15 127.059 15 127.059 15Z"
                fill={fill}
              />
              <path
                id="Vector_3"
                d="M150 123.529C148.235 127.059 146.471 127.941 142.941 127.059C130.588 124.412 118.235 122.647 105 124.412C99.7059 124.412 95.2941 125.294 90 126.177C90.8824 125.294 91.7647 125.294 91.7647 124.412C97.9412 120 105 116.471 112.059 115.588C117.353 114.706 121.765 114.706 127.059 113.824C132.353 113.824 136.765 111.176 138.529 106.765C139.412 105 140.294 102.353 140.294 99.7059C140.294 79.4118 140.294 59.1177 140.294 38.8236C140.294 37.9412 140.294 37.9412 140.294 37.0588C145.588 36.1765 147.353 37.0588 149.118 40.5883C150 67.9412 150 96.1765 150 123.529Z"
                fill={fill}
              />
              <path
                id="Vector_4"
                d="M60 128.824C50.2941 126.177 40.5882 126.177 30.8824 126.177C22.9412 126.177 15 127.941 7.94118 129.706C3.52941 130.588 0 127.941 0 123.529C0 95.2942 0 67.0589 0 38.8236C0 34.4118 1.76471 32.6471 6.17647 32.6471C7.05882 32.6471 7.94118 32.6471 8.82353 32.6471C8.82353 33.5294 8.82353 33.5294 8.82353 34.4118C8.82353 56.4706 8.82353 77.6471 8.82353 99.7059C8.82353 105 10.5882 108.529 14.1176 112.059C16.7647 113.824 19.4118 114.706 22.0588 114.706C28.2353 114.706 33.5294 115.588 39.7059 117.353C46.7647 119.118 53.8235 122.647 59.1176 127.941C59.1176 127.941 59.1176 127.941 60 128.824C60 127.941 60 127.941 60 128.824Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconCatalogos;
