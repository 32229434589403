import React from "react";
import Steps from "components/Steps";
import Modal from "components/Modal";
import { useDropzone } from "react-dropzone";
import { uploadPlanShiment, checkPlanVerifyStatus } from "actions/Planeacion";
import {
  setIdInterval,
  setPlannigEarring,
  clearIntervalId,
} from "redux/planning.slice";
import { useDispatch } from "react-redux";
import { notify } from "utils";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
const { PUBLIC_URL } = process.env;

const PlanningPage = ({ view }) => {
  const [t] = useTranslation("planning");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonRef = React.useRef();
  const overlayRef = React.useRef();

  const [activeIndexStep, setActiveIndexStep] = React.useState(null);
  const { currentProject } = useSelector((state) => state.auth);
  const { idIntervalId } = useSelector((state) => state.planning);
  const [openModal, setOpenModal] = React.useState(false);

  const { permissions } = view;

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "text/csv": [".cvs", ".xlsx"],
      },
      onDrop: () => {
        setActiveIndexStep(2);
      },
    });

  const handleUpload = () => {
    if (permissions.write) {
      if (idIntervalId !== null && idIntervalId !== undefined) {
        notify("warning", t("handleUpload.in_process"), 6000);
        return null;
      }
      let formData = new FormData();
      formData.append("UploadFile", acceptedFiles[0]);
      formData.append("IdProject", currentProject.id);
      setActiveIndexStep(3);

      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      inputRef.current.value = "";

      buttonRef.current.disabled = true;
      buttonRef.current.innerText = t("handleUpload.sending");
      overlayRef.current.classList.remove("hidden");
      uploadPlanShiment(formData).then((response) => {
        overlayRef.current.classList.add("hidden");
        if (response) {
          dispatch(setPlannigEarring({ ...response }));
          setActiveIndexStep(4);

          let idInterval = setInterval(() => {
            checkPlanVerifyStatus(response.id).then((checkRresponse) => {
              let idB64 = Buffer.from(response.id.toString()).toString(
                "base64"
              );
              if (checkRresponse && [2, 3].includes(checkRresponse.id)) {
                let messageAdditional =
                  checkRresponse.id === 3
                    ? t("handleUpload.message_additional_true")
                    : t("handleUpload.message_additional_false");
                toast(
                  (e) => (
                    <div>
                      <span className="font-[Gravity] text-[#222222]">
                        {t("handleUpload.finish")}
                      </span>{" "}
                      &nbsp;&nbsp;
                      <span
                        className={`${checkRresponse.id === 2
                          ? "text-[#FF3447]"
                          : "text-[#01c851]"
                          }`}
                      >
                        {messageAdditional}
                      </span>
                      <button
                        onClick={() => {
                          toast.dismiss(e.id);
                          navigate(`../detail`, {
                            state: { idHeader: idB64 },
                          });
                        }}
                        className={
                          "border-1 border-[#222222] text-blue-700 ml-2 font-[Gravity]"
                        }
                      >
                        {t("plan_upload.verify")}
                      </button>
                    </div>
                  ),
                  {
                    duration: 20000,
                    position: "top-right",
                    icon: "👏",
                    background: '#8BDB81'
                  }
                );

                dispatch(clearIntervalId(null));
                if (buttonRef) {
                  buttonRef.current.disabled = false;
                  buttonRef.current.innerText = t("handleUpload.validate");
                }
              }
            });
          }, 6000);
          dispatch(setIdInterval(idInterval));
        } else {
          if (buttonRef) {
            buttonRef.current.disabled = false;
            buttonRef.current.innerText = t("handleUpload.validate");
          }
        }
      });
    }
  };

  const getZoneInfo = () => {
    if (isDragActive) {
      return (
        <span className="font-[Gravity] text-[18px] font-[400]">
          {t("plan_upload.drop")}
        </span>
      );
    }

    if (acceptedFiles.length > 0) {
      return (
        <div className="flex flex-col items-center justify-center">
          <svg
            width="50"
            height="50"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="75" cy="75" r="75" fill="#DEDEDE" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M110.763 103.847V62.1158L80.9554 32.3081H51.1477C44.5628 32.3081 39.2246 37.6462 39.2246 44.2312V103.847C39.2246 110.432 44.5628 115.77 51.1477 115.77H98.84C105.425 115.77 110.763 110.432 110.763 103.847Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.9141 44.2308C36.9141 36.3713 43.2854 30 51.1448 30H80.9525C81.5646 30 82.1515 30.2431 82.5843 30.6759L112.392 60.4836C112.825 60.9164 113.068 61.5033 113.068 62.1154V103.846C113.068 111.706 106.697 118.077 98.8371 118.077H51.1448C43.2854 118.077 36.9141 111.706 36.9141 103.846V44.2308ZM51.1448 34.6154C45.8344 34.6154 41.5294 38.9203 41.5294 44.2308V103.846C41.5294 109.157 45.8344 113.462 51.1448 113.462H98.8371C104.148 113.462 108.453 109.157 108.453 103.846V63.0713L79.9966 34.6154H51.1448Z"
              fill="#222222"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M48.8418 74.0389C48.8418 72.7644 49.875 71.7312 51.1495 71.7312H80.9572C82.2317 71.7312 83.2649 72.7644 83.2649 74.0389C83.2649 75.3134 82.2317 76.3466 80.9572 76.3466H51.1495C49.875 76.3466 48.8418 75.3134 48.8418 74.0389Z"
              fill="#222222"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M48.8418 85.9617C48.8418 84.6872 49.875 83.6541 51.1495 83.6541H92.8803C94.1548 83.6541 95.188 84.6872 95.188 85.9617C95.188 87.2362 94.1548 88.2694 92.8803 88.2694H51.1495C49.875 88.2694 48.8418 87.2362 48.8418 85.9617Z"
              fill="#222222"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M48.8418 97.8846C48.8418 96.6101 49.875 95.5769 51.1495 95.5769H69.0341C70.3086 95.5769 71.3418 96.6101 71.3418 97.8846C71.3418 99.1591 70.3086 100.192 69.0341 100.192H51.1495C49.875 100.192 48.8418 99.1591 48.8418 97.8846Z"
              fill="#222222"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M80.9512 32.3081V50.1927C80.9512 56.7777 86.2893 62.1158 92.8742 62.1158H110.759"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M80.9483 30C82.2228 30 83.256 31.0332 83.256 32.3077V50.1923C83.256 55.5027 87.561 59.8077 92.8714 59.8077H110.756C112.031 59.8077 113.064 60.8409 113.064 62.1154C113.064 63.3899 112.031 64.4231 110.756 64.4231H92.8714C85.012 64.4231 78.6406 58.0517 78.6406 50.1923V32.3077C78.6406 31.0332 79.6738 30 80.9483 30Z"
              fill="#222222"
            />
          </svg>
          <span className="font-[Gravity] text-[14px] font-[400] text-center my-2">
            {acceptedFiles[0].name}
          </span>
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center justify-center">
        <svg
          width="60"
          height="60"
          viewBox="0 0 150 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="75" cy="75" r="75" fill="#DEDEDE" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M86.8402 115.954H99.1757C105.988 115.954 111.511 110.431 111.511 103.619V54.2766L86.8402 29.6056H49.8336C43.0209 29.6056 37.498 35.1284 37.498 41.9411V103.619C37.498 110.431 43.0209 115.954 49.8336 115.954H62.1691"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.5371 41.9409C34.5371 33.4932 41.3854 26.6449 49.8332 26.6449H86.8397C87.6249 26.6449 88.3779 26.9568 88.9331 27.512L113.604 52.1831C114.159 52.7383 114.471 53.4913 114.471 54.2765V103.619C114.471 112.066 107.623 118.915 99.1753 118.915H86.8397C85.2047 118.915 83.8792 117.589 83.8792 115.954C83.8792 114.319 85.2047 112.994 86.8397 112.994H99.1753C104.353 112.994 108.55 108.796 108.55 103.619V55.5028L85.6135 32.5659H49.8332C44.6555 32.5659 40.4582 36.7633 40.4582 41.9409V103.619C40.4582 108.796 44.6555 112.994 49.8332 112.994H62.1687C63.8037 112.994 65.1292 114.319 65.1292 115.954C65.1292 117.589 63.8037 118.915 62.1687 118.915H49.8332C41.3854 118.915 34.5371 112.066 34.5371 103.619V41.9409Z"
            fill="#222222"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M56 72.7797L74.5033 54.2764L93.0066 72.7797"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M72.4114 52.1828C73.5676 51.0266 75.4421 51.0266 76.5982 52.1828L95.1015 70.6861C96.2577 71.8422 96.2577 73.7167 95.1015 74.8729C93.9454 76.0291 92.0709 76.0291 90.9147 74.8729L74.5048 58.463L58.095 74.8729C56.9388 76.0291 55.0643 76.0291 53.9081 74.8729C52.752 73.7167 52.752 71.8422 53.9081 70.6861L72.4114 52.1828Z"
            fill="#222222"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M74.5035 51.3157C76.1385 51.3157 77.464 52.6411 77.464 54.2762V122.122C77.464 123.757 76.1385 125.082 74.5035 125.082C72.8684 125.082 71.543 123.757 71.543 122.122V54.2762C71.543 52.6411 72.8684 51.3157 74.5035 51.3157Z"
            fill="#222222"
          />
        </svg>
        <span className="font-[Gravity] text-[14px] font-[400] text-center">
          {t("plan_upload.instruction1")}
        </span>
      </div>
    );
  };

  return (
    <div className=" bg-[#ffffff] min-h-[480px] rounded box-shadow-white">
      <div className="bg-[#000000] rounded-t-lg h-[59px] w-full flex items-center justify-between px-4 box-shadow-black">
        <h3 className="text-[#ffffff] text-[20px] font-[Gravity] font-bold">
          {t("plan_upload.title")}
        </h3>

        <div className="flex flex-wrap">
         
          <button
            onClick={() => {
              setOpenModal(true)
            }}
            className="text-white mx-1"
            title="Informacion"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 150 150" fill="none">
              <path d="M75 0C33.5826 0 0 33.5826 0 75C0 116.417 33.5826 150 75 150C116.417 150 150 116.417 150 75C150 33.5826 116.417 0 75 0ZM75 118.527C71.3002 118.527 68.3036 115.53 68.3036 111.83C68.3036 108.131 71.3002 105.134 75 105.134C78.6998 105.134 81.6964 108.131 81.6964 111.83C81.6964 115.53 78.6998 118.527 75 118.527ZM85.5301 81.7801C84.014 82.3656 82.7095 83.3943 81.7868 84.7322C80.864 86.0701 80.3658 87.6549 80.3571 89.2801V93.0804C80.3571 93.817 79.7545 94.4196 79.0179 94.4196H70.9821C70.2455 94.4196 69.6429 93.817 69.6429 93.0804V89.481C69.6429 85.6138 70.7645 81.7969 72.9743 78.6161C75.1339 75.5022 78.1473 73.125 81.6964 71.769C87.3884 69.5759 91.0714 64.8047 91.0714 59.5982C91.0714 52.2154 83.856 46.2054 75 46.2054C66.144 46.2054 58.9286 52.2154 58.9286 59.5982V60.8705C58.9286 61.6071 58.3259 62.2098 57.5893 62.2098H49.5536C48.817 62.2098 48.2143 61.6071 48.2143 60.8705V59.5982C48.2143 53.019 51.0937 46.875 56.317 42.3047C61.3393 37.9018 67.9688 35.4911 75 35.4911C82.0313 35.4911 88.6607 37.9185 93.683 42.3047C98.9062 46.875 101.786 53.019 101.786 59.5982C101.786 69.2746 95.4074 77.9799 85.5301 81.7801Z" fill="#DEDEDE" />
            </svg>
          </button>
          <Menu
            animate={{
              mount: { y: 0 },
              unmount: { y: 25 },
            }}  
          >
            <MenuHandler className="grid place-items-center w-[30px] h-[30px] rounded rounded-full bg-[#eeeeee] p-1 cursor-pointer">
              <img
                src={`${PUBLIC_URL}/icons/btns/btn_options.svg`}
                alt="#"
                width={4}
                height={4}
                title={t("plan_upload.download_format")}
              />
            </MenuHandler>
            <MenuList className="left-start !min-w-[100px] !max-w-[140px]" >
              
              <MenuItem
                onClick={ () => {
                  try {
                    const { REACT_APP_BUCKET_BASE_URL } = process.env;
                    window.open(
                      `${REACT_APP_BUCKET_BASE_URL}/servicios_storage/gtrack/74f4c240-dceb-49cc-8cdf-fcba3203660e.csv`,
                      "_blank"
                    );
                  } catch (error) {
                    console.error(error);
                  }
                }}
                className={"font-[Gravity]"}
              >
                {t("plan_upload.csv")}
              </MenuItem>
              <MenuItem
                onClick={ () => {
                  try {
                    const { REACT_APP_BUCKET_BASE_URL } = process.env;
                    window.open(
                      `${REACT_APP_BUCKET_BASE_URL}/servicios_storage/gtrack/eda5abc7-81d0-49ea-93ff-50b8494cada3.xlsx`,
                      "_blank"
                    );
                  } catch (error) {
                    console.error(error);
                  }
                }}
                className={"font-[Gravity]"}
              >
                {t("plan_upload.xlsx")}
              </MenuItem>
            </MenuList>           
          </Menu>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-4 px-4 w-full h-full">
        <div
          className={`w-[700px] h-[160px] border-2 border-dashed rounded-[10px] my-3 flex flex-row items-center justify-center ${isDragActive ? "border-[#01C851]" : "border-[#222222]"
            }`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {getZoneInfo()}
        </div>
        {acceptedFiles.length ? (
          <button
            className="rounded-[10px] border border-[#222222] font-[Gravity] border-1 py-2 px-2"
            onClick={handleUpload}
            ref={buttonRef}
          >
            {t("plan_upload.validate")}
          </button>
        ) : null}
        <h3 className="font-[Gravity] text-[16px] font-[400] my-2">
          {t("plan_upload.instruction2")}
        </h3>
        <Steps
          activeIndex={activeIndexStep}
          steps={[
            {
              text: t("plan_upload.step1"),
              index: 0,
            },
            {
              type: "row",
            },
            {
              text: t("plan_upload.step2"),
              index: 1,
            },
            {
              type: "row",
            },
            {
              text: t("plan_upload.step3"),
              index: 2,
            },
            {
              type: "row",
            },
            {
              text: t("plan_upload.step4"),
              index: 3,
            },
          ]}
        />
      </div>

      <div
        className="absolute bg-white bg-opacity-60 z-[999] h-full w-full flex items-center justify-center top-0 left-0 hidden"
        ref={overlayRef}
      >
        <div className="flex items-center">
          <span className="text-3xl mr-4 font-[Gravity]">
          {t("plan_upload.send")}
          </span>
          <svg
            className="animate-spin h-5 w-5 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
      {openModal ? (
        <Modal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
          size={'lg'}
          titleModal={t("upload-modal-information.title")}
          hiddenButtonDefault
        >

          <div className="flex flex-wrap">
            <div className="w-full">
              <ul >
                <li className="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction1")}</span>
                </li>
                <li className="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction2")}</span>
                </li>
                <li className="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction3")}</span>
                </li>
                <li className="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction4")}</span>
                </li>
                <li class="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction5")}</span>
                </li>
                <li class="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction6")}</span>
                </li>
                <li class="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction7")}</span>
                </li>
                <li class="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction8")}</span>
                </li>
                <li class="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction9")}</span>
                </li>
                <li class="flex p-3 hover:bg-blue-600 hover:text-blue-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00a135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <span className="px-1">{t("upload-modal-information.instruction10")}</span>
                </li>

              </ul>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default PlanningPage;