import { Link, useLocation } from "react-router-dom";

const MenuItemsModule = ({ items }) => {
  const { pathname } = useLocation();
  const renderItems = () => {
    return items
      .filter((item) => item.id !== 23)
      .map((item, index) => {
        const currentUrl = pathname.indexOf(item.url) > -1;
        const colorText = currentUrl
          ? "font-bold text-[#000000] "
          : "text-[#5c5c5c]";
        const shadowClass = currentUrl ? "bg-[#c4c4c4] rounded-[2px] pl-1 opacity-[.7]" : "";
        return (
          <div
            className={`flex flex-row justify-start items-center w-full my-1 ${shadowClass} transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300`}
            key={`m-${index}`}
          >

            <div
              className="w-[15px] h-[15px] bg-[#C4C4C4]"
              key={`c-${index}`}
            />
            <Link
              to={item.url ? `../${item.url}` : "#"}
              className={`py-1 px-.5 font-[13px] font-[Gravity] ml-2 ${colorText}`}
              key={`${item.id}-${index}`}
            >
              {item.name}
            </Link>
          </div>
        );
      });
  };

  if (!items) {
    return null;
  }
  return <>{renderItems()}</>;
};

export default MenuItemsModule;
