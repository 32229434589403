import { ResponseTransform } from "utils";
import api from "api";
import {
  setListClient,
  setUnitTypeList,
  setUnit,
  setOperator,
  setProfiles,
  setCedisDestination,
  setEtapas,
  setSondeos,
  setCedisOrigin,
  setBrands,
  setCarriers,
  setCategoriesProduct,
  setComponents,
  setGroupOption,
  setGroupOptionById,
  setQuestions,
} from "redux/catalog.slice";
import { addProjectNewList, deleteProjectListUser } from "redux/auth.slice";

export const getClientList = () => async (dispatch) => {
  let responseApi = await api.get(`api/project`).then(ResponseTransform);

  if (responseApi) {
    dispatch(setListClient(responseApi));
  }
  return responseApi;
};

export const addOrUpdateClient = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/project/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/project", data).then(ResponseTransform);
  }
  if (responseApi) {
    if (data.id === null) {
      dispatch(addProjectNewList(responseApi));
    }
    dispatch(getClientList());
  }
  return responseApi;
};

export const deleteClient = (id) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/project/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(deleteProjectListUser(id));
    dispatch(getClientList());
  }
  return true;
};

export const getUnitTypeList = () => async (dispatch) => {
  let responseApi = await api.get(`api/trucktype`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setUnitTypeList(responseApi));
  }
  return responseApi;
};

export const addOrUpdateUnitType = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/trucktype/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/trucktype", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getUnitTypeList());
  }
  return responseApi;
};

export const deleteUnitType = (id) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/trucktype/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getUnitTypeList());
  }
  return responseApi;
};

export const getUnitList = () => async (dispatch) => {
  let responseApi = await api.get(`api/truck`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setUnit(responseApi));
  }
  return responseApi;
};

export const addOrUpdateTruck = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/truck/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/truck", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getUnitList());
  }
  return responseApi;
};

export const deleteUnit = (id) => async (dispatch) => {
  let responseApi = await api.remove(`api/truck/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getUnitList());
  }
  return responseApi;
};

export const getOperatorList = (idProject) => async (dispatch) => {
  let responseApi = await api
    .get(`api/project/${idProject}/operators`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setOperator(responseApi));
  }
  return responseApi;
};

export const getOperatorListByCarrier = async (idCarrier) => {
  let responseApi = await api
    .get(`api/operator/carrier/${idCarrier}`)
    .then(ResponseTransform);

  return responseApi;
};

export const addOrUpdateOperator = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/operator/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/operator", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getOperatorList(data.idProject));
  }
  return responseApi;
};

export const deleteOperator = (id, projectId) => async (dispatch) => {
  let responseApi = await api.remove(`api/user/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getOperatorList(projectId));
  }
  return responseApi;
};

export const getProfilesList = () => async (dispatch) => {
  let responseApi = await api.get(`api/rol`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setProfiles(responseApi));
  }
  return responseApi;
};

export const addOrUpdateProfile = (data) => async (dispatch) => {
  let responseApi = await api
    .post("api/rol/process-add-rol-views", data)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getProfilesList());
  }
  return responseApi;
};

export const deleteProfile = (id) => async (dispatch) => {
  let responseApi = await api.remove(`api/rol/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getProfilesList());
  }
  return responseApi;
};

//desdis destino
export const getCedisById = async (id) => {
  let responseApi = await api
    .get(`api/destinationpoint/${id}`)
    .then(ResponseTransform);
  return responseApi;
};

export const getCedisDestino =
  (id = null) =>
  async (dispatch) => {
    let endPoint = `api/destinationpoint/${id ? `${id}/project` : ""}`;

    let responseApi = await api.get(endPoint).then(ResponseTransform);
    if (responseApi) {
      dispatch(setCedisDestination(responseApi));
    }
    return responseApi;
  };

export const addOrUpdateCedisDestino = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/destinationpoint/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/destinationpoint", data)
      .then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getCedisDestino(data?.IdProject));
  }
  return responseApi;
};

export const deleteCedisDestino = (id, idProject) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/destinationpoint/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getCedisDestino(idProject));
  }
  return responseApi;
};

//end cedis destino

//cedis origin

export const getCedisOrigin =
  (id = null) =>
  async (dispatch) => {
    let endPoint = `api/originpoint/${id ? `${id}/project` : ""}`;
    let responseApi = await api.get(endPoint).then(ResponseTransform);
    if (responseApi) {
      dispatch(setCedisOrigin(responseApi));
    }
    return responseApi;
  };

export const getCediOriginById = async (id) => {
  let responseApi = await api
    .get(`api/originpoint/${id}`)
    .then(ResponseTransform);
  return responseApi;
};

export const addOrUpdateCedisOrigin = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/originpoint/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/originpoint", data)
      .then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getCedisOrigin(data?.IdProject));
  }
  return responseApi;
};

export const deleteCedisOrigin = (id, idProject) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/originpoint/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getCedisOrigin(idProject));
  }
  return responseApi;
};

//end cedis origin

//sondeos

export const getSondeos = () => async (dispatch) => {
  let responseApi = await api.get(`api/poll`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setSondeos(responseApi));
  }
  return responseApi;
};

export const addOrUpdateSondeos = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/poll/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/poll", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getSondeos());
  }
  return responseApi;
};

export const deleteSondeos = (id) => async (dispatch) => {
  let responseApi = await api.remove(`api/poll/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getSondeos());
  }
  return responseApi;
};

//end sondeos

//Etapas
export const getEtapas = () => async (dispatch) => {
  let responseApi = await api.get(`api/stage`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setEtapas(responseApi));
  }
  return responseApi;
};

export const addOrUpdateEtapas = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/stage/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/stage", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getEtapas());
  }
  return responseApi;
};

export const deleteEtapas = (id) => async (dispatch) => {
  let responseApi = await api.remove(`api/stage/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getEtapas());
  }
  return responseApi;
};

//end sondeos

//Marcas
export const getBrands = () => async (dispatch) => {
  let responseApi = await api.get(`api/brand`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setBrands(responseApi));
  }
  return responseApi;
};

export const addOrUpdateBrands = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/brand/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api.post("api/brand", data).then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getBrands());
  }
  return responseApi;
};

export const deleteBrands = (id) => async (dispatch) => {
  let responseApi = await api.remove(`api/brand/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getBrands());
  }
  return responseApi;
};

//end marcas

//Carriers

export const getCarrier = (idProject) => async (dispatch) => {
  let endPoint = `api/project/${idProject}/carrier`;
  let responseApi = await api.get(endPoint).then(ResponseTransform);
  if (responseApi) {
    dispatch(setCarriers(responseApi));
  }
  return responseApi;
};

export const addOrUpdateCarrier = (data) => async (dispatch) => {
  let responseCarrier;
  if (data.id) {
    responseCarrier = await api
      .put(`api/carrier/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseCarrier = await api
      .post("api/carrier", data)
      .then(ResponseTransform);
    if (responseCarrier && responseCarrier.id) {
      await AddCarrierProject({
        idCarrier: responseCarrier.id,
        idProject: data.idProject,
      });
    }
  }
  if (responseCarrier) {
    dispatch(getCarrier(data.idProject));
  }
  return responseCarrier;
};

export const AddCarrierProject = async ({ idCarrier, idProject }) => {
  await api.put(`api/carrier/${idCarrier}/addorremoveprojects`, [
    { idProject, addProject: true },
  ]);
};

export const deleteCarrier = (id, idCurrentProject) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/carrier/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getCarrier(idCurrentProject));
  }
  return responseApi;
};

//end carriers

//Categories Product

export const getCategoriesProduct = (idProject) => async (dispatch) => {
  let responseApi = await api
    .get(`api/Project/${idProject}/category-product`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setCategoriesProduct(responseApi));
  }
  return responseApi;
};

export const addOrUpdateCategoriesProduct = (data) => async () => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/CategoryProduct/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/CategoryProduct", data)
      .then(ResponseTransform);
  }
  return responseApi;
};

export const deleteCategoriesProduct = (id, idCurrent) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/CategoryProduct/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getCategoriesProduct(idCurrent));
  }
  return responseApi;
};

//end categories Product

//Stages

export const getStages = (idProject) => async (dispatch) => {
  let responseApi = await api
    .get(`api/Project/${idProject}/stages`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setEtapas(responseApi));
  }
  return responseApi;
};

export const addOrUpdateStages = (data) => async (dispatch) => {
  let responseApi;
  const { idProject, name, description } = data;
  if (data.id) {
    responseApi = await api
      .put(`api/Stage/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/Stage", { idProject, name, description })
      .then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getStages(data.idProject));
  }
  return responseApi;
};

export const deleteStages = (id, idCurrent) => async (dispatch) => {
  let responseApi = await api.remove(`api/Stage/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getStages(idCurrent));
  }
  return responseApi;
};

export const updateOrderList = (data, idCurrentProject) => async (dispatch) => {
  await api
    .post(`api/Stage/order`, { ListOrder: data })
    .then(ResponseTransform);
  dispatch(getStages(idCurrentProject));
};

//end categories Product

//Sondeos

export const getPolls = (id) => async (dispatch) => {
  let responseApi = await api
    .get(`api/Stage/${id}/polls`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setSondeos(responseApi));
  }
  return responseApi;
};

export const addOrUpdatePolls = (data) => async (dispatch) => {
  let responseApi;
  const { description, name, idStage, required } = data;
  if (data.id) {
    responseApi = await api
      .put(`api/poll/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/poll", { description, name, idStage, required })
      .then(ResponseTransform);
  }

  if (responseApi) {
    dispatch(getPolls(idStage));
  }
  return responseApi;
};

export const deletePolls = (id, idCurrent) => async (dispatch) => {
  let responseApi = await api.remove(`api/poll/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(getPolls(idCurrent));
  }
  return responseApi;
};

export const getStageByProject = async (idProject) => {
  let responseApi = await api
    .get(`api/project/${idProject}/stages`)
    .then(ResponseTransform);
  return responseApi;
};

export const updatePollOrderList = (data, id) => async (dispatch) => {
  await api
    .post(`api/Poll/order`, {
      ListOrder: data,
    })
    .then(ResponseTransform);
  dispatch(getPolls(id));
};
//en Sondeos

//Components

export const getComponents = () => async (dispatch) => {
  let responseApi = await api
    .get(`api/Question/component`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setComponents(responseApi));
  }
  return responseApi;
};

export const addOrUpdateComponents = (data) => async (dispatch) => {
  let responseApi;
  if (data.id) {
    responseApi = await api
      .put(`api/Question/component/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/Question/component", data)
      .then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getComponents());
  }
  return responseApi;
};

export const deleteComponents = (id) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/Question/component/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getComponents());
  }
  return responseApi;
};

//end componentsd

//GroupOption

export const getGroupOptions = () => async (dispatch) => {
  let responseApi = await api.get(`api/GroupsOption`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setGroupOption(responseApi));
  }
  return responseApi;
};

export const getGroupOptionsById = (id) => async (dispatch) => {
  let responseApi = await api
    .get(`api/GroupsOption/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(setGroupOptionById(responseApi));
  }
  return responseApi;
};

export const addOrUpdateGroupOptions = (data) => async (dispatch) => {
  let responseApi;
  const { description, answers, deleteAnswers } = data;
  if (data.id) {
    responseApi = await api
      .put(`api/GroupsOption/${data.id}`, {
        description,
        answers: answers,
        deleteAnswers: deleteAnswers,
      })
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/GroupsOption", {
        description,
        answers: answers,
      })
      .then(ResponseTransform);
  }
  if (responseApi) {
    dispatch(getGroupOptions());
  }
  return responseApi;
};

export const deleteGroupOptions = (id) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/GroupsOption/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getGroupOptions());
  }
  return responseApi;
};

//end GroupOption

//Questions

export const getQuestions = (id) => async (dispatch) => {
  let responseApi = await api.get(`api/poll/${id}`).then(ResponseTransform);
  if (responseApi) {
    dispatch(setQuestions(responseApi));
  }
  return responseApi;
};

export const addOrUpdateQuestions = (data) => async (dispatch) => {
  let responseApi;
  const { idPoll, question, idComponent, idGroup, required } = data;
  if (data.id) {
    responseApi = await api
      .put(`api/question/${data.id}`, data)
      .then(ResponseTransform);
  } else {
    responseApi = await api
      .post("api/question", {
        idPoll,
        question,
        idComponent,
        idGroup,
        required,
        idParent: 0,
        idParentAnswer: 0,
      })
      .then(ResponseTransform);
  }

  if (responseApi) {
    dispatch(getQuestions(idPoll));
  }
  return responseApi;
};

export const deleteQuestions = (id, idPoll) => async (dispatch) => {
  let responseApi = await api
    .remove(`api/question/${id}`)
    .then(ResponseTransform);
  if (responseApi) {
    dispatch(getQuestions(idPoll));
  }
  return responseApi;
};

export const updateQuestionOrderList = (data, idPoll) => async (dispatch) => {
  await api
    .post(`api/Question/order`, { ListOrder: data })
    .then(ResponseTransform);
  dispatch(getQuestions(idPoll));
};

//end Questions
